// ----- Packages -----
import React, { useCallback, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment-timezone";

// ----- Components -----
import TmPaFilters from "./TmPaFilters";

// ----- Types -----
import { TmEvent, TmPaFiltersData } from "../../../../Types/Tm";
import Template from "../../Template";
import TmPaDataGrid from "./TmPaDataGrid";

/**
 * The past ticketmaster events page, which displays the filters and data grid
 * @returns The past ticketmaster events page
 */
function TmPaPage() {
  // ----- Events -----
  const [pastEvents, setPastEvents] = useState<{ pastEvent?: TmEvent, currentEvent?: TmEvent }[]>([]);

  // ----- Events Filters Data -----
  const [pastEventsFilters, setPastEventsFilters] = useState<TmPaFiltersData>(
    {} as TmPaFiltersData
  );

  // ----- States -----
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // ----- URLs -----
  const pastEventsUrl = "/api/events/past";

  /**
   * Function that formats the events data
   * @param events<EventDetails[]> - The events to be formatted
   */
  const processEvents = useCallback(async (data: { events: TmEvent[], currentEvents: TmEvent[] }) => {
    let mergedEvents: { pastEvent: TmEvent, currentEvent?: TmEvent }[] = [];

    for (let pastEvent of data.events) {
      let pastEventDate = moment(pastEvent.event_date).tz(pastEvent.timezone || 'America/New_York');
      let matchedEvent: TmEvent | undefined;

      if (data.currentEvents.length > 0) {
        matchedEvent = data.currentEvents.find(currEvent => {
          let currEventDate = moment(currEvent.event_date).tz(currEvent.timezone || 'America/New_York');

          let isSameHour = pastEventDate.hour() === currEventDate.hour();
          let isOneDayAfter = pastEventDate.date() === currEventDate.date() + 1;  // Check if day is one day after
          let isSameMonth = pastEventDate.month() === currEventDate.month();
          let isOneYearBefore = pastEventDate.year() === currEventDate.year() - 1; // Check if year is one year after

          return isSameHour && isOneDayAfter && isSameMonth && isOneYearBefore;
        });
      }

      pastEvent.event_date = pastEventDate.format("YYYY-MM-DD HH:mm:ss");

      if (matchedEvent) {
        let formattedDate = moment(matchedEvent.event_date).tz(matchedEvent.timezone || 'America/New_York');
        matchedEvent.event_date = formattedDate.format("YYYY-MM-DD HH:mm:ss");
      }

      mergedEvents.push({
        pastEvent: pastEvent,
        currentEvent: matchedEvent
      });
    }

    setPastEvents(mergedEvents);
    setFirstLoad(false);
    setIsLoading(false);
  }, []);

  /**
   * Function that fetches the events from the server
   *  Format the data and set the state
   */
  const getEvents = useCallback(() => {
    try {
      let data = pastEventsFilters;
      let url = pastEventsUrl;

      const queryString = Object.keys(data)
        // @ts-ignore - Filter out null and undefined values
        .filter((key) => data[key] !== null && data[key] !== undefined)
        // @ts-ignore - Object.keys() returns a string
        .map((key) => `${key}=${data[key]}`)
        .join("&");

      console.log(queryString);

      setIsLoading(true);

      url += `?${queryString}`;

      axios
        .get(url)
        .then((res) => {
          processEvents(res.data).then();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
    }
  }, [pastEventsFilters, processEvents]);

  /**
   * Function that starts the search process
   */
  const startSearch = useCallback(() => {
    setPastEvents([]);
    setIsLoading(true);
    getEvents();
  }, [getEvents]);

  // The date format to be used in the table
  const dateFormat = "YYYY-MM-DD";

  /**
   * Function that renders the filters component
   */
  const renderFilters = () => {
    return (
      <TmPaFilters
        filters={pastEventsFilters}
        setFilters={setPastEventsFilters}
        search={startSearch}
      />
    );
  };

  /**
   * Function that renders the data grid component
   */
  const renderDataGrid = () => {
    return (
      <TmPaDataGrid
        events={pastEvents}
        loading={isLoading}
        firstLoad={firstLoad}
      />
    );
  };

  /**
   * Return the JSX of the page
   */
  return (
    <Template
      isLoading={isLoading}
      filtersComponent={renderFilters()}
      datagridComponent={renderDataGrid()}
      searchFunc={startSearch}
    />
  );
}

export default TmPaPage;
