// ----- Module -----
import React, { useEffect, useState } from "react";
import axios from "axios";

// ----- MUI -----
import { Box, Button, Card, CircularProgress } from "@mui/material";
import { GlobalAlert } from "../Types/Other";
import AddIcon from "@mui/icons-material/Add";
import TitleIcon from "@mui/icons-material/Notifications";

// ----- Components -----
import GlobalAlertsDataGrid from "../components/dataGrids/GlobalAlertsDataGrid";
import NavMenu from "../components/others/NavMenu";

/**
 * Global alerts list page
 * @constructor GlobalAlertsPage - The global alerts list page
 */
const GlobalAlertsPage = () => {
  document.title = "Global Alerts";

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [result, setResult] = useState<GlobalAlert[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const resp = await axios.get(`/api/globalAlerts`);
      setResult(resp.data);
    };
    fetch().then();
  }, []);

  async function add() {
    setLoading(true);
    setError("");
    try {
      const resp = await axios.post(`/api/globalAlerts`, {
        name: "New Global Alert",
      });
      setResult(resp.data);
    } catch (e: any) {
      setError(e.response.data.error);
    }
    setLoading(false);
  }

  async function update(globalAlert: GlobalAlert): Promise<boolean> {
    setLoading(true);
    setError("");
    try {
      const resp = await axios.put(`/api/globalAlerts`, globalAlert);
      setLoading(false);
      setResult(resp.data);
      return true;
    } catch (e: any) {
      setLoading(false);
      setError(e.response.data.error);
      return false;
    }
  }

  async function remove(id: string) {
    setLoading(true);
    setError("");
    try {
      const resp = await axios.delete(`/api/globalAlerts/${id}`);
      setResult(resp.data);
    } catch (e: any) {
      setError(e.response.data.error);
    }
    setLoading(false);
  }
  return (
    <Box sx={{ m: 2 }}>
      <NavMenu current={"globalalerts"} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Card sx={{ px: 2, mb: 1, display: "flex", alignItems: "center" }}>
          <TitleIcon sx={{ fontSize: 45 }} />
          <h1>Global alerts</h1>
        </Card>
        <Button
          sx={{ ml: 3, minWidth: 0, p: 1 }}
          variant="outlined"
          disabled={loading}
          onClick={async () => {
            await add();
          }}
        >
          <AddIcon />
        </Button>
        {loading && <CircularProgress sx={{ ml: 1 }} size={25} />}
      </Box>

      {error && <p style={{ color: "red" }}>{error}</p>}

      <GlobalAlertsDataGrid rows={result} update={update} remove={remove} />
    </Box>
  );
};

export default GlobalAlertsPage;
