// ----- Packages ----- //
import React, { useCallback, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment-timezone";

// ----- Components -----
import SgFilters from "./SgFilters";
import SgDataGrid from "./SgDataGrid";
import Template from "../Template";

// ----- Types -----
import { SgEvent, SgFiltersData } from "../../../Types/Sg";

/**
 * The seatgeek events page, which displays the filters and data grid
 * @returns The seatgeek events page
 */
function SgPage() {
  // ----- Events -----
  const [activeEvents, setActiveEvents] = useState<SgEvent[]>([]);

  // ----- Events Filters Data -----
  const [filters, setFilters] = useState<SgFiltersData>({} as SgFiltersData);

  // ----- States -----
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // ----- URLs -----
  const activeEventsUrl = "/api/seatgeek/events";
  const syncAllUrl = "/api/seatgeek/bulkRefresh";

  /**
   * Function that formats the events data
   * @param events<EventDetails[]> - The events to be formatted
   */
  const processEvents = useCallback(async (events: SgEvent[]) => {
    // Events that are in the drop-shipping
    const dsResp = await axios(
      `https://ds.plessinc.com/api/events?type=activetmids`
    );
    const dsEvents = await dsResp.data.events;

    // Format the events
    events.forEach((event: SgEvent) => {
      event.event_date = moment(event.event_date).format("YYYY-MM-DD, dddd");
      if (event.tm_id) event.in_ds = !!dsEvents.includes(event.tm_id);
    });

    setActiveEvents(events);
    setFirstLoad(false);
    setIsLoading(false);
  }, []);

  /**
   * Function that fetches the events from the server
   *  Format the data and set the state
   */
  const getEvents = useCallback(() => {
    try {
      let data = filters;
      let url = activeEventsUrl;
      setIsLoading(true);

      if (Object.keys(data).length > 0) {
        url += "?";
        for (let key in data) {
          // @ts-ignore
          if (data[key] === null || data[key] === undefined || data[key] === "")
            continue;
          // @ts-ignore
          url += `${key}=${data[key]}&`;
        }
        url = url.slice(0, -1);
      }

      axios
        .get(url)
        .then((res) => {
          processEvents(res.data).then();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
    }
  }, [processEvents, filters]);

  /**
   * Function that starts the search process
   */
  const startSearch = useCallback(() => {
    setActiveEvents([]);
    getEvents();
  }, [getEvents]);

  /**
   * Function that syncs all the events in the data grid
   */
  const syncAll = useCallback(async () => {
    if (activeEvents.length === 0 || activeEvents.length > 50) return;

    let body = {
      events: activeEvents.map((event) => event.id),
    };

    await axios
      .post(syncAllUrl, body)
      .then((res) => {
        processEvents(res.data).then();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [activeEvents, processEvents]);

  /**
   * Function that renders the filters component
   */
  const renderFilters = () => {
    return <SgFilters setFilters={setFilters} search={startSearch}/>;
  };

  /**
   * Function that renders the data grid component
   */
  const renderDataGrid = () => {
    return (
      <SgDataGrid
        events={activeEvents}
        loading={isLoading}
        firstLoad={firstLoad}
        syncAll={syncAll}
      />
    );
  };

  /**
   * Return the JSX of the page
   */
  return (
    <Template
      isLoading={isLoading}
      filtersComponent={renderFilters()}
      datagridComponent={renderDataGrid()}
      searchFunc={startSearch}
    />
  );
}

export default SgPage;
