// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { DataGridPro, GridColumns, GridRowModel } from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";

// ----- Types ----- //
import { GlobalAlert, GlobalAlertsGridProps } from "../../Types/Other";

/**
 * DataGrid that contains all the global alerts
 * @param props<GlobalAlertsGridProps> - The rows of the data grid, the update and delete functions.
 */
export default function GlobalAlertsDataGrid(props: GlobalAlertsGridProps) {
  /**
   * On row process, update the value in the database
   * @param newRow<GridRowModel> - The new row
   * @param oldRow<GridRowModel> - The old row
   */
  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve) => {
        for (const key in newRow) if (newRow[key] === "") newRow[key] = null;
        props.update(newRow as GlobalAlert).then((res) => {
          if (res) resolve(newRow);
          else resolve(oldRow);
        });
      }),
    [props]
  );

  // ----- Columns ----- //
  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      pinnable: true,
      editable: true,
      type: "string",
    },
    {
      field: "cap_left",
      headerName: "Capacity left %",
      width: 150,
      editable: true,
      type: "number",
    },
    {
      field: "cap_left_nb",
      headerName: "Capacity left #",
      width: 150,
      editable: true,
      type: "number",
    },
    {
      field: "avg_dec",
      headerName: "Average decrease",
      width: 150,
      editable: true,
      type: "number",
    },
    {
      field: "mir",
      headerName: "MIR",
      width: 100,
      editable: true,
      type: "number",
    },
    {
      field: "mqr",
      headerName: "MQR",
      width: 100,
      editable: true,
      type: "number",
    },
    {
      field: "dte",
      headerName: "Days to event",
      width: 100,
      editable: true,
      type: "number",
    },
    {
      field: "data_points",
      headerName: "Data points",
      width: 100,
      editable: true,
      type: "number",
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      pinnable: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex" }}>
            <Button
              style={{ minWidth: "10px" }}
              onClick={() => props.remove(params.row.id)}
            >
              <DeleteIcon sx={{ color: "red" }} />
            </Button>
          </Box>
        );
      },
    },
  ];

  // The data grid component fully configured and filled with the data
  return (
    <Box sx={{ height: "calc(100vh - 140px)", width: "100%" }}>
      <DataGridPro
        getRowId={(row) => row.id}
        density={"compact"}
        rows={props.rows}
        columns={columns}
        initialState={{
          pinnedColumns: { left: ["actions"] },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd"
        }
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(params) => {
          console.log("Error updating row", params);
        }}
      />
    </Box>
  );
}
