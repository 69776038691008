// ----- Packages ----- //
import React, { memo } from "react";
import moment from "moment";

// ----- MUI ----- //
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DataIcon from "@mui/icons-material/Description";

// ----- Types ----- //
import { TmEvent } from "../../../Types/Tm";

/**
 * The stats for an event. (TicketMaster and TicketMaster Resale)
 * @param props{ event: TmEvent } - The event to display the stats for.
 */
const EventStats = memo((props: { event: TmEvent }) => {
  const event: TmEvent = props.event;

  // Rows for the table.
  const rows = [
    {
      name: "TicketMaster",
      decrease_avg: event.tm_decrease_avg,
      remaining: event.tm_remaining,
      mir: event.tm_mir,
      mqr: event.tm_mqr,
      last_ping: moment(event.tm_last_ping).format("MM/DD/YYYY"),
    },
    {
      name: "TM Resale",
      decrease_avg: event.rs_decrease_avg,
      remaining: event.rs_remaining,
      mir: event.rs_mir,
      mqr: "---",
      last_ping: moment(event.tm_last_ping).format("MM/DD/YYYY"),
    },
    {
      name: "SeatGeek",
      decrease_avg: event.sg_decrease_avg,
      remaining: event.sg_remaining,
      mir: event.sg_mir,
      mqr: "---",
      last_ping: "---"
    }
  ];

  // ----- Render ----- //
  return (
    <div>
      <TableContainer component={Paper} sx={{width: "auto"}}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Box
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DataIcon sx={{position: "relative", top: -2}}/>
                  Stats
                </Box>
              </TableCell>
              <TableCell sx={{textAlign: "center", fontWeight: "bold"}}>
                Avg Dec
              </TableCell>
              <TableCell sx={{textAlign: "center", fontWeight: "bold"}}>
                RMNG
              </TableCell>
              <TableCell sx={{textAlign: "center", fontWeight: "bold"}}>
                MIR
              </TableCell>
              <TableCell sx={{textAlign: "center", fontWeight: "bold"}}>
                MQR
              </TableCell>
              <TableCell sx={{textAlign: "center", fontWeight: "bold"}}>
                Last Ping
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(
              (row) =>
                row.last_ping !== "Invalid date" && (
                  <TableRow
                    key={row.name}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      <strong>{row.name}</strong>
                    </TableCell>
                    <TableCell align="center">{row.decrease_avg}</TableCell>
                    <TableCell align="center">{row.remaining}</TableCell>
                    <TableCell align="center">{row.mir}</TableCell>
                    <TableCell align="center">{row.mqr}</TableCell>
                    <TableCell align="center">
                      <Typography
                        color={
                          moment().format("MM/DD/YYYY") !== row.last_ping
                            ? "error"
                            : "inherit"
                        }
                      >
                        {row.last_ping}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default EventStats;
