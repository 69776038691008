// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { Box, TextField, Tooltip } from "@mui/material";

// ----- Types ----- //
import { DefaultInputProps } from "../../Types/Other";

/**
 * The default input component for any filters
 * @param props<DefaultInputProps> The props for the component
 */
export default function DefaultInput(props: DefaultInputProps) {
  if (props.hide) return <></>;
  return (
    <Box
      sx={{
        mx: 1,
        mt: 2,
        mb: 1,
        width: props.type === "date" ? "150px" : "100px",
      }}
    >
      <Tooltip title={props.title} enterDelay={500} enterNextDelay={500}>
        <TextField
          fullWidth
          disabled={props.disabled || false}
          id={props.id}
          label={props.label}
          type={props.type}
          InputLabelProps={{shrink: true}}
          value={props.value}
          size={"small"}
          onChange={(e) => {
            let value: any = e.target.value;
            if (props.type === "number" && value !== "")
              value = Number.isNaN(Number(value)) ? 0 : Number(value);
            props.setValue(value);
          }}
          error={props.error || false}
          onKeyDown={(e) => {
            if (e.key === "Enter") props.startSearch();
          }}
        />
      </Tooltip>
    </Box>
  );
}
