// ----- Packages ----- //
import axios from "axios";
import moment from "moment/moment";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

// ----- MUI ----- //
import {
  Box,
  Button,
  Card,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import MapIcon from "@mui/icons-material/Map";
import NotesIcon from "@mui/icons-material/Comment";
import EditNotesIcon from "@mui/icons-material/Edit";
import DateIcon from "@mui/icons-material/CalendarMonth";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import SearchIcon from "@mui/icons-material/ManageSearch";
import GroupsIcon from "@mui/icons-material/Groups";

// ----- Other ----- //
import { TmEvent } from "../../../Types/Tm";
import { iconLinkStyle } from "../../../styles";

/**
 * The component for the event details (name, venue, date, notes)
 * @param props{{eventDetail: TmEvent, setEventDetail: React.Dispatch<React.SetStateAction<TmEvent | undefined>>}} - The props for the component
 */
const MpvEventInfo = memo((props: { eventDetail: any }) => {
  const { eventDetail } = props;

  // ----- Render ----- //
  return (
    <Card
      sx={{
        p: 1,
        borderTopLeftRadius: "0",
        maxWidth: "400px",
        minWidth: "300px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography
          variant={eventDetail.event_name.length > 40 ? "h6" : "h5"}
          fontWeight={"bold"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={eventDetail.event_name}
        >
          <a
            href={eventDetail.url}
            target="_blank"
            rel="noreferrer"
            style={{ color: "inherit" }}
          >
            {eventDetail.event_name}
          </a>
        </Typography>
      </Box>
      <Box>
        {/* Name and link of venue */}
        <div style={{ display: "flex" }}>
          <MapIcon sx={{ mr: 1 }} />
          {eventDetail.venue_name}
          <Tooltip title={"Total capacity of venue"}>
            <Box
              sx={{
                ml: "auto",
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <GroupsIcon sx={{ mr: 0.5 }} />
              <Typography variant={"body2"}>{eventDetail.capacity}</Typography>
            </Box>
          </Tooltip>
        </div>
        <Divider sx={{ my: 1 }} />
        {/* Date and time of event */}
        <Typography
          sx={
            moment(eventDetail.event_date).isBefore(moment())
              ? { fontWeight: "bold", color: "error.main", display: "flex" }
              : { display: "flex" }
          }
        >
          <DateIcon sx={{ mr: 1 }} />
          {moment(eventDetail.event_date).format("ddd, MM/DD/YYYY h:mm A")}
        </Typography>
        <Divider sx={{ my: 1 }} />
      </Box>
    </Card>
  );
});

export default MpvEventInfo;
