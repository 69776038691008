// ----- Packages ----- //
import React, { useState } from "react";
import axios from "axios";

// ----- MUI ----- //
import {
  Box,
  Button,
  Divider,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CountIcon from "@mui/icons-material/QuestionMark";
import TitleIcon from "@mui/icons-material/QuestionMark";

// ----- Other ----- //
import { modalStyle } from "../../styles";
import ErrorCard from "../others/cards/ErrorCard";

/**
 * Modal that displays more information about the event
 * @param props<{eventId: string}> - The event ID
 * @constructor - Displays the modal
 */
export default function Info(props: { eventId: string }) {
  // ----- Data ----- //
  const [inventoryCount, setInventoryCount] = useState("...");
  const [tixPrimary, setTixPrimary] = useState("...");
  const [tixResale, setTixResale] = useState("...");
  const [sections, setSections] = useState([]);

  // ----- State ----- //
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // ----- Functions ----- //

  /**
   * Opens the modal and fetches the data
   */
  const handleOpen = async () => {
    if (error) {
      setError("");
      setIsLoading(true);
    }

    setOpened(true);

    let skyboxId;

    // Try to get the Skybox ID from the matching events API
    await axios(
      `https://api.plessinc.com/api/event/view/ticketmaster/` + props.eventId
    )
      .then(
        (res) => (skyboxId = res.data?.event?.matching_events?.vividseats_id)
      )
      .catch(() => (skyboxId = null));

    if (skyboxId) {
      const header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-account": "258",
        "X-Api-Token": "4a806405-e1be-43e3-8af4-1e660c8e7585",
        "X-Application-Token": "2a049203-0e55-414e-864a-08629967118f",
      };

      const skyboxResp = await fetch(
        `https://skybox.vividseats.com/services/inventory?eventId=${skyboxId}&antiTag=ds`,
        {
          method: "GET",
          headers: header,
        }
      );

      if (!skyboxResp.ok) {
        setInventoryCount("ERROR");
      } else {
        const skyboxData = await skyboxResp.json();
        const rows = skyboxData.rows;
        let total = rows.reduce(
          (total: any, row: any) => total + row.quantity,
          0
        );

        setInventoryCount(total);
      }
    } else setInventoryCount("N/A");

    const tmDataResp = await fetch(
      `https://count.plessinc.com/event/${props.eventId}/US`
    );

    setIsLoading(false);

    if (!tmDataResp.ok) {
      setError(JSON.stringify(tmDataResp));
      return;
    }

    const tmData = await tmDataResp.json();

    setTixPrimary(tmData.numOfTixPrimary);
    setTixResale(tmData.numOfTixResale);

    tmData.bySection.sort((a: any, b: any) => b.count - a.count);
    setSections(tmData.bySection);
  };

  /**
   * Closes the modal
   */
  const handleClose = () => {
    setOpened(false);
  };

  // ----- Render ----- //
  return (
    <div>
      <Button style={{ minWidth: "10px" }} onClick={handleOpen}>
        <CountIcon />
      </Button>

      <Modal
        open={opened}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{ display: "flex", alignItems: "center", marginY: 3 }}>
            <TitleIcon sx={{ fontSize: 40, mr: 1 }} />
            <h1 style={{ margin: 0 }}>More Information</h1>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Typography color={"text.secondary"} sx={{ display: "flex" }}>
              More useful information about the event
            </Typography>
            <Typography
              onClick={() => navigator.clipboard.writeText(props.eventId)}
              style={{
                fontWeight: "bold",
                cursor: "copy",
                textDecoration: "underline",
                marginLeft: "5px",
              }}
            >
              {props.eventId}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />
          <p>
            <strong>Tickets in our inventory: </strong>
            {inventoryCount}
          </p>
          <p>
            <strong>Primary tickets: </strong>
            {tixPrimary}
          </p>
          <p>
            <strong>Resale tickets: </strong>
            {tixResale}
          </p>

          <Box>
            <Box sx={{ overflow: "auto", height: "300px" }}>
              {isLoading ? (
                <Skeleton variant="rounded" width="100%" height="100%" />
              ) : null}

              <ErrorCard
                details={{
                  component: "components/modals/Info.tsx",
                  error: error,
                }}
                show={!!error}
              />

              {!isLoading && !error ? (
                <DataGridPro
                  headerHeight={45}
                  rowHeight={35}
                  rows={sections}
                  getRowId={(row: any) => row.section}
                  columns={[
                    { field: "count", headerName: "Count" },
                    { field: "section", headerName: "Section", flex: 1 },
                  ]}
                  hideFooter={true}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0
                      ? "row-even"
                      : "row-odd"
                  }
                />
              ) : null}
            </Box>
          </Box>

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button sx={{ mr: 2 }} onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
