// Style for the modal component
export const modalStyle = {
  overflow: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  maxHeight: "90%",
  px: 4,
  pb: 4,
};

/* ----- Icons that link to another page ----- */
export const iconLinkStyle = {
  display: "flex",
  "& a, & button": {
    color: "rgb(194, 143, 249)",
    textDecoration: "none",
    fontSize: "1.5rem",
    margin: "0 0.3rem",
    "&:hover": {
      color: "rgb(155, 109, 204)",
    },
  },
};
