// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

// ----- Components ----- //
import NoRows from "./Overlays/NoRows";
import Error from "./Overlays/Error";
import Loading from "./Overlays/Loading";

// ----- Types ----- //
import { SearchDataGridProps } from "../../Types/Other";

/**
 * DataGrid template for the search pages (Tm, Sg, via, etc.)
 * @param props<SearchDataGridProps> - The props for the data grid
 */
export default function SearchDataGrid(props: SearchDataGridProps) {
  const apiRef = useGridApiRef();

  const {
    id,
    data,
    loading,
    firstLoad,
    columns,
    columnGroupingModel,
    processRowUpdate,
  } = props;

  // order the columns based on the order in the local storage
  const order = localStorage.getItem(id + "_COLUMNS_ORDER")
    ? JSON.parse(localStorage.getItem(id + "_COLUMNS_ORDER") || "")
    : [];

  const orderedColumns = columns.sort((a, b) => {
    const aIndex = order.indexOf(a.field);
    const bIndex = order.indexOf(b.field);
    if (aIndex === -1) {
      return 1;
    } else if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });

  // The data grid
  return (
    <DataGridPro
      getRowId={props.getRowId ? props.getRowId : (row) => row.id}
      apiRef={apiRef}
      headerHeight={30}
      rowHeight={35}
      rows={data}
      columns={orderedColumns}
      initialState={{
        pinnedColumns: {left: ["links"], right: ["actions"]},
        columns: {columnVisibilityModel: {}},
      }}
      getRowClassName={(params) =>
        (params.row.tm_remaining_threshold !== null &&
          params.row.tm_remaining_threshold >= params.row.tm_remaining) ||
        (params.row.tm_mir_threshold !== null &&
          params.row.tm_mir_threshold <= params.row.tm_mir)
          ? "threshold-alert"
          : params.indexRelativeToCurrentPage % 2 === 0
            ? "row-even"
            : "row-odd"
      }
      experimentalFeatures={{columnGrouping: true, newEditingApi: true}}
      columnGroupingModel={columnGroupingModel}
      loading={loading}
      components={{
        NoRowsOverlay: () => <NoRows firstLoad={firstLoad}/>,
        ErrorOverlay: () => <Error error={null}/>,
        LoadingOverlay: () => <Loading/>,
      }}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={(params) => {
        console.log("Error updating row", params);
      }}
      onColumnVisibilityModelChange={() => {
        const state = apiRef.current.exportState();
        localStorage.setItem(id + "_TABLE_STATE", JSON.stringify(state));
      }}
      onColumnWidthChange={() => {
        const state = apiRef.current.exportState();
        localStorage.setItem(id + "_TABLE_STATE", JSON.stringify(state));
      }}
      onSortModelChange={() => {
        const state = apiRef.current.exportState();
        localStorage.setItem(id + "_TABLE_STATE", JSON.stringify(state));
      }}
      onColumnOrderChange={() => {
        const state = apiRef.current.exportState();
        const orderedFields = state.columns?.orderedFields;
        localStorage.setItem(
          id + "_COLUMNS_ORDER",
          JSON.stringify(orderedFields)
        );
      }}
    />
  );
}
