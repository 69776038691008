import * as React from 'react';
import { useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment/moment";
import { Colors } from "../../Types/Other";
import { Line } from "react-chartjs-2";


const stackStrategy = {
  stack: 'total',
  area: true,
  stackOffset: 'none', // To stack 0 on top of others
};

const customize = {
  height: 300,
  legend: {hidden: true},
  margin: {top: 5},
  stackingOrder: 'descending',
};


const mergeDataSets = (pastCounts: any[], currentCounts: any[]) => {
  const mergedData: any[] = [];

  pastCounts.forEach(past => {
    const adjustedCreationDate = moment(past.creationDate).add(1, 'years').format("YYYY-MM-DD");
    const current = currentCounts.find(d => d.creationDate === adjustedCreationDate);

    mergedData.push({
      date: adjustedCreationDate, // using the adjustedCreationDate as date in mergedData for clarity
      pastCount: past.totalTickets,
      currentCount: current ? current.totalTickets : null
    });
  });

  return mergedData;
};

export default function Rockettes() {
  const {pastId, currentId} = useParams();


  const [pastCounts, setPastCounts] = React.useState<any[]>([]);
  const [currentCounts, setCurrentCounts] = React.useState<any[]>([]);

  const getCounts = async (id: string) => {
    const response = await axios(`/api/counters/${id}`);
    return response.data.map((d: any) => {
      d.creationDate = moment(d.creationDate).format("YYYY-MM-DD");
      return d;
    });
  };


  useEffect(() => {
    const fetch = async () => {
      const pastCounts = await getCounts(pastId || '');
      const currentCounts = await getCounts(currentId || '');
      setPastCounts(pastCounts);
      setCurrentCounts(currentCounts);
    };
    fetch().then(() => {
    });
  }, []);

  const mergedDataSets = mergeDataSets(pastCounts, currentCounts);

  const data = {
    // @ts-ignore
    labels: mergedDataSets.map((d) => moment(d.date)),
    datasets: [
      {
        fill: false,
        borderColor: "rgba(255,153,0,0.38)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointRadius: 0,
        label: "Past",
        // @ts-ignore
        data: mergedDataSets.map((d) => d.pastCount),
      },
      {
        fill: false,
        backgroundColor: "rgba(0,122,235,0.4)",
        borderColor: Colors.TM,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "Current",
        // @ts-ignore
        data: mergedDataSets.map((d) => d.currentCount),
        yAxisID: "y-axis-1",
      },
    ],
  };

  // ----- Options for the graph ----- //
  let options = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          distribution: "linear",
          time: {
            tooltipFormat: "MMMM DD",
            unit: "day",
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Day",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          id: "y-axis-1",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Primary",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
        {
          position: "right",
          id: "y-axis-2",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Resale",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
      ],
    },
  };

  // ----- Render ----- //
  return (
    <>
      <Box sx={{width: "100%", p: 10}}>
        <Typography variant={"h6"}>{pastId} - {currentId}</Typography>
        <Line type={"line"} data={pastCounts.length == 0 ? {} : data} options={options}
              height={125}/>
      </Box>
    </>
  );
};




