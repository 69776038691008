import React from "react";

// ----- MUI -----
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavMenu from "../../components/others/NavMenu";

/**
 * Component that renders a card with an error message
 * @param props<{msg: string, show: boolean}> - props for the component
 * - msg<string> - The error message to display
 * - show<boolean> - If the error card should be shown or not
 * - dismissible<boolean> - If the error card should be dismissible or not
 * @returns {JSX.Element} - The component
 */
export default function Template(props: {
  isLoading: boolean;
  filtersComponent: JSX.Element;
  datagridComponent: JSX.Element;
  searchFunc: (searchTerm: string) => void;
}) {
  /**
   * Return the component
   */
  return (
    <Box>
      <div
        className="App"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* ----- Header ----- */}
        <NavMenu current={"search"} />
        <Box sx={{ p: 1, display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* ----- Time frame selector & search button ----- */}

            <Box sx={{ height: "73px" }} />

            <Button
              sx={{ mx: 2, mb: 2, py: 1, width: 130 }}
              size="small"
              variant="contained"
              disabled={props.isLoading}
              startIcon={
                props.isLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <SearchIcon />
                )
              }
              onClick={() => {
                props.searchFunc("");
              }}
            >
              Search
            </Button>
          </Box>

          <Divider orientation={"vertical"} />

          {/* ----- Filters ----- */}
          <Box
            className="filter-container"
            sx={{
              overflowY: "auto",
              display: "flex",
              ml: 1,
              pb: 0.25,
              pr: 7,
            }}
          >
            {props.filtersComponent}
          </Box>
        </Box>

        {/* ----- Data grid ----- */}
        <main style={{ height: "100%" }}>{props.datagridComponent}</main>
      </div>
    </Box>
  );
}
