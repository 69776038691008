// ----- Packages ----- //
import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";

// ----- MUI ----- //
import { DataGridPro, GridColDef, } from "@mui/x-data-grid-pro";
import { Box, Button, Card, Skeleton, Typography } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

// ----- Types ----- //
import { SgViaSale } from "../../../../Types/SgVia";
import { Colors } from "../../../../Types/Other";
import { rotatingAnimation } from "../../../../animations";

export enum Sellers {
  VIA = "viagogo",
  SG = "seatgeek",
}

// ----- Others ----- //
const VIAGOGO_API_URL = "/api/viagogo/sales/";
const SG_API_URL = "/api/seatgeek/sales/";

/**
 * Component to show sales from Viagogo and Seatgeek
 * @param props<{viagogoId: string, seatgeekId: string}> viagogoId and seatgeekId of the event
 */
const SgViaSales = (props: { viagogoId: string; seatgeekId: string }) => {
  // ----- State ----- //
  const [SgViaSales, setSgViaSales] = useState<SgViaSale[]>([] as SgViaSale[]);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Fetches the sales from Viagogo and Seatgeek
   */
  const fetchData = useCallback(() => {
    setSgViaSales([]);
    const promises = [];

    if (props.viagogoId)
      promises.push(axios.get(`${VIAGOGO_API_URL}${props.viagogoId}`));

    if (props.seatgeekId)
      promises.push(axios.get(`${SG_API_URL}${props.seatgeekId}`));

    setIsLoading(true);

    // check if promises is empty
    if (promises.length === 0) return;

    Promise.all(promises)
      .then((results) => {
        if (results.length === 0) return;

        if (results[0]?.data.length === 0 && results[1]?.data.length === 0) {
          setIsLoading(false);
          return;
        }

        const mergedSales: any = [];
        results.forEach((res, index) => {
          if (!res?.data) return;
          const seller = res?.config?.url?.includes("viagogo")
            ? Sellers.VIA
            : Sellers.SG;
          const convertedSales = res.data.map((sale: any) => {
            const date =
              index === 0 ? moment(sale.created_at) : moment(sale.putc);
            return {
              id: sale.listing_id || sale.id,
              date,
              price: sale.unit_price || sale.bp,
              qty: sale.quantity || sale.q,
              section: sale.section_name || sale.s,
              row: sale.row_name || sale.r,
              seller: seller,
            };
          });
          mergedSales.push(...convertedSales);
        });

        mergedSales.sort((a: any, b: any) => {
          return moment(b.date).diff(moment(a.date));
        });

        setSgViaSales(mergedSales);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });

  }, [props.viagogoId, props.seatgeekId]);

  /**
   * Updates the sales from Seatgeek
   */
  const updateSg = () => {
    setIsLoading(true);
    axios
      .post(`/api/seatgeek/refresh/${props.seatgeekId}`)
      .then(async () => {
        await fetchData();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // ----- UseEffect ----- //
  useEffect(() => {
    if (!props.viagogoId && !props.seatgeekId) {
      setIsLoading(false);
      return;
    }
    fetchData();
  }, [props.viagogoId, props.seatgeekId, fetchData]);

  // ----- Columns ----- //
  const columns: GridColDef[] = [
    {
      field: "seller",
      headerName: " ",
      minWidth: 10,
      width: 10,
      renderCell: () => "",
      cellClassName: (params) => {
        if (params.value === Sellers.VIA) return "via-sale-row";
        else return "sg-sale-row";
      },
    },
    {field: "qty", headerName: "Qty", width: 20},
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 75,
      flex: 1,
      valueFormatter: (params: any) => {
        return `$${params.value}`;
      }
    },
    {
      field: "date",
      headerName: "Date",
      width: 175,
      flex: 2,
      type: "dateTime",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY - hh:mm A");
      },
    },
    {field: "section", headerName: "Section", width: 65, flex: 1},
    {field: "row", headerName: "Row", width: 50, flex: 1},
    {field: "seat", headerName: "Seat", width: 75, flex: 1},
  ];

  // ----- Render ----- //
  return (
    <Box sx={{px: 5}}>
      {SgViaSales && SgViaSales.length > 0 ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 1,
            }}
          >
            <Card sx={{px: 2, pt: 0.5, pr: 4}}>
              <Typography variant={"h6"} color={Colors.VIA}>
                Viagogo
              </Typography>
              <Box sx={{top: -7, position: "relative"}}>
                <Typography variant={"body2"} color={"textSecondary"}>
                  | Sales:{" "}
                  {SgViaSales.filter((sale) => sale.seller === Sellers.VIA)
                    .map((sale) => sale.qty || 0)
                    .reduce((a, b) => a + b, 0)}
                </Typography>
              </Box>
            </Card>

            <Box sx={{display: "flex"}}>
              <Card
                sx={{
                  height: "fit-content",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <Button
                  variant={"text"}
                  onClick={updateSg}
                  disabled={isLoading}
                  sx={{
                    minHeight: 0,
                    minWidth: 0,
                    p: 0.5,
                  }}
                >
                  <SyncIcon
                    fontSize={"small"}
                    sx={{
                      animation: isLoading
                        ? `${rotatingAnimation} 0.75s infinite linear reverse`
                        : "",
                    }}
                  />
                </Button>
              </Card>

              <Card sx={{px: 2, pt: 0.5, pr: 4, borderTopLeftRadius: 0}}>
                <Typography variant={"h6"} color={Colors.SG}>
                  SeatGeek
                </Typography>
                <Box sx={{top: -7, position: "relative"}}>
                  <Typography variant={"body2"} color={"textSecondary"}>
                    | Sales:{" "}
                    {SgViaSales.filter((sale) => sale.seller === Sellers.SG)
                      .map((sale) => sale.qty)
                      .reduce((a, b) => a + b, 0)}
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Box>

          {SgViaSales && SgViaSales.length > 0 && (
            <DataGridPro
              sx={{height: "70vh"}}
              density={"compact"}
              loading={isLoading}
              columns={columns}
              rows={SgViaSales}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "row-even"
                  : "row-odd"
              }
            />
          )}
        </Box>
      ) : (
        isLoading ? (
          <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", my: 1}}>
              <Skeleton variant={"rounded"} width={150} height={60}/>
              <Skeleton variant={"rounded"} width={150} height={60}/>
            </Box>
            <Skeleton variant={"rounded"} width={665} height={870}/>
          </Box>
        ) : (
          <Typography variant={"h5"} color={"textSecondary"} sx={{textAlign: "center", mt: 3}}>
            No SG or VIA sales found
          </Typography>
        )
      )}
    </Box>
  );
};

export default SgViaSales;
