// ----- Packages ----- //
import axios from "axios";
import React, {memo, useEffect, useState} from "react";

// ----- MUI ----- //
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

// ----- Components ----- //
import ArtistDropCheckBtn from "./DropCheckBtn";

// ----- Types ----- //
import {TmPerformer} from "../../../Types/Tm";

/**
 * Displays the performers for an event
 * @param props<performersJSON> - The performers for the event
 */
const EventPerformers = memo((props: { performersJSON: string }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const performers = JSON.parse(props.performersJSON) as TmPerformer[];
    const ids = performers.map((p: any) => p.id).join(",");

    // Get the watched status for each performer
    axios
      .get(`https://count.plessinc.com/artists/dropcheck`, {
        params: {artists: ids},
      })
      .then((resp) => {
        resp.data.forEach((artist: any) => {
          const performer = performers.find((p: any) => p.id === artist.id);
          if (performer) performer.watched = artist.watched;
        });
        setRows(performers as any);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.performersJSON]);

  // Return the table of performers
  return (
    <div>
      <TableContainer
        sx={{maxHeight: 150, overflowY: "auto", overflowX: "hidden"}}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: "bold", p: 1, pb: 0.25}}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonIcon sx={{position: "relative", top: -2}}/>
                  Performers
                </Typography>
              </TableCell>
              <TableCell sx={{fontWeight: "bold"}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{"&:last-child td, &:last-child th": {border: 0}}}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    maxWidth: 150,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={row.name}
                >
                  {row.name}
                </TableCell>
                <TableCell style={{padding: 0}}>
                  <ArtistDropCheckBtn
                    row={row}
                    callBack={() => setRows([...rows])}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default EventPerformers;
