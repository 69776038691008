// ----- Packages ----- //
import axios from "axios";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import React, { useCallback, useEffect, useState } from "react";

// ----- MUI Components -----
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, Button, Card, MenuItem, Skeleton, TextField, Tooltip, } from "@mui/material";

// ----- MUI Icons -----
import OrderedIcon from "@mui/icons-material/SignalCellularAlt";
import UnorderedIcon from "@mui/icons-material/Equalizer";
import LeftArrowIcon from "@mui/icons-material/ArrowBackIosNew";
import RightArrowIcon from "@mui/icons-material/ArrowForwardIos";
import PlayIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import HeightIcon from "@mui/icons-material/Height";

// ----- Components ----- //
import ErrorCard from "../../../others/cards/ErrorCard";
import { Colors } from "../../../../Types/Other";

/**
 * All the graphs for the tickets by sections
 * @param props {eventId: string} - The event id
 */
const MapGraph = (props: { eventId: string }) => {
  /* States */
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [isPlaying, setIsPlaying] = useState(false);

  /* Data */
  const [inventoryPerDay, setInventoryPerDay] = useState<any[]>([]);

  /* Graphs */
  const [graphDataPerDay, setGraphDataPerDay] = useState<any[]>([]);
  const [maxTickets, setMaxTickets] = useState<number>(0);
  const [lockHeight, setLockHeight] = useState<boolean>(true);

  /* Filters */
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [orderBy, setOrderBy] = useState<string>("inv");
  const [range, setRange] = useState<number>(7);

  /* Others */
  const [cursor, setCursor] = useState(0);
  const [orderInventory, setOrderInventory] = useState<boolean>(true);

  /* Functions */

  /**
   * Generate the graph data from the inventoryPerDay
   * @param data<array> The inventoryPerDay data
   * @param from<string> The start date
   * @param to<string> The end date
   */
  function generateGraphData(data: any, from: any, to: any) {
    let dataCopy = JSON.parse(JSON.stringify(data));

    // only keep data between fromDate and toDate
    let filteredData = dataCopy.filter((d: any) => {
      return (
        moment(d.date).isSameOrAfter(from) &&
        moment(d.date.substring(0, 10)).isSameOrBefore(to)
      );
    });

    // calculate the decrease of tickets by section
    for (let i = 0; i < filteredData.length; i++) {
      if (i === 0) {
        filteredData[i].data.forEach((d: any) => {
          d.decrease = 0;
        });
      } else {
        if (filteredData[i].totalTickets > 0)
          filteredData[i].data.forEach((d: any, j: number) => {
            d.decrease = filteredData[i - 1].data[j]?.tickets - d.tickets;
          });
      }
    }

    // Calculate the total decrease by section for the whole period
    const decreaseBySection = {} as any;
    filteredData.forEach((d: any) => {
      d.data.forEach((s: any) => {
        if (!decreaseBySection[s.section]) {
          decreaseBySection[s.section] = 0;
        }
        if (s.decrease < 0) s.decrease = 0;
        decreaseBySection[s.section] += s.decrease;
      });
    });

    // Assign the decrease to the sections and return the data
    return Object.keys(decreaseBySection).map((k) => ({
      section: k,
      decrease: decreaseBySection[k],
    }));
  }

  /**
   * Handles the change of the select
   * @param event<SelectChangeEvent> The event
   */
  const handleChange = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value as string);
  };

  /**
   * Next day
   * Updates the cursor and the dates for the next day
   */
  const nextDay = useCallback(() => {
    if (isPlaying || cursor >= inventoryPerDay.length - 1) return;
    const from =
      inventoryPerDay[cursor - range + 1 < 0 ? 0 : cursor - range + 1].date;
    const to = inventoryPerDay[cursor + 1].date;

    setCursor(cursor + 1);
    setFromDate(from);
    setToDate(to);
  }, [cursor, inventoryPerDay, range, isPlaying]);

  /**
   * Previous day
   * Updates the cursor and the dates for the previous day
   */
  const previousDay = useCallback(() => {
    if (isPlaying || cursor === 0) return;
    const from =
      inventoryPerDay[cursor - range - 1 < 0 ? 0 : cursor - range - 1].date;
    const to = inventoryPerDay[cursor - 1].date;

    setCursor(cursor - 1);
    setFromDate(from);
    setToDate(to);
  }, [cursor, inventoryPerDay, range, isPlaying]);

  /**
   * Play or pause the loop
   */
  const playPause = useCallback(() => {
    if (!isPlaying) {
      setCursor(0);
      setFromDate(inventoryPerDay[0].date);
      setToDate(inventoryPerDay[0].date);
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying, inventoryPerDay]);

  /**
   * Handle the change of the range
   */
  const handleRangeChange = useCallback(
    (value: any) => {
      let range = parseInt(value) || 1;
      if (range < 1) return;
      if (range >= inventoryPerDay.length) range = inventoryPerDay.length - 1;
      setRange(range);
      setFromDate(
        inventoryPerDay[cursor - range < 0 ? 0 : cursor - range].date
      );
    },
    [cursor, inventoryPerDay]
  );

  /* UseEffects */

  /**
   * Principal useEffect, fetches the data from the API and formats it for the graphs
   * Run only once, when the component is mounted
   */
  useEffect(() => {
    if (!props.eventId) {
      setIsLoading(false);
      return;
    }

    const fetch = async () => {
      try {
        const resp = await axios(`/api/counters/${props.eventId}`);

        const days = [] as any[];

        if (resp.data.length === 0) {
          setError("No data available");
          setIsLoading(false);
          return;
        }

        for (let i = 0; i < resp.data.length; i++) {
          const tickets = JSON.parse(resp.data[i].ticketsBySections);
          const sections = JSON.parse(resp.data[i].sectionsName);

          days.push({
            date: resp.data[i].creationDate,
            data: tickets.map((t: any, i: number) => ({
              section: sections[i],
              tickets: t,
            })),
          });
        }

        // get the section with the most tickets
        const max = days.reduce((acc: number, d: any) => {
          const max = d.data.reduce((acc: number, s: any) => {
            return Math.max(acc, s.tickets);
          }, 0);
          return Math.max(acc, max);
        }, 0);

        setMaxTickets(max);

        // Order all days by sections
        days.forEach((d: any) => {
          d.data.sort((a: any, b: any) => {
            return a.section.localeCompare(b.section);
          });
        });

        // Order by date
        days.sort((a, b) => {
          return moment(a.date).diff(moment(b.date));
        });

        for (let i = 0; i < days.length; i++) {
          if (i === 0) {
            days[i].data.forEach((d: any) => {
              d.decrease = 0;
            });
          } else {
            // count the total tickets for the day
            days[i].totalTickets = days[i].data.reduce(
              (acc: number, d: any) => acc + d.tickets,
              0
            );

            days[i].data.forEach((d: any, j: number) => {
              if (days[i].totalTickets > 0)
                d.decrease = days[i - 1].data[j]?.tickets - d.tickets;
              else d.decrease = 0;
            });
          }
        }

        setCursor(days.length - 1);

        setFromDate(
          moment(days[days.length - 8 > 0 ? days.length - 8 : 0].date)
        );
        setToDate(moment(days[days.length - 1].date));

        if (days.length < 8) setRange(days.length - 1);
        setInventoryPerDay(days);
        setIsLoading(false);
      } catch (err) {
        setError(err as string);
      }
    };

    fetch().then();
  }, [props.eventId]);

  /**
   * Updates the graph data when a filter is changed
   * Run when the inventoryPerDay, cursor, range or orderInventory changes
   */
  useEffect(() => {
    if (!inventoryPerDay[cursor]) return;
    let data = inventoryPerDay[cursor].data;
    const copy = JSON.parse(JSON.stringify(data));

    const decreaseBetweenDays = generateGraphData(
      inventoryPerDay,
      fromDate,
      toDate
    );

    // Merge the decrease between days with the inventory per day
    copy.forEach((d: any) => {
      const section = decreaseBetweenDays.find(
        (decrease: any) => decrease.section === d.section
      );

      d.decrease = section ? section.decrease : 0;
    });

    // Order by inventory or decrease
    if (orderInventory) {
      switch (orderBy) {
        case "inv":
          copy.sort((a: any, b: any) => {
            return b.tickets - a.tickets;
          });
          break;
        case "dec":
          copy.sort((a: any, b: any) => {
            return b.decrease - a.decrease;
          });
          break;
      }
    }

    setGraphDataPerDay(copy);
  }, [
    inventoryPerDay,
    cursor,
    range,
    orderInventory,
    orderBy,
    fromDate,
    toDate,
  ]);

  /**
   * Loop through the days if isPlaying is true
   * Updates the cursor and the dates for the next day
   */
  useEffect(() => {
    if (isPlaying) {
      setTimeout(() => {
        if (cursor >= inventoryPerDay.length - 1) {
          setIsPlaying(false);
          return;
        }

        const from =
          inventoryPerDay[cursor - range + 1 < 0 ? 0 : cursor - range + 1].date;
        const to =
          inventoryPerDay[cursor + 1 >= inventoryPerDay.length ? 0 : cursor + 1]
            .date;

        setFromDate(from);
        setToDate(to);
        setCursor(cursor + 1);
      }, 500);
    }
  }, [isPlaying, cursor, inventoryPerDay, range]);

  /**
   * Handle keyboard events
   * - Left arrow: previous day
   * - Right arrow: next day
   * - Space: play/pause
   * - Up arrow: increase range
   * - Down arrow: decrease range
   */
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      switch (event.keyCode) {
        case 37: // left arrow
          previousDay();
          break;
        case 39: // right arrow
          nextDay();
          break;
        case 32: // space
          playPause();
          break;
        case 38: // up arrow
          handleRangeChange(range + 1);
          break;
        case 40: // down arrow
          handleRangeChange(range - 1);
          break;
        default:
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isPlaying,
    inventoryPerDay,
    cursor,
    nextDay,
    previousDay,
    playPause,
    handleRangeChange,
    range,
  ]);

  /* Graphs */

  /**
   * Principal graph, shows the quantity left and the decrease over range days
   */
  const data = {
    labels: graphDataPerDay.map((d) => d.section),

    datasets: [
      {
        label: "TM Inventory",
        data: graphDataPerDay.map((d) => d.tickets),
        backgroundColor: Colors.TM + "CC", // CC = 80% opacity
      },
      {
        label: `Sales Over Day(s)`,
        data: graphDataPerDay.map((d) => d.decrease),
        backgroundColor: Colors.TM_RESALE + "CC", // CC = 80% opacity
      },
    ],
  };

  /**
   * Options for the graph
   */
  let options = {
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            min: 0,
            max: lockHeight ? maxTickets : undefined,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          id: "x-axis-1",
          type: "category",
          labels: graphDataPerDay.map((d) => d.section),
          ticks: {
            maxRotation: 90,
            callback: function (value: any) {
              return value.length > 10 ? value.substring(0, 10) + "..." : value;
            },
          },
        },
      ],
    },
  };

  /**
   * JSX to render
   * - Contain the graph and the different controls
   */
  return (
    <Box
      sx={{
        m: 5,
        mt: 3,
      }}
    >
      {error ? (
        <ErrorCard
          show={true}
          details={{
            component: "components/event/graphs/Section.tsx",
            error: error,
          }}
        />
      ) : (
        <div>
          <Box sx={{display: "flex", justifyContent: graphDataPerDay.length > 100 ? "space-between" : "center"}}>
            <Box sx={{minWidth: graphDataPerDay.length > 100 ? "150%" : "65%", height: "500px"}}>
              <Card sx={{p: 2}}>
                <Box sx={{display: "flex"}}>
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{display: "flex", alignItems: "center"}}>
                      <Tooltip
                        title={"Play / Pause (SPACE)"}
                        enterDelay={500}
                        enterNextDelay={500}
                      >
                        <div>
                          <Button
                            sx={{mx: 1}}
                            onClick={() => {
                              playPause();
                            }}
                            variant={isPlaying ? "contained" : "outlined"}
                            disabled={isLoading}
                          >
                            {isPlaying ? <PauseIcon/> : <PlayIcon/>}
                          </Button>
                        </div>
                      </Tooltip>

                      <Tooltip
                        title={"Show The Previous Day (LEFT ARROW)"}
                        enterDelay={500}
                        enterNextDelay={500}
                      >
                        <div>
                          <Button
                            sx={{mx: 1}}
                            onClick={() => previousDay()}
                            disabled={isPlaying || cursor === 0 || isLoading}
                          >
                            <LeftArrowIcon/>
                          </Button>
                        </div>
                      </Tooltip>

                      <h2 style={{margin: "0px"}}>
                        {!isLoading && inventoryPerDay[cursor]?.date ? (
                          moment(inventoryPerDay[cursor]?.date).format(
                            "DD/MM/YYYY"
                          )
                        ) : (
                          <Skeleton
                            variant="rounded"
                            width={125}
                            height={30}
                            animation={"wave"}
                          />
                        )}
                      </h2>

                      <Tooltip
                        title={"Show The Next Day (RIGHT ARROW)"}
                        enterDelay={500}
                        enterNextDelay={500}
                      >
                        <div>
                          <Button
                            sx={{mx: 1}}
                            onClick={() => nextDay()}
                            disabled={
                              isPlaying ||
                              cursor === inventoryPerDay.length - 1 ||
                              isLoading
                            }
                          >
                            <RightArrowIcon/>
                          </Button>
                        </div>
                      </Tooltip>
                    </Box>

                    <Tooltip
                      title={
                        "The Number Of Days To Calculate The Sales (UP/DOWN ARROW)"
                      }
                      enterDelay={500}
                      enterNextDelay={500}
                    >
                      <TextField
                        sx={{display: "block", width: "110px"}}
                        size="small"
                        id="range"
                        label="Days of sales"
                        type="number"
                        value={range}
                        onChange={(e) => handleRangeChange(e.target.value)}
                      />
                    </Tooltip>

                    <Tooltip
                      title={"Lock The Height Of The Graph"}
                      enterDelay={500}
                      enterNextDelay={500}
                    >
                      <div>
                        <Button
                          sx={{ml: 5}}
                          variant={lockHeight ? "contained" : "outlined"}
                          onClick={() => {
                            setLockHeight(!lockHeight);
                          }}
                          disabled={isLoading}
                        >
                          <HeightIcon/>
                        </Button>
                      </div>
                    </Tooltip>

                    <Tooltip
                      title={"Order The Sections"}
                      enterDelay={500}
                      enterNextDelay={500}
                    >
                      <div>
                        <Button
                          sx={{ml: 2}}
                          variant={orderInventory ? "contained" : "outlined"}
                          onClick={() => {
                            setOrderInventory(!orderInventory);
                          }}
                          disabled={isLoading}
                        >
                          {orderInventory ? (
                            <OrderedIcon sx={{transform: "scaleX(-1)"}}/>
                          ) : (
                            <UnorderedIcon/>
                          )}
                        </Button>
                      </div>
                    </Tooltip>

                    <Select
                      id="order-by"
                      value={orderBy}
                      onChange={handleChange}
                      size={"small"}
                      sx={{ml: 1}}
                      disabled={isLoading}
                    >
                      <MenuItem value={"inv"}>Inventory</MenuItem>
                      <MenuItem value={"dec"}>Sales</MenuItem>
                    </Select>
                  </Box>
                  <Box sx={{ml: 3}}>
                    <small>
                      <strong>Inventory on </strong>
                      {!isLoading &&
                        moment(inventoryPerDay[cursor].date).format(
                          "MM/DD/YYYY"
                        )}
                    </small>
                    <br/>
                    <small>
                      <strong>Sales from </strong>
                      {!isLoading &&
                        moment(
                          inventoryPerDay[
                            cursor - range < 0 ? 0 : cursor - range
                            ].date
                        ).format("MM/DD/YYYY")}
                      <strong> to </strong>
                      {!isLoading &&
                        moment(inventoryPerDay[cursor].date).format(
                          "MM/DD/YYYY"
                        )}
                      <strong> (</strong>
                      {moment(toDate).diff(
                        moment(fromDate).startOf("day"),
                        "days"
                      )}
                      <strong> days)</strong>
                    </small>
                  </Box>
                </Box>
              </Card>
              <Bar data={data} options={options}/>
            </Box>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default MapGraph;
