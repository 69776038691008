// ----- Packages ----- //
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

// ----- MUI ----- //
import { Box, Button, Card, Menu, MenuItem, Skeleton, } from "@mui/material";

// ----- Components ----- //
import NavMenu from "../../components/others/NavMenu";
import EventStats from "../../components/event/details/Stats";
import EventPerformers from "../../components/event/artist/Performers";
import TicketariaGraph from "../../components/event/details/Ticketaria";

// ----- Other ----- //
import { TmEvent } from "../../Types/Tm";
import { DsSale, MatchingEvents } from "../../Types/Other";

import EventActions from "../../components/event/Actions";
import EventInfo from "../../components/event/details/Info";
import DetailTabs from "../../components/event/details/Tabs";
import ErrorCard from "../../components/others/cards/ErrorCard";
import DsSales from "../../components/event/details/DsSales";

/**
 * This component is used to display all the details of a Ticketmaster event
 * - Show the event details and different graphs
 * @constructor Tm - The Ticketmaster event details page
 */
const Tm = () => {
  // ----- State ----- //
  const [noEvent, setNoEvent] = useState<boolean>(false);
  const [eventDetail, setEventDetail] = useState<TmEvent>();
  const [links, setLinks] = useState<{}>({}) as any;

  const [linksMenuAnchor, setLinksMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const linksMenuOpen = Boolean(linksMenuAnchor);

  // ----- Tm ----- //
  let {eventId} = useParams<{ eventId: string }>();

  // ----- Skybox ----- //
  const [skyBoxId, setSkyBoxId] = useState<string>("");
  const [dsSales, setDsSales] = useState<DsSale[]>([]);

  // ----- Others ----- //
  const [matchingEvents, setMatchingEvents] = useState<MatchingEvents>(
    {} as MatchingEvents
  );

  useEffect(() => {
    const fetch = async () => {
      document.title = "Loading...";

      try {
        // ----- Fetch -----
        const eventResp = await axios(`/api/events/${eventId}`);
        if (!eventResp.data.event) {
          setNoEvent(true);
          document.title = "No event found";
          return;
        }

        const dsResp = await axios(
          `https://ds.plessinc.com/api/events?type=activetmids`
        );
        const dcResp = await axios(
          `https://count.plessinc.com/event/isDropWatched`
        );

        // ----- Get data from resp -----
        const event = eventResp.data.event as TmEvent;
        const dsEvents = await dsResp.data.events;
        const dcEvents = await dcResp.data.events;

        event.in_ds = !!dsEvents.includes(event.url_id);

        const dsSalesResp = await axios(`/api/ds/sales/${eventId}`);
        const dsSales = await dsSalesResp.data;
        setDsSales(dsSales);

        // ----- Format the data -----
        event.isWatched = !!dcEvents.includes(event.url_id);

        if (event.timezone)
          event.event_date = moment(event.event_date)
            .tz(event.timezone)
            .format("MM/DD/YYYY HH:mm");

        const linkResp = await axios(
          `https://api.plessinc.com/api/event/view/ticketmaster/` + event.url_id
        );

        let matchingEvents = linkResp.data.event.matching_events;
        if (matchingEvents) setMatchingEvents(matchingEvents);

        let links = {} as any;

        if (event.url_id)
          links["ticketmaster"] =
            "https://www.ticketmaster.com/event/" + event.url_id;
        if (event.viagogo_id)
          links["stubhub"] =
            "https://www.stubhub.com/event/" + event.viagogo_id;

        const vividId = matchingEvents?.vividseats_id;
        if (vividId) setSkyBoxId(vividId);
        else {
          axios(`/api/skybox/${event.url_id}`)
            .then((resp) => {
              setSkyBoxId(resp.data.skybox_id);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        if (linkResp.data && linkResp.data.event.matching_events) {
          for (const [key, value] of Object.entries(
            linkResp.data.event.matching_events
          ) as any) {
            const newKey = key.substring(7);
            if (value && value.url && !links[newKey]) links[newKey] = value.url;
          }
        }

        setLinks(links);

        // ----- Set -----
        document.title = event.event_name;
        setEventDetail(event);
      } catch (err) {
        console.log(err);
      }
    };
    fetch().then();
  }, [eventId]);

  return noEvent ? (
    <Box // center in page
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
      }}
    >
      <ErrorCard
        details={{
          component: "pages/Details/Tm.tsx",
          error: "No event found for " + eventId,
        }}
        show={true}
      />
    </Box>
  ) : (
    <Box sx={{pt: 1}}>
      <NavMenu current={""}/>
      {/* Show loading until event is ready */}
      {eventDetail ? (
        <Box
          sx={{
            mx: 2,
            py: 1,
            display: "flex",
            alignItems: "start",
            overflowY: "auto",
          }}
        >
          <Card
            sx={{
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              minWidth: "40px",
            }}
          >
            <EventActions event={eventDetail}/>
          </Card>

          <EventInfo eventDetail={eventDetail}/>

          <Card sx={{ml: 3, minWidth: "550px"}}>
            <EventStats event={eventDetail}/>
            {/* Links to websites that sell tickets for this event */}
            <Box>
              {Object.keys(links)
                .slice(0, 3)
                .map((key) => (
                  <Button
                    sx={{m: 1}}
                    key={key}
                    variant="contained"
                    href={links[key]}
                    target="_blank"
                    rel="noreferrer"
                    color="secondary"
                    size="small"
                  >
                    <Box sx={{fontSize: "inherit"}}>{key}</Box>
                  </Button>
                ))}
              {/* If there are more than 3 links, show a "More" button that opens a menu */}
              {Object.keys(links).length > 3 && (
                <Button
                  id="basic-button"
                  aria-controls={linksMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={linksMenuOpen ? "true" : undefined}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    setLinksMenuAnchor(event.currentTarget)
                  }
                  sx={{m: 1}}
                  variant="outlined"
                  color="secondary"
                  size="small"
                >
                  More
                </Button>
              )}
              <Menu
                id="links-menu"
                anchorEl={linksMenuAnchor}
                open={linksMenuOpen}
                onClose={() => setLinksMenuAnchor(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {Object.keys(links)
                  .slice(3)
                  .map((key) => (
                    <MenuItem
                      key={key}
                      onClick={() => {
                        setLinksMenuAnchor(null);
                        window.open(links[key], "_blank");
                      }}
                    >
                      {key.toUpperCase()}
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
          </Card>

          <Card sx={{ml: 3, minWidth: "150px"}}>
            <EventPerformers performersJSON={eventDetail.performers}/>
          </Card>

          <DsSales eventDetail={eventDetail} dsSales={dsSales.slice().reverse()}/>

          <Box sx={{ml: 3}}>
            <TicketariaGraph eventId={skyBoxId}/>
          </Box>
        </Box>
      ) : (
        <Box sx={{ml: 2, pt: 1, display: "flex", gap: 3}}>
          <Box sx={{display: "flex"}}>
            <Skeleton
              width={40}
              height={140}
              variant="rounded"
              sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
            />
            <Skeleton
              width={400}
              height={165}
              variant="rounded"
              sx={{borderTopLeftRadius: 0}}
            />
          </Box>
          <Skeleton width={500} height={145} variant="rounded"/>
          <Skeleton width={150} height={100} variant="rounded"/>
          <Skeleton width={75} height={100} variant="rounded"/>
          <Skeleton width={800} height={165} variant="rounded"/>
        </Box>
      )}

      <DetailTabs
        tm_id={eventId || ""}
        seatgeek_id={matchingEvents?.seatgeek_id}
        viagogo_id={matchingEvents?.viagogo_id || matchingEvents?.sh_id}
        threshold_alert={eventDetail?.tm_remaining_threshold}
      />
    </Box>
  );
};

export default Tm;
