import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";
import setupLogRocketReact from 'logrocket-react';

import { LicenseInfo } from "@mui/x-license-pro";

// License KEY for MUI PRO components
// 89dd8f6d82c96d63de7663c452276ff6Tz03MjExNixFPTE3MjI3NzgwMTgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=
LicenseInfo.setLicenseKey(
  "a65e73af85e600874ef688d44f77a6ceTz01MDQ1MSxFPTE2OTQxOTc0NTQ0ODIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

LogRocket.init('imngox/bowman-frontend');
setupLogRocketReact(LogRocket);

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
