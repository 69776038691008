// ----- Packages ----- //
import React, { memo } from "react";

// ----- MUI ----- //
import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";

// ----- Components ----- //
import MapGraph from "./tabs/Sections";
import SgViaSales from "./tabs/SgViaSales";
import InventoryGraph from "./tabs/Listings";
import PreOnSaleGraph from "./tabs/PreOnSale";
import ViagogoSalesGraph from "./tabs/ViagogoSales";
import SeatgeekSalesGraph from "./tabs/SeatgeekSales";

/**
 * Component to show the tabs in the event details page
 * @param props{tm_id: string, seatgeek_id: string, viagogo_id: string, threshold_alert: number | undefined}
 *  tm_id, seatgeek_id, viagogo_id and threshold_alert of the event
 */
const DetailTabs = memo((props: {
  tm_id: string;
  seatgeek_id: string;
  viagogo_id: string;
  threshold_alert: number | undefined;
}) => {
  const {tm_id, seatgeek_id, viagogo_id, threshold_alert} = props;

  const [tab, setTab] = React.useState(
    window.location.hash
      ? window.location.hash.slice(1).replace(/%20/g, "")
      : "listings"
  );

  // ----- Render ----- //
  return (
    <Box>
      <Box sx={{pl: 4, pt: 1}}>
        <Tabs
          sx={{minHeight: 0}}
          value={tab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event: React.SyntheticEvent, newValue: string) => {
            window.location.hash = newValue;
            setTab(newValue);
          }}
        >
          <Tab value="listings" label="Ticketmaster" sx={{minHeight: 0, py: 1}}/>
          <Tab value="seatgeek" label="Seatgeek"
               disabled={!seatgeek_id}
               sx={{minHeight: 0, py: 1}}/>
          <Tab
            value="viagogo_sale"
            label="Viagogo"
            disabled={!viagogo_id}
            sx={{minHeight: 0, py: 1}}
          />
          <Tab
            value="pre_on_sale"
            label="PRESALE & ONSALE"
            sx={{minHeight: 0, py: 1}}
          />
          <Tab
            value="section"
            label="Section Analysis"
            sx={{minHeight: 0, py: 1}}
          />
        </Tabs>
      </Box>

      <Divider sx={{my: 1}}/>

      {/* Show graph based on tab */}
      {tab === "listings" && (
        <Grid container>
          <Grid item sm={12} md={8.5}>
            <Box
              sx={{borderRight: "1px solid", borderColor: "divider", m: 1}}
            >
              <InventoryGraph
                tmEventId={tm_id || ""}
                sgEventId={seatgeek_id}
                alertThreshold={threshold_alert || null}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={3.5}>
            {(seatgeek_id || viagogo_id) && (
              <SgViaSales
                seatgeekId={seatgeek_id || ""}
                viagogoId={viagogo_id || ""}
              />
            )}
          </Grid>
        </Grid>
      )}
      {tab === "seatgeek" && (
        <SeatgeekSalesGraph seatgeekId={seatgeek_id}/>
      )}
      {tab === "viagogo_sale" && (
        <ViagogoSalesGraph viagogoId={viagogo_id || ""}/>
      )}
      {tab === "pre_on_sale" && <PreOnSaleGraph eventId={tm_id || ""}/>}
      {tab === "section" && <MapGraph eventId={tm_id || ""}/>}
    </Box>
  );
});

export default DetailTabs;
