// ----- Packages ----- //
import React, { useState } from "react";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";

// ----- MUI ----- //
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import FilterDropCheckIcon from "@mui/icons-material/Search";
import FilterAlertIcon from "@mui/icons-material/NotificationsNone";
import FilterParkingIcon from "@mui/icons-material/DriveEta";
import ShowIcon from "@mui/icons-material/Visibility";

// ----- Components ----- //
import DefaultInput from "../../../../components/inputs/DefaultInput";
import MinMaxRemainingInputs from "../../../../components/inputs/MinMaxRemaining";

// ----- Types ----- //
import { TmUpFiltersData, TmUpFiltersProps } from "../../../../Types/Tm";
import FiltersPreset from "../../../../components/inputs/FiltersPreset";

// ----- Animations ----- //
import { bouncingAnimation, ringingAnimation, zoomingAnimation, } from "../../../../animations";

// ----- US States ----- //
const states = [
  {name: "", code: ""},
  {name: "Alabama", code: "AL"},
  {name: "Alaska", code: "AK"},
  {name: "Arizona", code: "AZ"},
  {name: "Arkansas", code: "AR"},
  {name: "California", code: "CA"},
  {name: "Colorado", code: "CO"},
  {name: "Connecticut", code: "CT"},
  {name: "Delaware", code: "DE"},
  {name: "Florida", code: "FL"},
  {name: "Georgia", code: "GA"},
  {name: "Hawaii", code: "HI"},
  {name: "Idaho", code: "ID"},
  {name: "Illinois", code: "IL"},
  {name: "Indiana", code: "IN"},
  {name: "Iowa", code: "IA"},
  {name: "Kansas", code: "KS"},
  {name: "Kentucky", code: "KY"},
  {name: "Louisiana", code: "LA"},
  {name: "Maine", code: "ME"},
  {name: "Maryland", code: "MD"},
  {name: "Massachusetts", code: "MA"},
  {name: "Michigan", code: "MI"},
  {name: "Minnesota", code: "MN"},
  {name: "Mississippi", code: "MS"},
  {name: "Missouri", code: "MO"},
  {name: "Montana", code: "MT"},
  {name: "Nebraska", code: "NE"},
  {name: "Nevada", code: "NV"},
  {name: "New Hampshire", code: "NH"},
  {name: "New Jersey", code: "NJ"},
  {name: "New Mexico", code: "NM"},
  {name: "New York", code: "NY"},
  {name: "North Carolina", code: "NC"},
  {name: "North Dakota", code: "ND"},
  {name: "Ohio", code: "OH"},
  {name: "Oklahoma", code: "OK"},
  {name: "Oregon", code: "OR"},
  {name: "Pennsylvania", code: "PA"},
  {name: "Rhode Island", code: "RI"},
  {name: "South Carolina", code: "SC"},
  {name: "South Dakota", code: "SD"},
  {name: "Tennessee", code: "TN"},
  {name: "Texas", code: "TX"},
  {name: "Utah", code: "UT"},
  {name: "Vermont", code: "VT"},
  {name: "Virginia", code: "VA"},
  {name: "Washington", code: "WA"},
  {name: "West Virginia", code: "WV"},
  {name: "Wisconsin", code: "WI"},
  {name: "Wyoming", code: "WY"},

  // Canada
  {name: "Alberta", code: "AB"},
  {name: "British Columbia", code: "BC"},
  {name: "Manitoba", code: "MB"},
  {name: "New Brunswick", code: "NB"},
  {name: "Newfoundland and Labrador", code: "NL"},
  {name: "Northwest Territories", code: "NT"},
  {name: "Nova Scotia", code: "NS"},
  {name: "Nunavut", code: "NU"},
  {name: "Ontario", code: "ON"},
  {name: "Prince Edward Island", code: "PE"},
  {name: "Quebec", code: "QC"},
  {name: "Saskatchewan", code: "SK"},
  {name: "Yukon", code: "YT"},
];

/**
 * Function to render all the filters for the upcoming events
 * @param props<TmUpFiltersProps> The props for the filters
 * @returns {JSX.Element} The filters component
 */
export default function TmUpFilters(props: TmUpFiltersProps) {
  // ----- Props ----- //
  const {setFilters, search} = props;

  // ----- States ----- //
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeInputs, setActiveInputs] = useState<string[]>([]);
  const [showMaxInputs, setShowMaxInputs] = useState<boolean>(false);

  // ----- Filters ----- //
  const [filters] = useState<TmUpFiltersData>({
    fromDate:
      searchParams.get("fromDate") ||
      moment().subtract(1, "days").format("YYYY-MM-DD"),
    toDate: searchParams.get("toDate") || moment().format("YYYY-MM-DD"),
    eventDateMin:
      searchParams.get("eventDateMin") || moment().format("YYYY-MM-DD"),
    minDataPoints: searchParams.get("minDataPoints")
      ? parseInt(searchParams.get("minDataPoints") || "7")
      : 7,
    artist: searchParams.get("artist") || "",
    venue: searchParams.get("venue") || "",
    category: searchParams.get("category") || "",
    state: searchParams.get("state") || "",
    country: searchParams.get("country") || "",
    minDecrease: searchParams.get("minDecrease")
      ? searchParams.get("minDecrease")
      : "",
    maxDecrease: searchParams.get("maxDecrease")
      ? searchParams.get("maxDecrease")
      : "",
    minAvgDecrease: searchParams.get("minAvgDecrease")
      ? searchParams.get("minAvgDecrease")
      : "",
    maxAvgDecrease: searchParams.get("maxAvgDecrease")
      ? searchParams.get("maxAvgDecrease")
      : "",
    minRemaining: searchParams.get("minRemaining")
      ? searchParams.get("minRemaining")
      : "",
    maxRemaining: searchParams.get("maxRemaining")
      ? searchParams.get("maxRemaining")
      : "",
    minMaxRemainIsNb: searchParams.get("minMaxRemainIsNb")
      ? searchParams.get("minMaxRemainIsNb") === "true"
      : true,
    minMir: searchParams.get("minMir") ? searchParams.get("minMir") : "",
    maxMir: searchParams.get("maxMir") ? searchParams.get("maxMir") : "",
    minMqr: searchParams.get("minMqr") ? searchParams.get("minMqr") : "",
    maxMqr: searchParams.get("maxMqr") ? searchParams.get("maxMqr") : "",
    onlyWithThreshold: searchParams.get("onlyWithThreshold")
      ? searchParams.get("onlyWithThreshold") === "true"
      : false,
    onlyInDropCheck: searchParams.get("onlyInDropCheck")
      ? searchParams.get("onlyInDropCheck") === "true"
      : false,
    statsFor: searchParams.get("statsFor") || "tm",
    minDsInvoices: searchParams.get("minDsInvoices") || "",
    minDsDate: searchParams.get("minDsDate") || "",
    isParking: searchParams.get("isParking")
      ? searchParams.get("isParking") === "true"
      : false,
    isCW: searchParams.get("isCW")
      ? searchParams.get("isCW") === "true"
      : false,
    minSgSells_total: searchParams.get("minSgSells_total") || "",
    minSgSells_1: searchParams.get("minSgSells_1") || "",
    minSgSells_7: searchParams.get("minSgSells_7") || "",
    minSgSells_30: searchParams.get("minSgSells_30") || "",
  } as TmUpFiltersData);

  /**
   * Function that check it the input is active
   * @param id<string> - The id of the input
   */
  function checkIfDisabled(id: string) {
    if (activeInputs.length > 0) return !activeInputs.includes(id);
    else return false;
  }

  /**
   * Function that set the filters on load
   */
  React.useEffect(() => {
    setFilters(filters);

    // Set the active inputs on load
    if (filters.artist !== "" || filters.venue !== "")
      setActiveInputs(["artist", "venue"]);
    if (filters.onlyInDropCheck) setActiveInputs(["only-in-dropcheck"]);
    if (filters.onlyWithThreshold) setActiveInputs(["only-with-threshold"]);
  }, [filters, setFilters]);

  /**
   * Function that update the value of a filter
   * @param key<string> - The key of the filter
   * @param value<any> - The value of the filter
   */
  const updateValue = (key: string, value: any) => {
    // @ts-ignore
    filters[key] = value;
    setFilters({...filters, [key]: value});

    value !== "" ? searchParams.set(key, value) : searchParams.delete(key);
    setSearchParams(searchParams);
  };

  /**
   * Return the component
   * @returns {JSX.Element} - The component
   */
  return (
    <Box>
      <Box sx={{display: "flex"}}>
        <Box>
          {/* From Date */}
          <DefaultInput
            id={"from-date"}
            label={"From Date"}
            title={"Filter all data after this date"}
            type={"date"}
            value={moment(filters.fromDate).format("YYYY-MM-DD")}
            error={filters.fromDate >= filters.toDate}
            startSearch={search}
            setValue={(value: string) =>
              value !== "" && updateValue("fromDate", value)
            }
            disabled={checkIfDisabled("from-date")}
          />
          {/* To Date */}
          <DefaultInput
            id={"to-date"}
            label={"To Date"}
            title={"Filter all data before this date"}
            type={"date"}
            value={moment(filters.toDate).format("YYYY-MM-DD")}
            error={
              moment(filters.fromDate) >= moment(filters.toDate) ||
              moment(filters.toDate) >= moment()
            }
            startSearch={search}
            setValue={(value: string) =>
              value !== "" && updateValue("toDate", value)
            }
            disabled={checkIfDisabled("to-date")}
          />
        </Box>

        <Box>
          {/* Event Date Min */}
          <DefaultInput
            id={"event-date-min"}
            label={"Event Date Min"}
            title={"Minimum event date"}
            type={"date"}
            value={moment(filters.eventDateMin).format("YYYY-MM-DD")}
            error={moment(filters.eventDateMin) < moment().subtract(1, "day")}
            startSearch={search}
            setValue={(value: string) =>
              value !== "" && updateValue("eventDateMin", value)
            }
            disabled={checkIfDisabled("event-date-min")}
          />

          <Box
            sx={{display: "flex", justifyContent: "space-around", pt: 0.9}}
          >
            {/* In drop check */}
            <Button
              sx={{minWidth: 0, p: 1, border: 1}}
              disabled={checkIfDisabled("only-in-dropcheck")}
              id="only-in-dropcheck"
              color="secondary"
              variant={filters.onlyInDropCheck ? "contained" : "outlined"}
              title="Only show events in the dropchecker"
              onClick={() => {
                updateValue("onlyInDropCheck", !filters.onlyInDropCheck);
                setActiveInputs(
                  !filters.onlyInDropCheck ? [] : ["only-in-dropcheck"]
                );
              }}
            >
              <FilterDropCheckIcon
                sx={{
                  animation: filters.onlyInDropCheck
                    ? `${zoomingAnimation} 0.5s ease-in-out forwards`
                    : "none",
                }}
              />
            </Button>
            {/* Have a threshold */}
            <Button
              sx={{minWidth: 0, p: 1, border: 1}}
              disabled={checkIfDisabled("only-with-threshold")}
              id="only-with-threshold"
              color="secondary"
              variant={filters.onlyWithThreshold ? "contained" : "outlined"}
              title="Only show events with a threshold"
              onClick={() => {
                updateValue("onlyWithThreshold", !filters.onlyWithThreshold);
                setActiveInputs(
                  !filters.onlyWithThreshold ? [] : ["only-with-threshold"]
                );
              }}
            >
              <FilterAlertIcon
                sx={{
                  animation: filters.onlyWithThreshold
                    ? `${ringingAnimation} 0.5s ease-in-out forwards`
                    : "none",
                }}
              />
            </Button>
            {/* Is parking */}
            <Button
              sx={{minWidth: 0, p: 1, border: 1}}
              disabled={checkIfDisabled("only-with-parking")}
              id="only-with-parking"
              color="secondary"
              variant={filters.isParking ? "contained" : "outlined"}
              title="Only show events that are parking"
              onClick={() => {
                updateValue("isParking", !filters.isParking);
              }}
            >
              <FilterParkingIcon
                sx={{
                  animation: filters.isParking
                    ? `${bouncingAnimation} 0.5s ease-in-out forwards`
                    : "none",
                }}
              />
            </Button>
          </Box>
        </Box>

        <Box>
          {/* Artist */}
          <DefaultInput
            id={"artist"}
            label={"Artist"}
            title={"The artist or band name"}
            type={"text"}
            value={filters.artist}
            error={filters.artist.length > 0 && filters.artist.length < 3}
            startSearch={search}
            setValue={(value) => {
              updateValue("artist", value);
              if (value !== "" || filters.venue !== "")
                setActiveInputs(["artist", "venue", "only-with-parking", "min-ds-invoices", "max-ds-invoices"]);
              else if (filters.artist === "" && filters.venue === "")
                setActiveInputs([]);
            }}
            disabled={checkIfDisabled("artist")}
          />
          {/* Venue */}
          <DefaultInput
            id={"venue"}
            label={"Venue"}
            title={"The venue name"}
            type={"text"}
            value={filters.venue}
            error={filters.venue.length > 0 && filters.venue.length < 3}
            startSearch={search}
            setValue={(value) => {
              updateValue("venue", value);
              if (value !== "" || filters.artist !== "")
                setActiveInputs(["artist", "venue", "only-with-parking", "min-ds-invoices", "max-ds-invoices"]);
              else if (filters.artist === "" && filters.venue === "")
                setActiveInputs([]);
            }}
            disabled={checkIfDisabled("venue")}
          />
        </Box>


        <Box>
          {/* Category */}
          <DefaultInput
            id={"category"}
            label={"Category"}
            title={"The category of the event"}
            type={"text"}
            value={filters.category}
            error={filters.category.length > 0 && filters.category.length < 3}
            startSearch={search}
            setValue={(value) => updateValue("category", value)}
            disabled={checkIfDisabled("category")}
          />

          <DefaultInput
            id={"country"}
            label={"Country"}
            title={"The country of the event"}
            type={"text"}
            value={filters.country}
            startSearch={search}
            setValue={(value) => updateValue("country", value)}
            disabled={checkIfDisabled("country")}
          />


        </Box>

        <Box>
          <Box
            sx={{display: "flex", justifyContent: "space-around", pt: 0.9}}
          >
            <Button
              sx={{minWidth: 0, p: 1, border: 1}}
              disabled={checkIfDisabled("only-cw")}
              id="only-cw"
              color="info"
              variant={filters.isCW ? "contained" : "outlined"}
              title="Only show events that are in the concert week"
              onClick={() => {
                updateValue("isCW", !filters.isCW);
              }}
            >
              CW
            </Button>
          </Box>
        </Box>


        <Box>
          {/* State */}
          <Autocomplete
            id="state"
            disabled={checkIfDisabled("state")}
            autoSelect
            autoHighlight
            size={"small"}
            options={states.map((state) => state.code)}
            sx={{mx: 1, my: 2, width: "100px"}}
            renderInput={(params) => <TextField {...params} label="State"/>}
            filterOptions={(options, {inputValue}) => {
              return options.filter((option) => {
                const stateName = states
                  .find((state) => state.code === option)
                  ?.name.toLowerCase();
                const stateCode = option.toLowerCase();
                const input = inputValue.toLowerCase();
                return stateName?.includes(input) || stateCode?.includes(input);
              });
            }}
            renderOption={(props, option) => [
              <li {...props}>
                {option}-
                <small>
                  {states.find((state) => state.code === option)?.name}
                </small>
              </li>,
            ]}
            value={filters.state}
            onChange={(event, newValue) => {
              if (newValue !== null) updateValue("state", newValue);
              else updateValue("state", "");
            }}
          />
          {/* Min Data Points */}
          <DefaultInput
            id={"min-data-points"}
            label={"Data Points"}
            title={"Minimum number of data points"}
            type={"number"}
            value={filters.minDataPoints}
            error={filters.minDataPoints < 0}
            startSearch={search}
            setValue={(value: string) => updateValue("minDataPoints", value)}
            disabled={checkIfDisabled("min-data-points")}
          />
        </Box>

        {/* Inputs that are applied on seller stats [tm, rs] */}
        <Card sx={{display: "flex"}}>
          <Box>
            <Box sx={{display: "flex", flexDirection: "row"}}>
              {/* Seller */}
              <FormControl
                sx={{
                  mx: 1,
                  mt: 2,
                  width: "100px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {borderColor: "secondary.main"},
                    "&:hover fieldset": {borderColor: "secondary.main"},
                    "&.Mui-focused fieldset": {borderColor: "secondary.main"},
                  },
                }}
                size="small"
                title="Apply filters on stats of..."
                disabled={checkIfDisabled("search-type")}
              >
                <InputLabel id="search-type-label">Filter on</InputLabel>
                <Select
                  id="search-type"
                  fullWidth
                  labelId="search-type-label"
                  value={filters.statsFor}
                  label="Filter on"
                  onChange={(event: SelectChangeEvent) => {
                    updateValue("statsFor", event.target.value);
                  }}
                >
                  <MenuItem value={"tm"}>TM</MenuItem>
                  <MenuItem value={"rs"}>Resale</MenuItem>
                </Select>
              </FormControl>

              {/* Show Max input */}
              <Button
                sx={{minWidth: 0, p: 1, border: 1, marginTop: 2, bottom: 1}}
                disabled={checkIfDisabled("show-max-input")}
                id="show-max-input"
                color="primary"
                variant={showMaxInputs ? "contained" : "outlined"}
                title="Show max inputs"
                onClick={() => {
                  setShowMaxInputs(!showMaxInputs);
                }}
              >
                <Typography
                  fontSize={10}
                  color="text.secondary"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  MAX
                </Typography>
                <ShowIcon/>
              </Button>
            </Box>


            <Box sx={{display: "flex", flexDirection: "row"}}>

              {/* Min DS Sales */}
              <DefaultInput
                id={"min-ds-invoices"}
                label={"DS Invoice"}
                title={"Minimum number of DS invoices"}
                type={"number"}
                value={filters.minDsInvoices}
                error={filters.minDsInvoices < 0}
                startSearch={search}
                setValue={(value: string) => updateValue("minDsInvoices", value)}
                disabled={checkIfDisabled("min-ds-invoices")}
              />

              <DefaultInput
                id={"min-ds-date"}
                label={"DS Date"}
                title={"Minimum DS date"}
                type={"date"}
                value={moment(filters.minDsDate).format("YYYY-MM-DD")}
                error={moment(filters.minDsDate) > moment()}
                startSearch={search}
                setValue={(value: string) => updateValue("minDsDate", value)}
                disabled={checkIfDisabled("min-ds-date") || !filters.minDsInvoices}
              />
            </Box>
          </Box>

          <Box sx={{display: showMaxInputs ? "flex" : "block"}}>
            <Box>
              {/* Min decrease */}
              <DefaultInput
                id={"min-decrease"}
                label={showMaxInputs ? "Min Dec. *" : "Decrease *"}
                title={
                  "Minimum tickets quantity decrease between the FROM and TO dates"
                }
                type={"number"}
                value={filters.minDecrease}
                error={
                  filters.minDecrease !== "" &&
                  filters.maxDecrease !== "" &&
                  filters.minDecrease > filters.maxDecrease
                }
                startSearch={search}
                setValue={(value: string) => updateValue("minDecrease", value)}
                disabled={checkIfDisabled("min-decrease")}
              />
              {/* Max decrease */}
              <DefaultInput
                id={"max-decrease"}
                label={"Max Dec. *"}
                title={
                  "Maximum tickets quantity decrease between the FROM and TO dates"
                }
                type={"number"}
                value={filters.maxDecrease}
                error={
                  filters.minDecrease !== "" &&
                  filters.maxDecrease !== "" &&
                  filters.minDecrease > filters.maxDecrease
                }
                startSearch={search}
                setValue={(value: string) => updateValue("maxDecrease", value)}
                disabled={checkIfDisabled("max-decrease")}
                hide={!showMaxInputs}
              />
            </Box>

            <Box>
              {/* Min Avg Decrease */}
              <DefaultInput
                id={"min-avg-decrease"}
                label={`${showMaxInputs ? "Min " : ""}Dec. Avg`}
                title={
                  "Minimum tickets quantity decrease average from the last 7 days"
                }
                type={"number"}
                value={filters.minAvgDecrease}
                error={
                  filters.minAvgDecrease !== "" &&
                  filters.maxAvgDecrease !== "" &&
                  filters.minAvgDecrease > filters.maxAvgDecrease
                }
                startSearch={search}
                setValue={(value: string) =>
                  updateValue("minAvgDecrease", value)
                }
                disabled={checkIfDisabled("min-avg-decrease")}
              />
              {/* Max Avg Decrease */}
              <DefaultInput
                id={"max-avg-decrease"}
                label={"Max Decrease Avg"}
                title={
                  "Maximum tickets quantity decrease average from the last 7 days"
                }
                type={"number"}
                value={filters.maxAvgDecrease}
                error={
                  filters.minAvgDecrease !== "" &&
                  filters.maxAvgDecrease !== "" &&
                  filters.minAvgDecrease > filters.maxAvgDecrease
                }
                startSearch={search}
                setValue={(value: string) =>
                  updateValue("maxAvgDecrease", value)
                }
                disabled={checkIfDisabled("max-avg-decrease")}
                hide={!showMaxInputs}
              />
            </Box>
          </Box>

          <Box sx={{display: showMaxInputs ? "flex" : "block"}}>
            <Box>
              {/* Min/Max Remaining */}
              <MinMaxRemainingInputs
                minRemaining={filters.minRemaining}
                maxRemaining={filters.maxRemaining}
                setMinRemaining={(value: number | string) =>
                  updateValue("minRemaining", value)
                }
                setMaxRemaining={(value: number | string) =>
                  updateValue("maxRemaining", value)
                }
                minMaxRemainIsNb={filters.minMaxRemainIsNb}
                setMinMaxIsNb={() =>
                  updateValue("minMaxRemainIsNb", !filters.minMaxRemainIsNb)
                }
                checkIfDisabled={checkIfDisabled}
                startSearch={search}
                hideMax={!showMaxInputs}
              />
            </Box>

            <Box>
              {/* Min MIR */}
              <DefaultInput
                id={"min-mir"}
                label={`${showMaxInputs ? "Min " : ""}MIR`}
                title={"Minimum Make It Ratio value"}
                type={"number"}
                value={filters.minMir}
                error={
                  filters.minMir !== "" &&
                  filters.maxMir !== "" &&
                  filters.minMir > filters.maxMir
                }
                startSearch={search}
                setValue={(value: string) => updateValue("minMir", value)}
                disabled={checkIfDisabled("min-mir")}
              />
              {/* Max MIR */}
              <DefaultInput
                id={"max-mir"}
                label={"Max MIR"}
                title={"Maximum Make It Ratio value"}
                type={"number"}
                value={filters.maxMir}
                error={
                  filters.minMir !== "" &&
                  filters.maxMir !== "" &&
                  filters.minMir > filters.maxMir
                }
                startSearch={search}
                setValue={(value: string) => updateValue("maxMir", value)}
                disabled={checkIfDisabled("max-mir")}
                hide={!showMaxInputs}
              />
            </Box>
          </Box>

          <Collapse in={filters.statsFor === "tm"} orientation={"horizontal"}>
            <Box>
              {/* Min MQR {Only for TM stats} */}
              <DefaultInput
                id={"min-mqr"}
                label={`${showMaxInputs ? "Min " : ""}MQR`}
                title={"Minimum Max Quantity Ratio value"}
                type={"number"}
                value={filters.minMqr}
                error={
                  (filters.minMqr !== "" &&
                    filters.maxMqr !== "" &&
                    filters.minMqr > filters.maxMqr) ||
                  filters.minMqr < 0 ||
                  filters.minMqr > 1
                }
                startSearch={search}
                setValue={(value: string) => updateValue("minMqr", value)}
                disabled={checkIfDisabled("min-mqr")}
              />
              {/* Max MQR {Only for TM stats} */}
              <DefaultInput
                id={"max-mqr"}
                label={"Max MQR"}
                title={"Maximum Max Quantity Ratio value"}
                type={"number"}
                value={filters.maxMqr}
                error={
                  (filters.minMqr !== "" &&
                    filters.maxMqr !== "" &&
                    filters.minMqr > filters.maxMqr) ||
                  filters.maxMqr > 1 ||
                  filters.maxMqr < 0
                }
                startSearch={search}
                setValue={(value: string) => updateValue("maxMqr", value)}
                disabled={checkIfDisabled("max-mqr")}
                hide={!showMaxInputs}
              />
            </Box>
          </Collapse>
        </Card>

        <Card sx={{display: "flex", ml: 5, position: "relative", overflow: "visible"}}>
          <Typography variant="h6" sx={{position: "absolute", left: -50, top: 30, transform: "rotate(-90deg)"}}>
            SG Sells
          </Typography>

          <Box sx={{display: "flex"}}>
            <Box>
              {/* SG Sells Total */}
              <DefaultInput
                id={"min-sg-sells-total"}
                label={"Total"}
                title={"Minimum tickets quantity sold from the last 7 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_total}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_total", value)}
                disabled={checkIfDisabled("min-sg-sells-total")}
              />


              {/* Sg Sells 1 day */}
              <DefaultInput
                id={"min-sg-sells-1"}
                label={"1 day"}
                title={"Minimum tickets quantity sold from the last 1 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_1}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_1", value)}
                disabled={checkIfDisabled("min-sg-sells-1")}
              />
            </Box>

            <Box>
              {/* Sg Sells 7 days */}
              <DefaultInput
                id={"min-sg-sells-7"}
                label={"7 days"}
                title={"Minimum tickets quantity sold from the last 7 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_7}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_7", value)}
                disabled={checkIfDisabled("min-sg-sells-7")}
              />

              {/* Sg Sells 30 days */}
              <DefaultInput
                id={"min-sg-sells-30"}
                label={"30 days"}
                title={"Minimum tickets quantity sold from the last 30 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_30}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_30", value)}
                disabled={checkIfDisabled("min-sg-sells-30")}
              />
            </Box>
          </Box>

        </Card>


        <Box>
          <FiltersPreset
            id={"TmUp"}
            filters={filters}
            updateValue={updateValue}
          />
        </Box>
      </Box>
    </Box>
  );
}
