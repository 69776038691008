// ----- Module -----
import React from "react";

// ----- MUI -----
import { Box, Card, Divider, IconButton, Typography } from "@mui/material";
import axios from "axios";
import Matching from "../components/modals/Matching";
import NavMenu from "../components/others/NavMenu";
import InfoIcon from '@mui/icons-material/Info';


/**
 * Login page
 * - The login page is a simple button that opens a popup to login with Google
 * - The popup is handled by the useGoogleLogin hook
 */
const McDavidMatchTool = () => {
  document.title = "McDavid - Match";


  const [events, setEvents] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);


  React.useEffect(() => {
    axios.get('/api/mcDavid/not-poed').then((resp) => {
      const data = resp.data;

      const po_data = data.map((obj: any) => JSON.parse(obj.po_data)).filter((po_data: any) => po_data !== null);
      const events = po_data.map((po_data: any) => po_data.event_id).filter((value: any, index: any, self: any) => self.indexOf(value) === index);

      setLoading(false);

      setEvents(events);
    });

  }, []);
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        gap: "10px",
      }}
    >

      <NavMenu current={"mcDavidMatch"}/>

      {loading ? <Typography>Loading...</Typography>
        : !loading && events.length === 0 ?
          <Typography>No events found</Typography>
          : (
            <Card sx={{px: 2, py: 3, overflow: "auto", width: "100%", maxWidth: "350px"}}>
              {events.map((event: any) => (
                <>
                  <Box key={event} sx={{display: "flex"}}>
                    <IconButton color={"secondary"}
                                onClick={() => window.open(`/event/${event}`, "_blank")}>
                      <InfoIcon fontSize={"small"}/>
                    </IconButton>
                    <Typography sx={{display: "flex", alignItems: "center"}}>
                      {event}
                    </Typography>
                    <Box sx={{ml: 'auto'}}>
                      <Matching eventId={event}/>
                    </Box>
                  </Box>
                  <Divider/>
                </>
              ))}
            </Card>
          )
      }

    </Box>
  );
};

export default McDavidMatchTool;
