// ----- React -----
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// ----- MUI -----
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// ----- Pages -----
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Search from "./pages/Search";
import Tm from "./pages/Details/Tm";
import Sg from "./pages/Details/Sg";
import Mpv from "./pages/Details/Mpv";
import Invoice from "./pages/Invoice";
import GlobalAlertsPage from "./pages/GlobalAlertsPage";
import ArtistDropcheck from "./pages/ArtistDropcheck";
import Rockettes from "./pages/Details/Rockettes";

// ----- Styles -----
import DefaultTheme from "./Theme";

// ----- Types ----- //
import { SEARCH_TYPES } from "./Types/Other";
import LogRocket from "logrocket";
import McDavidMatchTool from "./pages/McDavidMatchTool";
import TmpUsOpen from "./pages/UsOpen/TmpUsOpen";
import UsOpenGraph from "./pages/UsOpen/UsOpenGraph";

const PWD = "tickets09";

/**
 * Main App component
 * @constructor App
 */
function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null
  );

  React.useEffect(() => {
    const pwd = localStorage.getItem("pwd");
    const email = localStorage.getItem("email");
    if (pwd === PWD) setIsAuthenticated(true);
    else {
      setIsAuthenticated(false);
      localStorage.removeItem("pwd");
      localStorage.removeItem("email");
    }

    if (email && pwd === PWD) setupLogRocket({id: email, name: email, email});
  }, []);

  const setupLogRocket = (user: {
    id: string;
    name: string;
    email: string;
  }) => {
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,
    });
  };

  return (
    <ThemeProvider theme={DefaultTheme()}>
      <CssBaseline/>
      <Router>
        {isAuthenticated ? (
          <Routes>
            <Route
              path="/*"
              element={<Search seller={SEARCH_TYPES.TM_ACTIVE}/>}
            />
            <Route
              path="/tm/active"
              element={<Search seller={SEARCH_TYPES.TM_ACTIVE}/>}
            />
            <Route
              path="/tm/past"
              element={<Search seller={SEARCH_TYPES.TM_PAST}/>}
            />
            <Route
              path="/via"
              element={<Search seller={SEARCH_TYPES.VIA_ACTIVE}/>}
            />
            <Route
              path="/sg"
              element={<Search seller={SEARCH_TYPES.SG_ACTIVE}/>}
            />
            <Route
              path="/sg-via"
              element={<Search seller={SEARCH_TYPES.SG_VIA_ACTIVE}/>}
            />

            <Route
              path="/mpv"
              element={<Search seller={SEARCH_TYPES.MPV}/>}
            />

            <Route path="/event/:eventId" element={<Tm/>}/>
            <Route path="/mpv/:eventId" element={<Mpv/>}/>
            <Route path="/seatgeek/:eventId" element={<Sg/>}/>
            <Route path="/logout" element={<Logout/>}/>

            <Route path="/invoice" element={<Invoice/>}/>
            <Route path="/globalAlerts" element={<GlobalAlertsPage/>}/>
            <Route path="/artistDropcheck" element={<ArtistDropcheck/>}/>
            <Route path="/mcDavidMatch" element={<McDavidMatchTool/>}/>
            <Route path="/usOpen" element={<TmpUsOpen/>}/>
            <Route path="/usOpen/graph/:id2024/:id2023" element={<UsOpenGraph/>}/>

            <Route
              path="/rockettest/:pastId/:currentId"
              element={<Rockettes/>}
            />
          </Routes>
        ) : (
          isAuthenticated === false && (
            <Routes>
              <Route path="/*" element={<Login/>}/>
            </Routes>
          )
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
