// ----- Module -----
import React from "react";

// ----- MUI -----
import { Box, Button, Card, Divider, TextField, Typography, } from "@mui/material";


const PWD = 'tickets09';

/**
 * Login page
 * - The login page is a simple button that opens a popup to login with Google
 * - The popup is handled by the useGoogleLogin hook
 */
const Login = () => {
  document.title = "Login";

  const [error, setError] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const basicErrorMsg = `Invalid email or password. Please try again.`;

  const handleLogin = () => {
    setIsLoading(true);
    setError("");
    setTimeout(() => {
      if (email && password === PWD) {
        localStorage.setItem('pwd', PWD);
        localStorage.setItem('email', email);
        window.location.href = "/";
      } else {
        setIsLoading(false);
        setError(basicErrorMsg);
        setPassword("");
      }
    }, 1000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <Card
        sx={{
          px: 3,
          py: 2,
          width: 450,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{mb: 2}}>
          Bowman Project
        </Typography>

        <Divider sx={{width: "90%", mb: 2}}/>

        <TextField
          size={"small"}
          label="Email"
          variant="outlined"
          type={"email"}
          sx={{mb: 2}}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") handleLogin();
          }}
        />

        <TextField
          size={"small"}
          label="Password"
          variant="outlined"
          type={"password"}
          sx={{mb: 2}}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") handleLogin();
          }}
        />

        <Box sx={{display: "flex", justifyContent: "end", width: "100%"}}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleLogin}
            disabled={isLoading || !email || !password}
          >
            {isLoading ? "Logging in..." : "Login"}
          </Button>
        </Box>

      </Card>
      <Box color={"red"} sx={{top: 2, height: 10, textAlign: "center"}}>
        <p>{error}</p>
      </Box>
    </Box>
  );
};

export default Login;
