import { Box, Button, Card, Divider, Modal, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InvoiceIcon from "@mui/icons-material/Receipt";
import SaleIcon from "@mui/icons-material/Receipt";
import TicketIcon from "@mui/icons-material/ConfirmationNumber";
import QtyIcon from "@mui/icons-material/ConfirmationNumber";
import React from "react";
import { modalStyle } from "../../../styles";
import { DsSale } from "../../../Types/Other";

import SeatIcon from '@mui/icons-material/EventSeat';
import DateIcon from '@mui/icons-material/CalendarMonth';
import MoneyIcon from '@mui/icons-material/AttachMoney';

import moment from "moment";

const DsSales = (props: { eventDetail: any, dsSales: DsSale[] }) => {
  const {eventDetail, dsSales} = props;

  const [opened, setOpened] = React.useState<boolean>(false);

  const handleOpen = () => { setOpened(true); };
  const handleClose = () => { setOpened(false); };


  return (
    <Card sx={{ml: 3, py: 1, minWidth: "75px"}}>
      <Box
        sx={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "center",
          px: 1,
        }}
      >
        {eventDetail.in_ds ? (
          <CheckIcon sx={{color: "green"}}/>
        ) : (
          <ClearIcon sx={{color: "red"}}/>
        )}
        DS
      </Box>

      <Divider sx={{my: 1}}/>

      <Box
        sx={{px: 1, display: "flex", justifyContent: "space-between"}}
      >
        <Box
          title={"Invoices"}
          color={"text.secondary"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingRight: 1,
          }}
        >
          <InvoiceIcon/>
          <Typography color={"text.primary"}>{dsSales.length}</Typography>
        </Box>

        <Box
          title={"Quantity"}
          color={"text.secondary"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TicketIcon/>
          <Typography color={"text.primary"}>
            {dsSales.reduce((a, b) => a + b.quantity, 0)}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{my: 1}}/>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 1,
        }}
      >
        <Button
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          onClick={handleOpen}
        >
          Info
        </Button>

        <Modal
          open={opened}
          onClose={handleClose}
          aria-labelledby="sales-modal-title"
          aria-describedby="sales-modal-description"
        >
          <Box sx={modalStyle}>
            <Box sx={{display: "flex", alignItems: "center", marginY: 3}}>
              <SaleIcon sx={{fontSize: 40, mr: 1}}/>
              <h1 style={{margin: 0}}>Sales Information</h1>
            </Box>

            <Divider sx={{my: 1}}/>

            {dsSales.map((sale) => (
              <>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <Box sx={{display: "flex", gap: 5}}>
                    <Typography>
                      <QtyIcon
                        sx={{fontSize: "inherit", mr: 1, position: "relative", top: "2px", color: "text.secondary"}}/>
                      {sale.quantity}
                    </Typography>
                    <Typography>
                      <SeatIcon
                        sx={{fontSize: "inherit", mr: 1, position: "relative", top: "2px", color: "text.secondary"}}/>
                      {sale.section} - {sale.row}
                    </Typography>
                  </Box>
                  <Box sx={{display: "flex"}}>
                    <Typography>
                      <MoneyIcon
                        sx={{fontSize: "inherit", position: "relative", top: "2px", color: "text.secondary"}}/>
                      {Math.round((sale.sale_amount / sale.quantity) * 100) / 100}
                    </Typography>
                    <Typography sx={{color: "text.secondary", fontSize: "10px", mt: 'auto', ml: 0.5}}>
                      Per Ticket
                    </Typography>
                  </Box>

                  <Box sx={{display: "flex"}}>


                    <Typography>
                      <DateIcon
                        sx={{fontSize: "inherit", mr: 1, position: "relative", top: "2px", color: "text.secondary"}}/>
                      {moment(sale.created_at).format("MM/DD/YYYY")}
                    </Typography>

                    <Typography sx={{color: "text.secondary", fontSize: "12px", mt: 'auto', ml: 0.5}}>
                      {moment(sale.created_at).format("h:mm A")}
                    </Typography>


                  </Box>

                </Box>
                <Box sx={{display: "flex", justifyContent: "center"}}>
                  <Divider sx={{my: 1, width: "95%"}}/>
                </Box>
              </>
            ))}

            <Box sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}>
              <Button sx={{mr: 2}} onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Card>
  );
};

export default DsSales;
