// ----- Packages ----- //
import axios from "axios";
import moment from "moment";
import { Line } from "react-chartjs-2";
import React, { useCallback, useEffect, useState } from "react";

// ----- MUI ----- //
import { Box, Card, Divider, Skeleton, Typography, } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

// ----- Types ----- //
import { ViaSale } from "../../../../Types/Via";

// ----- Components ----- //
import ErrorCard from "../../../others/cards/ErrorCard";

// ----- Others -----
const VIAGOGO_API_URL = "/api/viagogo";

/**
 * Graph that displays the sales of a Viagogo event
 * @param props {viagogoId: string} - The Viagogo ID of the event
 */
const ViagogoSalesGraph = (props: { viagogoId: string }) => {
  // ----- State -----
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string>("");

  // ----- Data -----
  const [sales, setSales] = useState<ViaSale[]>([]);
  const [listings, setListings] = useState<any[]>([]);

  const salesUrl = `${VIAGOGO_API_URL}/sales/${props.viagogoId}`;
  const listingsUrl = `${VIAGOGO_API_URL}/listings/${props.viagogoId}`;


  /**
   * Fetches the sales and listings data from the API
   */
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    axios
      .get(salesUrl)
      .then((res) => {
        res.data.sort((a: any, b: any) => {
          return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
        });
        setSales(res.data);
      })
      .catch((err) => {
        setErrorMsg(err + "");
      });

    axios
      .get(listingsUrl)
      .then((res) => {
        const data = res.data;

        // const groupedData: any = {};
        // data.forEach((d: ViaListing) => {
        //   if (!groupedData[d.created_at]) {
        //     groupedData[d.created_at] = {
        //       total_listings: 0,
        //       average_price: 0,
        //       minimum_price: Number.MAX_VALUE,
        //     };
        //   }
        //   groupedData[d.created_at].total_listings++;
        //   groupedData[d.created_at].average_price += d.price;
        //   groupedData[d.created_at].minimum_price = Math.min(d.price, groupedData[d.created_at].minimum_price);
        // });
        //
        // // convert to array
        // const listingsData = Object.keys(groupedData).map((key) => {
        //   const d = groupedData[key];
        //   return {
        //     created_at: key,
        //     total_listings: d.total_listings,
        //     average_price: d.average_price / d.total_listings,
        //     minimum_price: d.minimum_price,
        //   };
        // });
        //
        // // sort by date
        // listingsData.sort((a, b) => {
        //   return moment(a.created_at).isBefore(moment(b.created_at)) ? -1 : 1;
        // });

        setListings([]);


        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMsg(err + "");
      });
  }, [props.viagogoId]);

  // ----- Effects -----
  useEffect(() => {
    setErrorMsg("");
    if (!props.viagogoId) return;

    fetchData();
  }, [props.viagogoId, fetchData]);

  // Columns for the sales table
  const salesColumns = [
    {field: "quantity", headerName: "Qty", width: 50},
    {
      field: "unit_price",
      headerName: "Price",
      type: "number",
      flex: 1,
      valueFormatter: (params: any) => {
        return `$${params.value}`;
      }
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 2,
      type: "dateTime",
      valueFormatter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY - hh:mm A");
      },
    },
    {field: "section_name", headerName: "Section", width: 100, flex: 1},
    {field: "row_name", headerName: "Row", width: 50, flex: 1},
  ];

  // Columns for the listings graph
  const listingsGraphData = {
    // @ts-ignore
    labels: listings.map((d) => moment(d.created_at)),
    datasets: [
      {
        fill: false,
        backgroundColor: "rgba(42,141,255,0.51)",
        borderColor: "rgb(42,141,255)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgb(42,141,255)",
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "Total listings",
        // @ts-ignore
        data: listings.map((d) => d.total_listings),
        yAxisID: "y-total-listings",
      },
      {
        fill: false,
        backgroundColor: "rgba(255,148,29,0.52)",
        borderColor: "rgb(255,148,29)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgb(255,148,29)",
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "Avg price",
        // @ts-ignore
        data: listings.map((d) => d.average_price),
        yAxisID: "y-price",
      },
      {
        fill: false,
        backgroundColor: "rgba(81,206,102,0.55)",
        borderColor: "rgb(81,206,102)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgb(81,206,102)",
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "Min price",
        // @ts-ignore
        data: listings.map((d) => d.minimum_price),
        yAxisID: "y-price",
      },
    ],
  };

  // Options for the listings graph
  let options = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          distribution: "linear",
          time: {
            tooltipFormat: "MMMM DD h:mm a",
            unit: "day",
          },
          scaleLabel: {
            labelString: "Day",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          id: "y-total-listings",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },

          scaleLabel: {
            display: true,
            labelString: "Total listings",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
        {
          position: "right",
          id: "y-price",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Prices (Avg/Min)",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
      ],
    },
  };

  // ----- Render ----- //
  return (
    <Box sx={{mt: 1, px: 5}}>
      {errorMsg ? (
        <ErrorCard
          show={errorMsg !== ""}
          details={{
            component: "event/graphs/ViagogoSales.tsx",
            error: errorMsg,
          }}
        />
      ) : (
        <Box>
          <Box sx={{display: "flex", justifyContent: "space-around"}}>
            <Box sx={{width: "25%", pt: 1, pr: 1}}>
              <Card sx={{width: "fit-content", mb: 1, py: 1, pl: 2, pr: 5}}>
                <Typography variant="h5">Sales</Typography>
              </Card>

              {isLoading ? (
                <Skeleton variant="rounded" width={590} height={900}/>
              ) : (
                <DataGridPro
                  getRowId={(row) => row.listing_id}
                  density={"compact"}
                  sx={{height: 900}}
                  rows={sales}
                  columns={salesColumns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0
                      ? "row-even"
                      : "row-odd"
                  }
                />
              )}
            </Box>

            <Divider orientation={"vertical"} flexItem sx={{ml: 3, my: 2}}/>

            <Box sx={{width: "75%", p: 1}}>
              <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Card sx={{width: "fit-content", mb: 1, py: 1, pl: 2, pr: 5}}>
                  <Typography variant="h5">Listings - Not implemented yet!</Typography>
                </Card>
              </Box>

              <Box sx={{position: "relative", top: -15}}>
                <Line
                  type={"line"}
                  data={listingsGraphData}
                  options={options}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ViagogoSalesGraph;
