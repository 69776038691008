// ----- Packages ----- //
import axios from "axios";
import React from "react";

// ----- MUI ----- //
import { Button } from "@mui/material";
import RemoveFromDCIcon from "@mui/icons-material/SearchOff";
import AddToDCIcon from "@mui/icons-material/ZoomIn";
import ErrorIcon from "@mui/icons-material/ErrorOutline";

/**
 * Button that adds or removes an artist from the drop check list
 * @param props<{row: any, callBack?: () => void}> - The row of the artist and a callback function
 */
const ArtistDropCheckBtn = (props: { row: any; callBack?: () => void }) => {
  const { row, callBack } = props;

  const [error, setError] = React.useState("");

  /**
   * Adds an artist to the drop check list
   * @param performer - The artist to add
   */
  async function addToDC(performer: any) {
    await axios
      .post(`https://count.plessinc.com/artists/dropcheck`, {
        id: performer.id,
        name: performer.name,
      })
      .then(() => {
        performer.watched = true;
        setError("");
        if (callBack) callBack();
      })
      .catch((err) => {
        console.log(err);
        setError(err.toString());
      });
  }

  /**
   * Removes an artist from the drop check list
   * @param performer - The artist to remove
   */
  async function removeFromDC(performer: any) {
    await axios
      .delete(`https://count.plessinc.com/artists/${performer.id}`)
      .then(() => {
        performer.watched = false;
        setError("");
        if (callBack) callBack();
      })
      .catch((err) => {
        console.log(err);
        setError(err.toString());
      });
  }

  // Return the button, either an error icon (if there is an error) or the add/remove button
  return error ? (
    <ErrorIcon sx={{ color: "red" }} />
  ) : (
    <Button
      size="small"
      sx={{
        minWidth: 10,
        padding: "2px",
        "&:hover": { color: row.watched ? "red" : "" },
      }}
      color={row.watched ? "success" : "primary"}
      onClick={async () =>
        row.watched ? await removeFromDC(row) : await addToDC(row)
      }
    >
      {row.watched ? <RemoveFromDCIcon /> : <AddToDCIcon />}
    </Button>
  );
};

export default ArtistDropCheckBtn;
