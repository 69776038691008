// ----- Packages ----- //
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import axios from "axios";

// ----- MUI ----- //
import { Box } from "@mui/material";

// ----- Components -----
import MpvUpDataGrid from "./MpvUpDataGrid";
import MpvUpFilters from "./MpvUpFilters";
import Template from "../Template";

// ----- Types -----
import { MpvEvent } from "../../../Types/Mpv";
import { useSearchParams } from "react-router-dom";
import { MpvUpFiltersData } from "../../../Types/Mpv";

/**
 * The upcoming ticketmaster events page, which displays the filters and data grid
 * @returns The upcoming ticketmaster events page
 */
function TmUpPage() {
  // ----- Events -----
  const [activeEvents, setActiveEvents] = useState<MpvEvent[]>([]);

  // ----- Events Filters Data -----
  const [filters, setFilters] = useState<MpvUpFiltersData>(
    {} as MpvUpFiltersData
  );

  // ----- States -----
  const [statsFor, setStatsFor] = useState<string>("tm");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const [autoSearch, setAutoSearch] = useState<boolean>(false);

  // ----- Styles -----
  const selectedHeaderStyle = {
    backgroundColor: "rgba(125, 125, 125, 0.07)",
    fontSize: "1.2rem",
    fontWeight: "bold",
  };


  // ----- URLs -----
  const upcomingEventsUrl = "/api/events/mpv";

  /**
   * Function that formats the events data
   * @param events<EventDetails[]> - The events to be formatted
   */
  const processEvents = useCallback(async (events: MpvEvent[]) => {

    // Format the events
    events.forEach((event: MpvEvent) => {
      console.log(event.id, event.event_date, event.timezone)
      if (event.timezone) {
        const parsedDateTime = moment(event.event_date).tz(event.timezone);
        event.event_date = parsedDateTime.format(`${dateFormat}`);
        event.event_time = parsedDateTime.format(`${timeFormat}`);
        event.days_left = parsedDateTime.diff(moment(), "days");
      } else {
        event.event_date = moment(event.event_date).format(`${dateFormat}`);
        event.event_time = moment(event.event_date).format(`${timeFormat}`);
        event.days_left = moment(event.event_date).diff(moment(), "days");
      }

    });

    setActiveEvents(events);
    setFirstLoad(false);
    setIsLoading(false);
  }, []);

  /**
   * Function that fetches the events from the server
   *  Format the data and set the state
   */
  const getEvents = useCallback(() => {
    try {
      let data = filters;
      let url = upcomingEventsUrl;

      setStatsFor(filters.statsFor);
      if (Object.keys(data).length > 0) {
        url += "?";
        for (let key in data) {
          // @ts-ignore
          if (data[key] === "" || data[key] === null || data[key] === undefined)
            continue;
          // @ts-ignore
          url += `${key}=${data[key]}&`;
        }
        url = url.slice(0, -1);
      } else {
        setIsLoading(false);
        return;
      }

      console.log(url);

      setIsLoading(true);

      axios
        .get(url)
        .then((res) => {
          processEvents(res.data.events).then();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
    }
  }, [filters, processEvents]);

  /**
   * Function that starts the search process
   */
  const startSearch = useCallback(() => {
    setActiveEvents([]);

    setIsLoading(true);
    getEvents();
  }, [getEvents]);

  /**
   * Function that runs when the component mounts
   * if there are search params in the URL, set the filters state on load
   */
  useEffect(() => {
    if (Object.keys(filters).length > 0 && !autoSearch) {
      setAutoSearch(true);
      if (firstLoad && searchParams.toString() !== "") startSearch();
    }
  }, [filters, firstLoad, searchParams, startSearch, autoSearch]);

  // The date format to be used in the table
  const dateFormat = "dddd, MMMM Do YYYY";
  const timeFormat = "h:mm A";

  const renderFilters = () => {
    return <MpvUpFilters setFilters={setFilters} search={startSearch}/>;
  };

  const renderDataGrid = () => {
    return (
      <Box
        style={{paddingTop: 0, height: "100%"}}
        /* Generate a CSS class to highlight the column header that the filters have been applied to */
        sx={
          statsFor === "tm"
            ? {"& .tm-header": selectedHeaderStyle}
            : statsFor === "rs"
              ? {"& .rs-header": selectedHeaderStyle}
              : {"& .sh-header": selectedHeaderStyle}
        }
      >
        <MpvUpDataGrid
          events={activeEvents}
          loading={isLoading}
          firstLoad={firstLoad}
        />
      </Box>
    );
  };

  /**
   * Return the JSX of the page
   */
  return (
    <Template
      isLoading={isLoading}
      filtersComponent={renderFilters()}
      datagridComponent={renderDataGrid()}
      searchFunc={startSearch}
    />
  );
}

export default TmUpPage;
