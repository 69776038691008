// ----- Packages -----
import axios from "axios";
import moment from "moment";
import { Line } from "react-chartjs-2";
import React, { useCallback, useEffect, useState } from "react";

// ----- MUI -----
import { Box, Button, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import UpdateIcon from "@mui/icons-material/Update";

// ----- Types -----
import { SgSale } from "../../../../Types/Sg";

/**
 * Displays the seatgeek sales graph for an event
 * @param props{seatgeekId} - The seatgeek id
 */
const SeatgeekSalesGraph = (props: { seatgeekId: string }) => {
  // ----- State -----
  const [isLoading, setIsLoading] = useState(true);

  // ----- Data -----
  const [rawSales, setRawSales] = useState<SgSale[]>([]);
  const [aggregatedSales, setAggregatedSales] = useState<any>([]);
  const [qtyAndGetInPerDay, setQtyAndGetInPerDay] = useState<any>([]);

  const [data, setData] = useState<any>([]);

  /**
   * Fetches the sales and listings data from the API
   */
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await axios
      .get(`/api/seatgeek/sales/${props.seatgeekId}`)
      .then((res) => {
        setRawSales(res.data);
        setAggregatedSales(aggregateSalesData(res.data));
      })
      .catch(() => {
        setIsLoading(false);
      });

    await axios
      .get(`/api/seatgeek/qtyPerDay/${props.seatgeekId}`)
      .then((res) => {
        setQtyAndGetInPerDay(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [props.seatgeekId]);


  const mergeSalesAndListings = useCallback(() => {
    const merged = qtyAndGetInPerDay.map((item: any) => {
      const match = aggregatedSales.find((d: any) => d.date === moment(item.creationDate).format("MM/DD/YYYY"));
      if (match) {
        return {...item, qty: match.qty};
      }
      return item;
    });

    setData(merged);
  }, [qtyAndGetInPerDay, aggregatedSales]);

  useEffect(() => {
    if (qtyAndGetInPerDay.length > 0 && aggregatedSales.length > 0) {
      mergeSalesAndListings();
    }
  }, [qtyAndGetInPerDay, aggregatedSales, mergeSalesAndListings]);

  /**
   * Updates the sales and listings data from the API
   */
  const updateData = () => {
    setIsLoading(true);
    axios
      .post(`/api/seatgeek/refresh/${props.seatgeekId}`)
      .then(async () => {
        await fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Aggregates the sales data by date
   * @param sales<SgSale[]> The sales data
   */
  const aggregateSalesData = (sales: SgSale[]) => {
    const result: any[] = [];

    sales.forEach((sale: SgSale) => {
      const date = moment(sale.putc).format("MM/DD/YYYY");
      const existingItem = result.find((r) => r.date === date);
      if (existingItem) {
        existingItem.qty += sale.q;
      } else {
        result.push({date, qty: sale.q});
      }
    });

    return result;
  };

  // ----- Effects -----
  useEffect(() => {
    const fetch = async () => {
      await fetchData().then(() => setIsLoading(false));
    };

    if (!props.seatgeekId) return;

    fetch().then();
  }, [props.seatgeekId, fetchData]);

  // ----- DataGrid Columns -----
  const salesColumns = [
    {
      field: "putc",
      headerName: "Sold Date",
      width: 175,
      valueGetter: (params: any) => {
        return moment(params.value).format("MM/DD/YYYY - hh:mm A");
      },
    },
    {field: "s", headerName: "Section", width: 100},
    {field: "r", headerName: "Row", width: 100},
    {field: "q", headerName: "Quantity", width: 100},
    {
      field: "bp",
      headerName: "Price",
      type: "number",
      width: 100,
      valueFormatter: (params: any) => {
        return `$${params.value}`;
      }
    },
  ];

  // ----- Graph Components -----
  const listingsGraphData = {
    // @ts-ignore
    labels: data.map((d: any) => moment(d.creationDate)),
    datasets: [
      {
        fill: false,
        backgroundColor: "rgba(79,158,255,0.51)",
        borderColor: "rgb(61,157,255)",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgb(61,157,255)",
        pointRadius: 7,
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        label: "Total Sold",
        spanGaps: true,
        // @ts-ignore
        data: data.map((d) => d.qty),
        yAxisID: "y-total-sold",
      },
      {
        fill: false,
        backgroundColor: "rgba(255, 99, 132, 0.51)",
        borderColor: "rgb(255, 99, 132)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgb(255, 99, 132)",
        pointRadius: 7,
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        label: "Total Tickets",
        // @ts-ignore
        data: data.map((d) => d.totalTicketsSg),
        yAxisID: "y-total-tickets",
      },
      {
        fill: false,
        backgroundColor: "rgba(255,206,99,0.51)",
        borderColor: "rgb(255,206,99)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "rgb(255,206,99)",
        pointRadius: 7,
        pointBorderWidth: 1,
        pointHoverRadius: 10,
        label: "Get In",
        // @ts-ignore
        data: data.map((d) => d.getin),
        yAxisID: "y-get-in",
      }
    ],
  };

  // ----- Options for the graph -----
  let options = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          distribution: "linear",
          time: {
            tooltipFormat: "MMMM DD",
            unit: "day",
          },
          display: true,
        },
      ],
      yAxes: [
        {
          position: "left",
          id: "y-total-sold",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },

          scaleLabel: {
            display: true,
            labelString: "Total Sold",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
        {
          position: "right",
          id: "y-get-in",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
        },
        {
          position: "right",
          id: "y-total-tickets",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
        }
      ],
    },
  };

  // ----- Render -----
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <Box sx={{py: 1, px: 5}}>
      <Button onClick={updateData} startIcon={<UpdateIcon/>}>
        ping for last data
      </Button>

      <Box sx={{display: "flex", justifyContent: "space-around"}}>
        <Box sx={{width: 800, p: 1}}>
          <Typography variant="h5">Sales</Typography>
          <DataGridPro
            sx={{height: 900}}
            rows={rawSales}
            columns={salesColumns}
          />
        </Box>
        <Box sx={{width: "100%", p: 1}}>
          <Typography variant="h5" sx={{ml: 5}}>
            Tickets
          </Typography>
          <Line type={"line"} data={listingsGraphData} options={options}/>
        </Box>
      </Box>
    </Box>
  );
};

export default SeatgeekSalesGraph;
