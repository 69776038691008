// ----- Packages ----- //
import React, { memo, useState } from "react";
import axios from "axios";

// ----- MUI ----- //
import { GridColumnGroupingModel, GridColumns, useGridApiRef, } from "@mui/x-data-grid-pro";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import GraphViewIcon from "@mui/icons-material/QueryStats";
import SyncIcon from "@mui/icons-material/Sync";

// ----- Types ----- //
import { SgDataGridProps } from "../../../Types/Sg";
import SearchDataGrid from "../../../components/dataGrids/SearchDataGrid";

/**
 * The data grid component for the upcoming seatgeek events
 * @param props<SgDataGridProps> The props for the data grid
 * @returns The data grid component
 */
export default memo(function SgDataGrid(props: SgDataGridProps) {
  const [syncingAll, setSyncingAll] = useState(false);
  const apiRef = useGridApiRef();

  /**
   * Sync the row with the latest data from the seatgeek api
   * @param row_id<number> The id of the row to sync
   * @return boolean Whether the sync was successful
   */
  const syncRow = async (row_id: number) => {
    return await axios
      .post("/api/seatgeek/refresh/" + row_id)
      .then((res) => {
        const data = res.data[0] as {
          tickets_sold: number;
          listings_sold: number;
        };
        // Update the row with the new data
        apiRef.current.updateRows([
          {
            id: row_id,
            tickets_sold: data.tickets_sold,
            listings_sold: data.listings_sold,
          },
        ]);
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  // The columns groupings for the data grid. Only used for the active events
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "SeatGeek",
      children: [
        {field: "avg_decrease"},
        {field: "mir"},
        {field: "mqr"},
        {field: "sold_last_thirty"},
        {field: "sold_last_seven"},
        {field: "sold_yesterday"},
        {field: "tickets_sold"},
        {field: "listings_sold"},
        {field: "total_listings"},
      ],
    },
    {
      groupId: "Ticketmaster",
      children: [
        {field: "tm_remaining"},
        {field: "tm_mir"},
        {field: "tm_mqr"},
        {field: "tm_decrease_avg"},
      ],
    },
    {
      groupId: " ",
      children: [{field: "actions"}],
    },
  ];

  // The default column for the data grid
  const columns: GridColumns = [
    {
      field: "links",
      headerName: "Link",
      width: 95,
      pinnable: true,
      sortable: false,
      // Render the links to the seatgeek and ticketmaster pages
      renderCell: (params) => {
        return (
          <Box sx={{color: "primary.main", display: "flex"}}>
            <a
              href={params.row.url}
              target="_blank"
              rel="noreferrer"
              style={{color: "inherit"}}
            >
              <Button sx={{minWidth: 0}}>SG</Button>
            </a>
            {params.row.tm_id && (
              <a
                href={"https://www.ticketmaster.com/event/" + params.row.tm_id}
                target="_blank"
                rel="noreferrer"
                style={{color: "inherit"}}
              >
                <Button sx={{minWidth: 0}}>TM</Button>
              </a>
            )}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "event_date",
      headerName: "Date",
      type: "dateTime",
    },
    {
      field: "venue",
      headerName: "Venue",
    },
    {
      field: "category",
      headerName: "Category",
      // Capitalize the first letter of the category
      renderCell: ({value}) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : "",
    },
    // {
    //   field: "in_ds",
    //   headerName: "In DS",
    //   // Render a check or cross depending on the value of the field (red=not in DS, green=in DS)
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           margin: "auto",
    //         }}
    //       >
    //         {params.value != null ? (
    //           params.value ? (
    //             <CheckIcon style={{color: "green"}}/>
    //           ) : (
    //             <ClearIcon style={{color: "red"}}/>
    //           )
    //         ) : (
    //           <RemoveIcon style={{color: "gray"}}/>
    //         )}
    //       </Box>
    //     );
    //   },
    // },
    // {
    //   field: "total_listings",
    //   headerName: "Remaining",
    // },
    // {
    //   field: "avg_decrease",
    //   headerName: "Avg Daily Sales",
    // },
    // {
    //   field: "mir",
    //   headerName: "MIR",
    // },
    // {
    //   field: "mqr",
    //   headerName: "MQR",
    // },
    // {
    //   field: "sold_last_thirty",
    //   headerName: "Sold (30)",
    // },
    // {
    //   field: "sold_last_seven",
    //   headerName: "Sold (7)",
    // },
    // {
    //   field: "sold_yesterday",
    //   headerName: "Sold (1)",
    // },
    // {
    //   field: "tickets_sold",
    //   headerName: "Tickets Sold",
    // },
    // {
    //   field: "listings_sold",
    //   headerName: "Listings Sold",
    // },
    // {
    //   field: "separator",
    //   headerName: "---",
    //   sortable: false,
    //   filterable: false,
    //   width: 0,
    //   align: "center",
    //   // Render a vertical line between the seatgeek and ticketmaster columns
    //   renderCell: () => (
    //     <Box
    //       sx={{
    //         borderLeft: "1px solid",
    //         borderColor: "grey.300",
    //         height: "100%",
    //       }}
    //     />
    //   ),
    //   headerAlign: "center",
    // },
    // {
    //   field: "tm_remaining",
    //   headerName: "Remaining",
    // },
    // {
    //   field: "tm_mir",
    //   headerName: "MIR",
    // },
    // {
    //   field: "tm_mqr",
    //   headerName: "MQR",
    // },
    // {
    //   field: "tm_decrease_avg",
    //   headerName: "Avg Daily Sales",
    // },
    {
      field: "is_parking",
      headerName: "Parking",
      align: "center",
      // Render a green check or red x depending on if the event has parking
      renderCell: (params) => {
        return (
          <Box sx={{color: "primary.main", display: "flex"}}>
            {params.row.is_parking ? (
              <CheckIcon sx={{color: "green"}}/>
            ) : (
              <ClearIcon sx={{color: "red"}}/>
            )}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      // Render the sync all button
      renderHeader: () => (
        <Button
          sx={{height: "25px"}}
          variant={syncingAll ? "contained" : "outlined"}
          color={"secondary"}
          onClick={async () => {
            setSyncingAll(true);
            await props.syncAll();
            setSyncingAll(false);
          }}
          disabled={
            syncingAll || props.events.length === 0 || props.events.length > 50
          }
          title="Sync all events"
          startIcon={
            <SyncIcon
              sx={{
                color: "inherit",
                animation: syncingAll ? "spin 1s linear infinite" : "none",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotate(360deg)",
                  },
                  "100%": {
                    transform: "rotate(0deg)",
                  },
                },
              }}
            />
          }
        >
          All
        </Button>
      ),
      width: 135,
      pinnable: true,
      sortable: false,
      // Render the links to the seatgeek and ticketmaster detail pages
      renderCell: (params) => {
        return (
          <Box>
            <SyncButton syncRow={syncRow} rowId={params.row.id}/>
            <Button
              sx={{minWidth: "10px"}}
              onClick={() => {
                window.open(`/seatgeek/${params.row.id}`, "_blank");
              }}
            >
              <Typography
                fontSize={10}
                color="text.secondary"
                sx={{position: "absolute", top: "2px", left: 3}}
              >
                SG
              </Typography>
              <GraphViewIcon/>
            </Button>
            {params.row.tm_id && (
              <Button
                style={{minWidth: "10px"}}
                onClick={() => {
                  window.open(`/event/${params.row.tm_id}`, "_blank");
                }}
              >
                <Typography
                  fontSize={10}
                  color="text.secondary"
                  sx={{
                    position: "absolute",
                    top: "2px",
                    left: params.row.tm_id.startsWith("Z") ? 1 : 3,
                  }}
                >
                  TM
                  {params.row.tm_id.startsWith("Z") ? "+" : ""}
                </Typography>
                <GraphViewIcon/>
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  // The data grid component fully configured and filled with the data
  return (
    <SearchDataGrid
      id={"SG"}
      data={props.events}
      loading={props.loading}
      firstLoad={props.firstLoad}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      processRowUpdate={() => {
      }}
    />
  );
});

/**
 * The sync button component for each row
 * @param props<{syncRow: (rowId: number) => Promise<boolean>, rowId: number}> The props for the component
 * @returns {JSX.Element} The sync button component
 */
function SyncButton(props: {
  syncRow: (rowId: number) => Promise<boolean>;
  rowId: number;
}) {
  const {syncRow, rowId} = props;
  // The state for the button
  const [isSyncing, setIsSyncing] = useState(false);
  const [error, setError] = useState<boolean>(false);

  // The function to handle the click event
  const handleSyncClick = async () => {
    setIsSyncing(true);
    setError(!(await syncRow(rowId)));
    setIsSyncing(false);
  };

  // The button component
  return (
    <Button
      sx={{minWidth: "10px"}}
      onClick={() => {
        handleSyncClick().then();
      }}
      color={error ? "error" : "secondary"}
      title={"Sync"}
      disabled={isSyncing}
    >
      <SyncIcon
        sx={{
          color: "inherit",
          animation: isSyncing ? "spin 1s linear infinite" : "none",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(360deg)",
            },
            "100%": {
              transform: "rotate(0deg)",
            },
          },
        }}
      />
    </Button>
  );
}
