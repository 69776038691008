// ----- Packages ----- //
import React, { useEffect, useState } from "react";
import moment from "moment/moment";

// ----- MUI ----- //
import { Box } from "@mui/material";

// ----- Components ----- //
import DefaultInput from "../../../../components/inputs/DefaultInput";

// ----- Types ----- //
import { TmPaFiltersProps } from "../../../../Types/Tm";

/**
 * The filters component for the past ticketmaster events
 * @param props<TmPaFiltersProps> The props for the filters
 * @returns The filters component
 *
 * TODO: Refactor this component to use the new filters component
 */
export default function TmPaFilters(props: TmPaFiltersProps) {
  // ----- Props ----- //
  const filters = props.filters;
  const search = props.search;

  // ----- Filters ----- //
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [exactDate, setExactDate] = useState("");
  const [artist, setArtist] = useState("");
  const [venue, setVenue] = useState("");

  /**
   * When the filters change, update the filters in the parent component
   */
  useEffect(() => {
    filters.fromDate = moment(fromDate).isValid()
      ? moment(fromDate).format("YYYY-MM-DD")
      : null;
    filters.toDate = moment(toDate).isValid()
      ? moment(toDate).format("YYYY-MM-DD")
      : null;
    filters.exactDate = moment(exactDate).isValid()
      ? moment(exactDate).format("YYYY-MM-DD")
      : null;
    filters.artist = artist;
    filters.venue = venue;

    props.setFilters(filters);
  }, [fromDate, toDate, exactDate, artist, venue, filters, props]);

  /**
   * Render the component
   * @returns {JSX.Element} The component
   */
  return (
    <Box sx={{ display: "flex" }}>
      <Box>
        {/* Artist/Venue name */}
        <DefaultInput
          id={"artist"}
          label={"Artist"}
          title={"The artist or band name"}
          type={"text"}
          value={artist}
          setValue={setArtist}
          error={
            (artist.length > 0 && artist.length <= 3) ||
            (artist.length === 0 && venue.length === 0)
          }
          startSearch={search}
        />

        <DefaultInput
          id={"venue"}
          label={"Venue"}
          title={"The venue name"}
          type={"text"}
          value={venue}
          setValue={setVenue}
          error={
            (venue.length > 0 && venue.length <= 3) ||
            (artist.length === 0 && venue.length === 0)
          }
          startSearch={search}
        />
      </Box>

      <Box>
        {/* From/To Date */}
        <DefaultInput
          id={"from-date"}
          label={"From"}
          title={"Filter out all events that were before this date"}
          type={"date"}
          value={fromDate}
          setValue={setFromDate}
          error={fromDate !== null && moment(fromDate).isBefore(fromDate)}
          startSearch={search}
        />

        <DefaultInput
          id={"to-date"}
          label={"To"}
          title={"Filter out all events that were after this date"}
          type={"date"}
          value={toDate}
          setValue={setToDate}
          error={toDate !== null && moment(toDate).isBefore(toDate)}
          startSearch={search}
        />
      </Box>

      <Box>
        {/* Exact Date */}
        <DefaultInput
          id="exact-date"
          label="Exact Event Date"
          title="Filter out all events that were not on this date"
          type="date"
          value={exactDate}
          setValue={setExactDate}
          startSearch={search}
        />
      </Box>
    </Box>
  );
}
