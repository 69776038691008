// Props for the alert threshold modal
import { GridColumns } from "@mui/x-data-grid-pro";

export type AlertThresholdModalProps = {
  eventId: string;
  remaining: number;
  remaining_threshold: number;
  mir: number;
  mir_threshold: number;
};

// Props for the input component for the min and max remaining
export type MinMaxRemainingInputProps = {
  minRemaining: number | string;
  maxRemaining: number | string;
  setMinRemaining: (value: number | string) => void;
  setMaxRemaining: (value: number | string) => void;
  minMaxRemainIsNb: boolean;
  setMinMaxIsNb: (value: boolean) => void;
  checkIfDisabled: (value: string) => boolean;
  startSearch: () => void;
  hideMax?: boolean;
};

// Props for the default input component
export type DefaultInputProps = {
  id: string;
  label: string;
  title: string;
  type: string;
  value: any;
  setValue: (value: any) => void;
  error?: boolean;
  startSearch: () => void;
  disabled?: boolean;
  hide?: boolean;
};

export type FiltersPresetProps = {
  id: string;
  filters: any;
  updateValue: (key: string, value: any) => void;
};

// Data about a global alert
export type GlobalAlert = {
  id: number;
  name: string;
  cap_left: number | null;
  avg_dec: number | null;
  mir: number | null;
  mqr: number | null;
  dte: number | null;
  data_points: number | null;
};

// Props for the global alerts data grid
export type GlobalAlertsGridProps = {
  rows: GlobalAlert[];
  update: (globalAlert: GlobalAlert) => Promise<boolean>;
  remove: (id: string) => void;
};

// Details about a sale in the DS
export type DsSale = {
  invoice_id: string;
  skybox_event_id: string;
  quantity: number;
  section: string;
  row: string;
  sale_amount: number;
  created_at: string;
};

// All the search types available in the app
export enum SEARCH_TYPES {
  TM_ACTIVE = "Tm_Active",
  TM_PAST = "Tm_Past",
  VIA_ACTIVE = "Viagogo",
  SG_ACTIVE = "Seatgeek",
  SG_VIA_ACTIVE = "Sg_Via",
  MPV = "Mpv",
}

// Available matchings events
export type MatchingEvents = {
  gametime_id: string;
  seatgeek_id: string;
  sh_id: string;
  tevo_id: string;
  ticketnetwork_id: string;
  tm_id: string;
  viagogo_id: string;
  vividseats_id: string;
};

export type InventoryGraphComponentProps = {
  tmEventId: string;
  sgEventId: string;
  alertThreshold: number | null;
};

export type ErrorDetails = {
  component: string;
  error: any;
};

export type SearchDataGridProps = {
  id: string;
  data: any[];
  loading: boolean;
  firstLoad: boolean;
  columns: GridColumns;
  columnGroupingModel: any;
  processRowUpdate: (params: any) => void;
  getRowId?: (row: any) => string;
};

export interface PreOnSaleData {
  sale_type: number;
  sale_date: string;
  graph_data: {
    date: string;
    quantity: number;
  }[];
}

export enum Colors {
  TM = "#007aeb",
  TM_RESALE = "#d0006f",
  VIA = "#74c300",
  SG = "#ff544e",
}
