import React from "react";

// ----- MUI -----
import { Button, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AlertIcon from "@mui/icons-material/Notifications";
import ArtistIcon from "@mui/icons-material/PersonSearch";
import MatchIcon from '@mui/icons-material/JoinInner';

/**
 * Component that renders the nav menu
 * The nav menu is a button that opens a menu with links to other pages in Bowman
 * @param props<{current: string}> - props for the component
 * @returns {JSX.Element} - The component
 */
export default function NavMenu(props: any) {
  // The anchor element for the menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // If the menu is open or not
  const open = Boolean(anchorEl);

  /**
   * Handle the opening of the menu
   * @param event<React.MouseEvent<HTMLButtonElement>> - The event that triggered the opening of the menu
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handle the closing of the menu
   * @param url<string> - The url to open in a new tab
   * @param blank<boolean> - If the url should be opened in a new tab or not
   */
  const handleClose = (url: string, blank: boolean = true) => {
    if (url !== "") window.open(url, blank ? "_blank" : "_self");
    setAnchorEl(null);
  };

  /**
   * Return the component
   */
  return (
    <div>
      <Button
        id="nav-menu-button"
        sx={{position: "absolute", top: 0, right: 0, minWidth: 0, m: 2, p: 1}}
        aria-controls={open ? "" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon/>
      </Button>
      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("")}
        MenuListProps={{
          "aria-labelledby": "nav-menu-button",
        }}
      >
        {/* Goto main bowman page */}
        <MenuItem
          disabled={props.current === "search"}
          onClick={() => handleClose("/")}
        >
          <SearchIcon sx={{mr: 1}} fontSize={"small"}/>
          Search
        </MenuItem>
        {/* Goto the global alerts page */}
        <MenuItem
          disabled={props.current === "globalalerts"}
          onClick={() => handleClose("/globalalerts")}
        >
          <AlertIcon sx={{mr: 1}} fontSize={"small"}/>
          Global alerts
        </MenuItem>
        {/* Goto artist dropcheck page */}
        <MenuItem
          disabled={props.current === "artistdropcheck"}
          onClick={() => handleClose("/artistdropcheck")}
        >
          <ArtistIcon sx={{mr: 1}} fontSize={"small"}/>
          Artist dropcheck
        </MenuItem>

        <MenuItem
          disabled={props.current === "mcDavidMatch"}
          onClick={() => handleClose("/mcDavidMatch")}
        >
          <MatchIcon sx={{mr: 1}} fontSize={"small"}/>
          McDavid Match PO
        </MenuItem>

        <MenuItem
          disabled={props.current === "usOpen"}
          onClick={() => handleClose("/usOpen")}
        >
          <MatchIcon sx={{mr: 1}} fontSize={"small"}/>
          Us Open
        </MenuItem>

        {/*<MenuItem*/}
        {/*  onClick={() => handleClose("/logout", false)}*/}
        {/*  sx={{ color: "error.main" }}*/}
        {/*>*/}
        {/*  <LogoutIcon sx={{ mr: 1 }} fontSize={"small"} />*/}
        {/*  Log out*/}
        {/*</MenuItem>*/}
      </Menu>
    </div>
  );
}
