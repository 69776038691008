/**
 *  !!! This is a temporary solution to look up the data found by the API call !!!
 */

// ----- Module -----
import React, { useState } from "react";
import axios from "axios";

// ----- MUI -----
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NavMenu from "../components/others/NavMenu";

interface EmailInfo {
  seller: string;
  event: string;
  venue: string;
  date: string;
  tickets: string;
  section: string;
  row: string;
  total: string;
  box: string;
}

/**
 * Page to search for an invoice
 * @constructor Invoice - The invoice page
 */
const Invoice = () => {
  document.title = "Invoice";

  const [saleNb, setSaleNb] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [result, setResult] = useState<EmailInfo[]>([]);

  async function search(saleNb: string) {
    setLoading(true);
    setResult([]);
    setError("");
    try {
      const resp = await axios.get(
        `https://invoice.plessinc.com/api/emails/find?saleNb=${saleNb}`
      );

      setResult([resp.data.info]);
    } catch (e: any) {
      if (e.response) setError(e.response.data.error);
      else setError(e.message);
    }
    setLoading(false);
  }

  return (
    <Box sx={{ m: 2 }}>
      <NavMenu current={"invoice"} />
      <h1>Invoice</h1>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          size={"small"}
          id="outlined-basic"
          label="Sale Nb"
          variant="outlined"
          onChange={(e) => setSaleNb(e.target.value)}
          // on key press enter
          onKeyDown={async (e) => {
            if (e.key === "Enter") await search(saleNb);
          }}
        />
        <Button
          sx={{ ml: 3 }}
          variant="contained"
          disabled={loading}
          onClick={async () => {
            await search(saleNb);
          }}
        >
          Search
          {loading && <CircularProgress sx={{ ml: 1 }} size={20} />}
        </Button>
      </Box>

      {error && <p style={{ color: "red" }}>{error}</p>}

      <Box sx={{ display: "flex" }}>
        <TableContainer component={Paper} sx={{ width: "auto", mt: 3 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SELLER</TableCell>
                <TableCell>EVENT</TableCell>
                <TableCell>VENUE</TableCell>
                <TableCell>DATE</TableCell>
                <TableCell>TICKETS</TableCell>
                <TableCell>SECTION</TableCell>
                <TableCell>ROW</TableCell>
                <TableCell>TOTAL</TableCell>
                <TableCell>BOX</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((info) => (
                <TableRow
                  key={info.section + info.row}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{info.seller}</TableCell>
                  <TableCell>{info.event}</TableCell>
                  <TableCell>{info.venue}</TableCell>
                  <TableCell>{info.date}</TableCell>
                  <TableCell>{info.tickets}</TableCell>
                  <TableCell>{info.section}</TableCell>
                  <TableCell>{info.row}</TableCell>
                  <TableCell>${info.total}</TableCell>
                  <TableCell>{info.box}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box></Box>
      </Box>
    </Box>
  );
};

export default Invoice;
