// ----- Packages -----
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

// ----- MUI -----
import { Box, Card, Skeleton } from "@mui/material";

// ----- Components -----
import NavMenu from "../../components/others/NavMenu";
import MpvEventInfo from "../../components/event/details/MpvInfo";
import axios from "axios";
import MpvTabs from "../../components/event/details/MpvTabs";


// ----- Other -----
import ErrorCard from "../../components/others/cards/ErrorCard";


/**
 * This component is used to display all the details of a Seatgeek event
 * - Show the event details and different graphs
 * @constructor Sg - The Seatgeek event details page
 */
const Mpv = () => {
  let {eventId} = useParams<{ eventId: string }>();
  const [eventDetail, setEventDetail] = React.useState<any>();
  const [noEvent, setNoEvent] = React.useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      document.title = "Loading...";

      try {
        // ----- Fetch -----
        const eventResp = await axios(`/api/events/mpv/${eventId}`);
        if (eventResp.status !== 200 || !eventResp.data.event) {
          setNoEvent(true);
          document.title = "No event found";
          return;
        }
        // ----- Get data from resp -----
        const event = eventResp.data.event;

        if (event.timezone)
          event.event_date = moment(event.event_date)
            .tz(event.timezone)
            .format("MM/DD/YYYY HH:mm");

        // ----- Set -----
        document.title = event.event_name;

        setEventDetail(event);
      } catch (err) {
        console.log(err);
      }
    };
    fetch().then();
  }, [eventId]);

  return noEvent ? (
    <Box // center in page
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
      }}
    >
      <ErrorCard
        details={{
          component: "pages/Details/Tm.tsx",
          error: "No event found for " + eventId,
        }}
        show={true}
      />
    </Box>
  ) : (
    <Box sx={{pt: 1}}>
      <NavMenu current={""}/>
      {/* Show loading until event is ready */}
      {eventDetail ? (
        <Box>
          <Card
            sx={{
              m: 3,
              px: 2,
              py: 1,
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MpvEventInfo eventDetail={eventDetail}/>
          </Card>
          {/* <NavMenu />
        <SeatgeekSalesGraph seatgeekId={eventId || ""} /> */}
        </Box>
      ) : (
        <Box sx={{ml: 2, pt: 1, display: "flex", gap: 3}}>
          <Box sx={{display: "flex"}}>
            <Skeleton
              width={40}
              height={140}
              variant="rounded"
              sx={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
            />
            <Skeleton
              width={400}
              height={165}
              variant="rounded"
              sx={{borderTopLeftRadius: 0}}
            />
          </Box>
          <Skeleton width={500} height={145} variant="rounded"/>
          <Skeleton width={150} height={100} variant="rounded"/>
          <Skeleton width={75} height={100} variant="rounded"/>
          <Skeleton width={800} height={165} variant="rounded"/>
        </Box>
      )}
      <MpvTabs
        event_id={eventId || ""}
        seatgeek_id={eventDetail?.seatgeek_id}
        stubhub_id={eventDetail?.stubhub_id}
      />
    </Box>
  );
};

export default Mpv;
