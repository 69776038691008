// ----- React ----- //
import React, { useEffect, useState } from "react";

// ----- Packages ----- //
import { Line } from "react-chartjs-2";
import axios from "axios";
import moment from "moment";

// ----- MUI ----- //
import { Box, Card, Typography } from "@mui/material";

// ----- Types ----- //
import { Colors, InventoryGraphComponentProps } from "../../../../Types/Other";

/**
 * Displays the tm inventory graph for an event
 * @param props{tmEventId, sgEventId, alertThreshold} - The event ids and the alert threshold
 */
const InventoryGraph = (props: InventoryGraphComponentProps) => {
  const {tmEventId, sgEventId, alertThreshold} = props;

  // ----- State -----
  const [isLoading, setIsLoading] = useState(true);
  const [dataPoints, setDataPoints] = useState([]);

  // Get the data tm and resale data points
  useEffect(() => {
    const fetch = async () => {
      const response = await axios(`/api/counters/${tmEventId}`);
      response.data.forEach((d: any) => {
        d.creationDate = moment(d.creationDate).format("YYYY-MM-DD");
        d.totalTicketsSg = 0;
      });

      const data = response.data;

      const validData = data.filter((d: any) => moment(d.creationDate).isValid());

      setDataPoints(validData);
    };
    fetch().then(() => {
      setIsLoading(false);
    });
  }, [tmEventId, sgEventId]);

  // ----- Data to show in the graph ----- //
  const data = {
    // @ts-ignore
    labels: dataPoints.map((d) => moment(d.creationDate)),
    datasets: [
      {
        fill: false,
        borderColor: "#ffcc00",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointRadius: 0,
        label: alertThreshold ? "TM Alert" : "hidden",
        data: dataPoints.map(() => alertThreshold),
      },
      {
        fill: false,
        backgroundColor: "rgba(0,122,235,0.4)",
        borderColor: Colors.TM,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "Ticketmaster",
        // @ts-ignore
        data: dataPoints.map((d) => d.totalTickets),
        yAxisID: "y-axis-1",
      },
      {
        fill: false,
        backgroundColor: "rgba(208,0,111,0.4)",
        borderColor: Colors.TM_RESALE,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM_RESALE,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "Resale",
        // @ts-ignore
        data: dataPoints.map((d) => d.totalTicketsResale),
        yAxisID: "y-axis-2",
      },
    ],
  };

  // ----- Options for the graph ----- //
  let options = {
    // don't show tooltips if name is hidden
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || "";
          if (datasetLabel.includes("hidden")) return;
          else return datasetLabel + ": " + tooltipItem.yLabel;
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    legend: {
      display: true,
      labels: {
        // Hide the label for the alert threshold if there is no alert threshold
        filter: function (item: any) {
          return !item.text.includes("hidden");
        },
      },
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          distribution: "linear",
          time: {
            tooltipFormat: "MMMM DD",
            unit: "day",
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Day",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          id: "y-axis-1",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Primary",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
        {
          position: "right",
          id: "y-axis-2",
          ticks: {
            beginAtZero: true,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Resale",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
      ],
    },
  };

  // ----- Render ----- //
  return (
    <Box sx={{width: "100%"}}>
      <Card
        sx={{
          width: "fit-content",
          ml: 4,
          py: 1,
          pl: 2,
          pr: 5,
        }}
      >
        <Typography variant="h5">Listings</Typography>
      </Card>
      <Box sx={{position: "relative", bottom: 10}}>
        <Line type={"line"} data={isLoading ? {} : data} options={options}/>
      </Box>
    </Box>
  );
};

export default InventoryGraph;
