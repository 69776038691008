// ----- Packages -----
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

// ----- MUI -----
import { Box, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

interface Promotions {
  id: string;
  code: string;
  description: string;
}

/**
 * Displays the seatgeek sales graph for an event
 * @param props{eventId} - The event id
 */
const MpvPromotions = (props: { eventId: string }) => {
  // ----- State -----
  const [isLoading, setIsLoading] = useState(true);

  // ----- Data -----
  const [promotions, setPromotions] = useState<Promotions[]>([]);

  /**
   * Fetches the sales and listings data from the API
   */
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await axios
      .get(`/api/mpv/promotions/${props.eventId}`)
      .then((res) => {
        setPromotions(JSON.parse(res.data.promotions));
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [props.eventId]);

  // ----- Effects -----
  useEffect(() => {
    const fetch = async () => {
      await fetchData().then(() => setIsLoading(false));
    };

    if (!props.eventId) return;

    fetch().then();
  }, [props.eventId, fetchData]);

  // ----- DataGrid Columns -----
  const promotionsColumns = [
    {
      field: "id",
      headerName: "Promotion ID",
    },
    { field: "code", headerName: "Promotion Code", width: 150 },
    { field: "description", headerName: "Description", width: 500 },
  ];

  // ----- Render -----
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <Box sx={{ py: 1, px: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box sx={{ width: 800, p: 1 }}>
          <Typography variant="h5">Promotions - Coupon Codes</Typography>
          <DataGridPro
            sx={{ height: 900 }}
            rows={promotions}
            columns={promotionsColumns}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MpvPromotions;
