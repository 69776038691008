// ----- Packages ----- //
import axios from "axios";
import moment from "moment/moment";
import React, { memo, useState } from "react";
import { Link } from "react-router-dom";

// ----- MUI ----- //
import { Box, Button, Card, Divider, TextField, Tooltip, Typography, } from "@mui/material";

import MapIcon from "@mui/icons-material/Map";
import NotesIcon from "@mui/icons-material/Comment";
import EditNotesIcon from "@mui/icons-material/Edit";
import DateIcon from "@mui/icons-material/CalendarMonth";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import SearchIcon from "@mui/icons-material/ManageSearch";
import GroupsIcon from '@mui/icons-material/Groups';

// ----- Other ----- //
import { TmEvent } from "../../../Types/Tm";
import { iconLinkStyle } from "../../../styles";

/**
 * The component for the event details (name, venue, date, notes)
 * @param props{{eventDetail: TmEvent, setEventDetail: React.Dispatch<React.SetStateAction<TmEvent | undefined>>}} - The props for the component
 */
const EventInfo = memo((props: {
  eventDetail: TmEvent;
}) => {
  const {eventDetail} = props;

  const [notes, setNotes] = useState<string>(eventDetail.notes);
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const [noteError, setNoteError] = useState<boolean>(false);

  // ----- Functions -----
  const handleNotesChange = (event_id: string, notes: string) => {
    setEditNotes(false);
    axios
      .put(`/api/events/${event_id}/notes`, {
        notes: notes,
      })
      .then(() => setNoteError(false))
      .catch(() => setNoteError(true));
  };

  const handleNotesEdit = () => {
    setEditNotes(!editNotes);
    setTimeout(() => {
      const textField = document.querySelector("#notes") as HTMLInputElement;
      if (textField) textField.focus();
    }, 0);
  };

  // ----- Render ----- //
  return (
    <Card
      sx={{
        p: 1,
        borderTopLeftRadius: "0",
        maxWidth: "400px",
        minWidth: "300px",
      }}
    >
      <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
        <Typography
          variant={eventDetail.event_name.length > 40 ? "h6" : "h5"}
          fontWeight={"bold"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          title={eventDetail.event_name}
        >
          {eventDetail.event_name}
        </Typography>
        {/* Link to search for other event with same name */}
        <Box sx={iconLinkStyle}>
          <a
            title={"Searchs for all " + eventDetail.event_name + " events"}
            href={`/?artist=${eventDetail.event_name}`}
            target="_blank"
            rel="noreferrer"
            style={{display: "flex", alignSelf: "center"}}
          >
            <SearchIcon sx={{paddingBottom: "2px"}}/>
          </a>
        </Box>
      </Box>
      <Box>
        {/* Name and link of venue */}
        <div style={{display: "flex"}}>
          <MapIcon sx={{mr: 1}}/>
          <Link
            to={eventDetail.venue_url}
            target={"_blank"}
            style={{
              color: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={eventDetail.venue_name}
          >
            {eventDetail.venue_name}
          </Link>
          {/* Link to search for other event at same venue */}
          <Box sx={iconLinkStyle}>
            <a
              title={"Searchs for all events at " + eventDetail.venue_name}
              href={`/?venue=${eventDetail.venue_name}`}
              target="_blank"
              rel="noreferrer"
              style={{display: "flex", alignSelf: "center"}}
            >
              <SearchIcon sx={{paddingBottom: "2px"}}/>
            </a>
          </Box>

          <Tooltip title={"Total capacity of venue"}>
            <Box sx={{ml: "auto", display: "flex", alignItems: "center", color: "text.disabled"}}>
              <GroupsIcon sx={{mr: 0.5}}/>
              <Typography variant={"body2"}>
                {eventDetail.capacity}
              </Typography>
            </Box>
          </Tooltip>
        </div>
        <Divider sx={{my: 1}}/>
        {/* Date and time of event */}
        <Typography
          sx={
            moment(eventDetail.event_date).isBefore(moment())
              ? {fontWeight: "bold", color: "error.main", display: "flex"}
              : {display: "flex"}
          }
        >
          <DateIcon sx={{mr: 1}}/>
          {moment(eventDetail.event_date).format("ddd, MM/DD/YYYY h:mm A")}
        </Typography>
        <Divider sx={{my: 1}}/>
        {/* Notes about the event */}
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: notes ? "text.primary" : "text.disabled",
            }}
          >
            {noteError ? (
              <ErrorIcon sx={{mr: 1}} color={"error"}/>
            ) : (
              <NotesIcon sx={{mr: 1}}/>
            )}
          </Typography>

          {editNotes ? (
            <TextField
              id={"notes"}
              fullWidth
              variant="standard"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              size={"small"}
              onBlur={() =>
                handleNotesChange(eventDetail.url_id, notes)
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Escape")
                  handleNotesChange(eventDetail.url_id, notes);
              }}
              error={notes.length > 500}
            />
          ) : notes ? (
            <Typography onClick={() => handleNotesEdit()}>
              {notes}
            </Typography>
          ) : (
            <Typography
              onClick={() => handleNotesEdit()}
              color={"text.disabled"}
            >
              No available notes
            </Typography>
          )}

          <Box sx={iconLinkStyle}>
            {!editNotes ? (
              <Button
                onClick={() => handleNotesEdit()}
                size={"small"}
                sx={{minWidth: 0}}
              >
                <EditNotesIcon fontSize={"small"}/>
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Card>
  );
});

export default EventInfo;
