// ----- Module -----
import React, { useEffect, useState } from "react";
import axios from "axios";

// ----- MUI -----
import { Box, Button, Card, CircularProgress, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TitleIcon from "@mui/icons-material/PersonSearch";

// ----- Components -----
import NavMenu from "../components/others/NavMenu";
import ArtistsDropCheckDataGrid from "../components/dataGrids/ArtistsDropCheckDataGrid";
import ErrorCard from "../components/others/cards/ErrorCard";
import moment from "moment";

/**
 * Artist Dropcheck list page
 * @constructor ArtistDropcheck - The artist dropcheck list page
 */
const ArtistDropcheck = () => {
  document.title = "Artist Dropcheck";

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const [artists, setArtists] = useState<any[]>([]);

  const [keyWord, setKeyWord] = useState<string>("");

  useEffect(() => {
    getArtistsInDC();
  }, []);

  function getArtistsInDC() {
    setLoading(true);
    axios
      .get(`https://count.plessinc.com/artists/dropcheck`)
      .then((resp) => {
        resp.data.forEach((artist: any) => (artist.watched = true));

        resp.data.sort((a: any, b: any) =>
          moment(b.updatedAt).diff(moment(a.updatedAt), "seconds")
        );

        setArtists(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        // @ts-ignore
        setError(err.response);
        setLoading(false);
      });
  }

  function searchArtist() {
    if (keyWord === "") {
      getArtistsInDC();
      return;
    }

    setLoading(true);
    axios
      .get(`https://count.plessinc.com/artists/search`, {
        params: {
          keywords: keyWord,
        },
      })
      .then((resp) => {
        setArtists(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  return (
    <Box sx={{ m: 2 }}>
      <NavMenu current={"artistdropcheck"} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Card sx={{ px: 2, mb: 1, display: "flex", alignItems: "center" }}>
          <TitleIcon sx={{ fontSize: 50 }} />
          <h1>Artist Dropcheck</h1>
        </Card>
        <TextField
          id="search-artist"
          label="Name"
          variant="outlined"
          size={"small"}
          sx={{ ml: 3 }}
          value={keyWord}
          onChange={(e) => setKeyWord(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") searchArtist();
          }}
        />
        <Button
          variant="outlined"
          sx={{ ml: 1, minWidth: 0, p: 1 }}
          onClick={searchArtist}
        >
          <SearchIcon />
        </Button>
        {loading && <CircularProgress sx={{ ml: 1 }} size={25} />}
      </Box>

      <ErrorCard
        show={error !== ""}
        details={{
          component: "src/pages/ArtistDropcheck.tsx",
          error: error,
        }}
      />

      {!error && <ArtistsDropCheckDataGrid data={artists} loading={loading} />}
    </Box>
  );
};

export default ArtistDropcheck;
