// ----- Pacakges ----- //
import React, { useEffect, useState } from "react";
import axios from "axios";

// ----- MUI ----- //
import { CircularProgress, Stack, Typography } from "@mui/material";

/**
 * Fetches a random cat fact from the Cat Fact API.
 */
let catFact = "";

export async function fetchCatFact() {
  if (!catFact) {
    const response = await axios.get("https://catfact.ninja/fact");
    catFact = response.data.fact;
  }
  return catFact;
}

/**
 * Loading overlay for the data grid.
 */
const Loading = () => {
  // ----- State ----- //
  const [catFact, setCatFact] = useState("Loading...");

  // ----- Effects ----- //
  useEffect(() => {
    let isMounted = true;

    async function getCatFact() {
      const fact = await fetchCatFact();
      if (isMounted) setCatFact(fact);
    }

    getCatFact().then();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <CircularProgress sx={{ m: 3 }} />
      <Typography variant="body1">
        While we're loading, here's a random cat fact:
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: "text.secondary", maxWidth: 400, textAlign: "center" }}
      >
        {catFact}
      </Typography>
    </Stack>
  );
};

export default Loading;
