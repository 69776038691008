// ----- Packages ----- //
import React, { useState } from "react";

// ----- MUI ----- //
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

import AlertIcon from "@mui/icons-material/NotificationsNone";
import TitleIcon from "@mui/icons-material/NotificationsNone";
import AddAlertIcon from "@mui/icons-material/AddAlertOutlined";
import ActiveAlertIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NoAlertIcon from "@mui/icons-material/NotificationsOffOutlined";

// ----- Other ----- //
import { modalStyle } from "../../styles";
import { AlertThresholdModalProps } from "../../Types/Other";
import { Link } from "react-router-dom";
import { ringingAnimation } from "../../animations";

/**
 * Modal that contains the input to set the threshold for an event
 * @param props<AlertThresholdModalProps> - eventId, threshold, remaining
 */
export default function ThresholdAlert(props: AlertThresholdModalProps) {
  const [remThresh, setRemThresh] = useState<string | number | number[]>(
    props.remaining_threshold || ""
  );
  const [mirThresh, setMirThresh] = useState<string | number | number[]>(
    props.mir_threshold || ""
  );

  const [open, setOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const handleOpen = async () => {
    getThresholds();
    setOpen(true);
    setError(false);
  };

  const handleClose = () => {
    getThresholds();
    setOpen(false);
  };

  const handleSet = () => {
    const data = {
      remaining_threshold: remThresh === "" ? null : remThresh,
      mir_threshold: mirThresh === "" ? null : mirThresh,
    };

    setIsSaving(true);

    fetch(`/api/threshold/${props.eventId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => {
        if (data.ok) {
          setOpen(false);
        } else setError(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  /**
   * Get the current thresholds for the event
   * - Remaining threshold is the number of remaining tickets that triggers an alert
   * - MIR threshold is the MIR that triggers an alert
   */
  const getThresholds = () => {
    fetch(`/api/threshold/${props.eventId}`, {
      method: "GET",
    })
      .then((data) => {
        if (data.ok) {
          data.json().then((data) => {
            const rem = data.remaining_threshold || "";
            const mir = data.mir_threshold || "";
            setRemThresh(rem);
            setMirThresh(mir);
          });
        } else setError(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const remMarks = [
    {
      value: 0,
      label: <NoAlertIcon fontSize={"small"} />,
    },
    {
      value: Math.ceil(props.remaining * 0.25),
      label: `${Math.ceil(props.remaining * 0.25)}`,
    },
    {
      value: Math.ceil(props.remaining * 0.5),
      label: `${Math.ceil(props.remaining * 0.5)}`,
    },
    {
      value: Math.ceil(props.remaining * 0.75),
      label: `${Math.ceil(props.remaining * 0.75)}`,
    },
    {
      value: props.remaining - 1,
      label: props.remaining - 1,
    },
  ];

  const remMarksLOW = [
    {
      value: 0,
      label: <NoAlertIcon fontSize={"small"} />,
    },
    {
      value: props.remaining - 1,
      label: `${props.remaining - 1}`,
    },
  ];

  const mirMarks = [
    {
      value: props.mir,
      label: <NoAlertIcon fontSize={"small"} />,
    },
    {
      value: props.mir + 1,
      label: Math.round((props.mir + 1) * 10) / 10,
    },
    {
      value: props.mir + 2,
      label: Math.round((props.mir + 2) * 10) / 10,
    },
    {
      value: props.mir + 3,
      label: Math.round((props.mir + 3) * 10) / 10,
    },
  ];

  // @ts-ignore
  return (
    <div>
      <Button
        disabled={props.remaining <= 0}
        style={{ minWidth: "10px" }}
        onClick={handleOpen}
      >
        {remThresh || mirThresh ? (
          remThresh >= props.remaining ||
          (mirThresh !== "" && mirThresh <= props.mir) ? (
            <ActiveAlertIcon
              color="warning"
              sx={{
                animation: `${ringingAnimation} 0.5s ease-in-out infinite`,
              }}
            />
          ) : (
            <AlertIcon color="success" />
          )
        ) : (
          <AddAlertIcon />
        )}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{ display: "flex", alignItems: "center", marginY: 3 }}>
            <TitleIcon sx={{ fontSize: 40, mr: 1 }} />
            <h1 style={{ margin: 0 }}>Edit Alert Thresholds</h1>
          </Box>

          <Typography color={"text.secondary"}>
            Set a threshold on various metrics to receive an alert when it is
            reached.
            <br />
            You will be notified via Slack in the channel{" "}
            <Link
              to={"https://ticketaria.slack.com/archives/C04758AH4G5"}
              target={"_blank"}
              style={{ color: "inherit", fontWeight: "bold" }}
            >
              #threshold-alerts
            </Link>
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ m: 3 }}>
            {/* Remaining Threshold Section */}
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Typography
                  color={remThresh ? "success" : "text.secondary"}
                  textAlign="center"
                  width={75}
                >
                  Remaining Tickets
                </Typography>
              </Grid>
              <Grid item>
                <Box>
                  <TextField
                    sx={{ width: 90 }}
                    size={"small"}
                    value={remThresh}
                    onChange={(event) =>
                      setRemThresh(
                        event.target.value === ""
                          ? ""
                          : Number(event.target.value)
                      )
                    }
                    error={remThresh >= props.remaining || remThresh < 0}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: props.remaining,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs sx={{ mx: 2 }}>
                <Slider
                  sx={{ mt: 1 }}
                  marks={props.remaining >= 50 ? remMarks : remMarksLOW}
                  value={typeof remThresh === "number" ? remThresh : 0}
                  onChange={(event, newValue) => {
                    if (newValue === 0) setRemThresh("");
                    else setRemThresh(newValue);
                  }}
                  aria-labelledby="input-slider"
                  max={props.remaining - 1}
                  // @ts-ignore : 'color' is a valid prop, but typescript doesn't like it
                  color={
                    remThresh >= props.remaining || remThresh < 0
                      ? "error"
                      : remThresh
                      ? "info"
                      : "primary"
                  }
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Typography fontSize={12} color="text.secondary">
              Send an alert when the remaining tickets are
              <strong> less than or equal </strong>
              to this value.
            </Typography>

            <Divider sx={{ my: 3 }} />

            {/* MIR Threshold Section */}
            <Grid container alignItems="center" columnSpacing={3}>
              <Grid item>
                <Typography
                  color={mirThresh ? "success" : "text.secondary"}
                  textAlign="center"
                  width={75}
                >
                  MIR
                </Typography>
              </Grid>
              <Grid item>
                <Box>
                  <TextField
                    sx={{ width: 90 }}
                    size={"small"}
                    value={mirThresh}
                    onChange={(event) =>
                      setMirThresh(
                        event.target.value === ""
                          ? ""
                          : Number(event.target.value)
                      )
                    }
                    error={mirThresh !== "" && mirThresh <= props.mir}
                    inputProps={{
                      step: 0.1,
                      min: props.mir,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs sx={{ mx: 2 }}>
                <Slider
                  sx={{ mt: 1 }}
                  marks={mirMarks}
                  value={typeof mirThresh === "number" ? mirThresh : props.mir}
                  onChange={(event, newValue) => {
                    if (newValue <= props.mir) setMirThresh("");
                    else setMirThresh(newValue);
                  }}
                  aria-labelledby="input-slider"
                  step={0.1}
                  min={props.mir}
                  max={props.mir + 3}
                  // @ts-ignore : 'color' is a valid prop, but typescript doesn't like it
                  color={
                    mirThresh !== "" && mirThresh <= props.mir
                      ? "error"
                      : mirThresh !== ""
                      ? "info"
                      : "primary"
                  }
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Typography fontSize={12} color="text.secondary">
              Send an alert when the MIR is
              <strong> more than or equal </strong>
              to this value.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            {error && <p style={{ margin: "auto 25px" }}>An error occurred</p>}
            <Button sx={{ mr: 2 }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSet}
              disabled={
                isSaving ||
                remThresh > props.remaining ||
                (remThresh !== "" && remThresh < 0) ||
                (mirThresh !== "" && mirThresh < props.mir)
              }
            >
              Save
              {isSaving && <CircularProgress sx={{ ml: 1 }} size={20} />}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
