// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { Box, Stack, Typography } from "@mui/material";

/**
 * No rows overlay for the data grid.
 * @param props<{ firstLoad: boolean }> - Whether this is the first load.
 */
export default function DataGridBody(props: { firstLoad: boolean }) {
  const firstLoad = props.firstLoad;

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <div>
        {firstLoad ? (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6">Welcome to Bowman!</Typography>
            <Typography variant="body1">
              To get started, click the "SEARCH" button to launch a research.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6">
              No events found for this search!
            </Typography>
            <Typography variant="body1">
              Try again with different filters.
            </Typography>
          </Box>
        )}
      </div>
    </Stack>
  );
}
