import { keyframes } from "@emotion/react";

export const ringingAnimation = keyframes`
    0% {transform: rotate(0deg);}
    25% {transform: rotate(-20deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(20deg);}
    100% {transform: rotate(0deg);}
`;

export const bouncingAnimation = keyframes`
    0% {transform: translateY(0px);}
  33% {
    transform: translateY(-3px);
  }
  66% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const zoomingAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

export const rotatingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
