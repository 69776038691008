import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import GraphViewIcon from "@mui/icons-material/QueryStats";
import { Button } from "@mui/material";

const eventsAshe = [
  {
    "tm_id_2024": "1D006054A9001D09",
    "tm_id_2023": "1D005E6FC0AD3755",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-26",
    "time": "12:00 PM",
    "dow": "Monday",
    "session": 1,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-26-2024/event/1D006054A9001D09"
  },
  {
    "tm_id_2024": "1D006054AA0A1DF6",
    "tm_id_2023": "1D005E6FC13F37A0",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-26",
    "time": "7:00 PM",
    "dow": "Monday",
    "session": 2,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-26-2024/event/1D006054AA0A1DF6"
  },
  {
    "tm_id_2024": "1D006054A9041D0E",
    "tm_id_2023": "1D005E6FC0AE3765",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-27",
    "time": "12:00 PM",
    "dow": "Tuesday",
    "session": 3,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-27-2024/event/1D006054A9041D0E"
  },
  {
    "tm_id_2024": "1D006054AA0D1E0B",
    "tm_id_2023": "1D005E6FC14037A4",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-27",
    "time": "7:00 PM",
    "dow": "Tuesday",
    "session": 4,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-27-2024/event/1D006054AA0D1E0B"
  },
  {
    "tm_id_2024": "1D006054A9071D20",
    "tm_id_2023": "1D005E6FC0AE376B",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-28",
    "time": "12:00 PM",
    "dow": "Wednesday",
    "session": 5,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-28-2024/event/1D006054A9071D20"
  },
  {
    "tm_id_2024": "1D006054AA101E11",
    "tm_id_2023": "1D005E6FC14137A6",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-28",
    "time": "7:00 PM",
    "dow": "Wednesday",
    "session": 6,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-28-2024/event/1D006054AA101E11"
  },
  {
    "tm_id_2024": "1D006054A90B1D28",
    "tm_id_2023": "1D005E6FC0AF376F",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-29",
    "time": "12:00 PM",
    "dow": "Thursday",
    "session": 7,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-29-2024/event/1D006054A90B1D28"
  },
  {
    "tm_id_2024": "1D006054AA141E1E",
    "tm_id_2023": "1D005E6FC14237A8",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-29",
    "time": "7:00 PM",
    "dow": "Thursday",
    "session": 8,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-29-2024/event/1D006054AA141E1E"
  },
  {
    "tm_id_2024": "1D006054A90F1D34",
    "tm_id_2023": "1D005E6FC0B03771",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-30",
    "time": "12:00 PM",
    "dow": "Friday",
    "session": 9,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-30-2024/event/1D006054A90F1D34"
  },
  {
    "tm_id_2024": "1D006054AA171E30",
    "tm_id_2023": "1D005E6FC14337AD",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-30",
    "time": "7:00 PM",
    "dow": "Friday",
    "session": 10,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-30-2024/event/1D006054AA171E30"
  },
  {
    "tm_id_2024": "1D006054A9121D5F",
    "tm_id_2023": "1D005E6FC0B03773",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-31",
    "time": "12:00 PM",
    "dow": "Saturday",
    "session": 11,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-31-2024/event/1D006054A9121D5F"
  },
  {
    "tm_id_2024": "1D006054AA1B1E80",
    "tm_id_2023": "1D005E6FC14437B0",
    "court": "Arthur Ashe",
    "date_2024": "2024-08-31",
    "time": "7:00 PM",
    "dow": "Saturday",
    "session": 12,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-31-2024/event/1D006054AA1B1E80"
  },
  {
    "tm_id_2024": "1D006054A9161D73",
    "tm_id_2023": "1D005E6FC0B13779",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-01",
    "time": "12:00 PM",
    "dow": "Sunday",
    "session": 13,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-01-2024/event/1D006054A9161D73"
  },
  {
    "tm_id_2024": "1D006054AA1F1E85",
    "tm_id_2023": "1D005E6FC14637B2",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-01",
    "time": "7:00 PM",
    "dow": "Sunday",
    "session": 14,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-01-2024/event/1D006054AA1F1E85"
  },
  {
    "tm_id_2024": "1D006054A91A1D75",
    "tm_id_2023": "1D005E6FC0B2377F",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-02",
    "time": "12:00 PM",
    "dow": "Monday",
    "session": 15,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-02-2024/event/1D006054A91A1D75"
  },
  {
    "tm_id_2024": "1D006054AA221E8D",
    "tm_id_2023": "1D005E6FC14837B6",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-02",
    "time": "7:00 PM",
    "dow": "Monday",
    "session": 16,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-02-2024/event/1D006054AA221E8D"
  },
  {
    "tm_id_2024": "1D006054A91D1D7B",
    "tm_id_2023": "1D005E6FC0B33783",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-03",
    "time": "12:00 PM",
    "dow": "Tuesday",
    "session": 17,
    "url_2024": "https://www.ticketmaster.com/quarterfinal-menswomens-flushing-new-york-09-03-2024/event/1D006054A91D1D7B"
  },
  {
    "tm_id_2024": "1D006054AA261E8F",
    "tm_id_2023": "1D005E6FC14A37BA",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-03",
    "time": "7:00 PM",
    "dow": "Tuesday",
    "session": 18,
    "url_2024": "https://www.ticketmaster.com/quarterfinal-menswomens-flushing-new-york-09-03-2024/event/1D006054AA261E8F"
  },
  {
    "tm_id_2024": "1D006054A9211D7D",
    "tm_id_2023": "1D005E6FC0B33787",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-04",
    "time": "12:00 PM",
    "dow": "Wednesday",
    "session": 19,
    "url_2024": "https://www.ticketmaster.com/quarterfinal-menswomens-flushing-new-york-09-04-2024/event/1D006054A9211D7D"
  },
  {
    "tm_id_2024": "1D006054AA291E9D",
    "tm_id_2023": "1D005E6FC14B37BE",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-04",
    "time": "7:00 PM",
    "dow": "Wednesday",
    "session": 20,
    "url_2024": "https://www.ticketmaster.com/quarterfinal-menswomens-flushing-new-york-09-04-2024/event/1D006054AA291E9D"
  },
  {
    "tm_id_2024": "1D006054AA2D1EA1",
    "tm_id_2023": "1D005E6FC14D37C0",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-05",
    "time": "7:00 PM",
    "dow": "Thursday",
    "session": 21,
    "url_2024": "https://www.ticketmaster.com/womens-singles-semifinals-flushing-new-york-09-05-2024/event/1D006054AA2D1EA1"
  },
  {
    "tm_id_2024": "1D006054A9801DA1",
    "tm_id_2023": "1D005E6FC0DD378C",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-05",
    "time": "12:00 PM",
    "dow": "Thursday",
    "session": 22,
    "url_2024": "https://www.ticketmaster.com/womens-dbls-finalmens-semifinals-flushing-new-york-09-06-2024/event/1D006054A9801DA1"
  },
  {
    "tm_id_2024": "1D006054AA301EB8",
    "tm_id_2023": "1D005E6FC14E37C5",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-06",
    "time": "7:00 PM",
    "dow": "Friday",
    "session": 23,
    "url_2024": "https://www.ticketmaster.com/mens-singles-semifinals-flushing-new-york-09-06-2024/event/1D006054AA301EB8"
  },
  {
    "tm_id_2024": "1D006054A9831DC1",
    "tm_id_2023": "1D005E6FC0DE3797",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-07",
    "time": "12:00 PM",
    "dow": "Saturday",
    "session": 24,
    "url_2024": "https://www.ticketmaster.com/mens-dbls-finalwomens-final-flushing-new-york-09-07-2024/event/1D006054A9831DC1"
  },
  {
    "tm_id_2024": "1D006054A9A81DDD",
    "tm_id_2023": "1D005E6FC0DF379D",
    "court": "Arthur Ashe",
    "date_2024": "2024-09-08",
    "time": "1:00 PM",
    "dow": "Sunday",
    "session": 25,
    "url_2024": "https://www.ticketmaster.com/mens-final-flushing-new-york-09-08-2024/event/1D006054A9A81DDD"
  }
];

const eventsArm = [
  {
    "tm_id_2024": "1D006054AE021F43",
    "tm_id_2023": "1D005E6FAE902DCD",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-26",
    "time": "11:00 AM",
    "dow": "Monday",
    "session": 1,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-26-2024/event/1D006054AE021F43"
  },
  {
    "tm_id_2024": "1D006054AE801FE9",
    "tm_id_2023": "1D005E6FAEF72DE8",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-26",
    "time": "7:00 PM",
    "dow": "Monday",
    "session": 2,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-26-2024/event/1D006054AE801FE9"
  },
  {
    "tm_id_2024": "1D006054AE031F51",
    "tm_id_2023": "1D005E6FAE912DCF",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-27",
    "time": "11:00 AM",
    "dow": "Tuesday",
    "session": 3,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-27-2024/event/1D006054AE031F51"
  },
  {
    "tm_id_2024": "1D006054AE812009",
    "tm_id_2023": "1D005E6FAEF72DEA",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-27",
    "time": "7:00 PM",
    "dow": "Tuesday",
    "session": 4,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-27-2024/event/1D006054AE812009"
  },
  {
    "tm_id_2024": "1D006054AE041F54",
    "tm_id_2023": "1D005E6FAE912DD2",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-28",
    "time": "11:00 AM",
    "dow": "Wednesday",
    "session": 5,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-28-2024/event/1D006054AE041F54"
  },
  {
    "tm_id_2024": "1D006054AE822022",
    "tm_id_2023": "1D005E6FAEF72DEC",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-28",
    "time": "7:00 PM",
    "dow": "Wednesday",
    "session": 6,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-28-2024/event/1D006054AE822022"
  },
  {
    "tm_id_2024": "1D006054AE051F58",
    "tm_id_2023": "1D005E6FAE912DD5",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-29",
    "time": "11:00 AM",
    "dow": "Thursday",
    "session": 7,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-29-2024/event/1D006054AE051F58"
  },
  {
    "tm_id_2024": "1D006054AE832025",
    "tm_id_2023": "1D005E6FAEF82DEE",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-29",
    "time": "7:00 PM",
    "dow": "Thursday",
    "session": 8,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-29-2024/event/1D006054AE832025"
  },
  {
    "tm_id_2024": "1D006054AE061F66",
    "tm_id_2023": "1D005E6FAE912DD7",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-30",
    "time": "11:00 AM",
    "dow": "Friday",
    "session": 9,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-30-2024/event/1D006054AE061F66"
  },
  {
    "tm_id_2024": "1D006054AE84202A",
    "tm_id_2023": "1D005E6FAEF82DF3",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-30",
    "time": "7:00 PM",
    "dow": "Friday",
    "session": 10,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-30-2024/event/1D006054AE84202A"
  },
  {
    "tm_id_2024": "1D006054AE071F7C",
    "tm_id_2023": "1D005E6FAE922DD9",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-31",
    "time": "11:00 AM",
    "dow": "Saturday",
    "session": 11,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-31-2024/event/1D006054AE071F7C"
  },
  {
    "tm_id_2024": "1D006054AE852055",
    "tm_id_2023": "1D005E6FAEF82DF6",
    "court": "Louis Armstrong",
    "date_2024": "2024-08-31",
    "time": "7:00 PM",
    "dow": "Saturday",
    "session": 12,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-31-2024/event/1D006054AE852055"
  },
  {
    "tm_id_2024": "1D006054AE081F85",
    "tm_id_2023": "1D005E6FAE922DDD",
    "court": "Louis Armstrong",
    "date_2024": "2024-09-01",
    "time": "11:00 AM",
    "dow": "Sunday",
    "session": 13,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-01-2024/event/1D006054AE081F85"
  },
  {
    "tm_id_2024": "1D006054AE091FBE",
    "tm_id_2023": "1D005E6FAE922DE1",
    "court": "Louis Armstrong",
    "date_2024": "2024-09-02",
    "time": "11:00 AM",
    "dow": "Monday",
    "session": 15,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-02-2024/event/1D006054AE091FBE"
  }
];

const eventsGround = [
  {
    "tm_id_2024": "1D006054B2B5237F",
    "tm_id_2023": "1D005E6FB8EF3483",
    "court": "Grounds",
    "date_2024": "2024-08-26",
    "time": "11:00 AM",
    "dow": "Monday",
    "session": 1,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-26-2024/event/1D006054B2B5237F"
  },
  {
    "tm_id_2024": "1D006054B2B623DA",
    "tm_id_2023": "1D005E6FB8EF3490",
    "court": "Grounds",
    "date_2024": "2024-08-27",
    "time": "11:00 AM",
    "dow": "Tuesday",
    "session": 3,
    "url_2024": "https://www.ticketmaster.com/1st-round-mens-womens-flushing-new-york-08-27-2024/event/1D006054B2B623DA"
  },
  {
    "tm_id_2024": "1D006054B2B623E5",
    "tm_id_2023": "1D005E6FB8EF349F",
    "court": "Grounds",
    "date_2024": "2024-08-28",
    "time": "11:00 AM",
    "dow": "Wednesday",
    "session": 5,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-28-2024/event/1D006054B2B623E5"
  },
  {
    "tm_id_2024": "1D006054B2B723E9",
    "tm_id_2023": "1D005E6FB8EF34A1",
    "court": "Grounds",
    "date_2024": "2024-08-29",
    "time": "11:00 AM",
    "dow": "Thursday",
    "session": 7,
    "url_2024": "https://www.ticketmaster.com/2nd-round-mens-womens-flushing-new-york-08-29-2024/event/1D006054B2B723E9"
  },
  {
    "tm_id_2024": "1D006054B2B723FF",
    "tm_id_2023": "1D005E6FB8EF34A3",
    "court": "Grounds",
    "date_2024": "2024-08-30",
    "time": "11:00 AM",
    "dow": "Friday",
    "session": 9,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-30-2024/event/1D006054B2B723FF"
  },
  {
    "tm_id_2024": "1D006054B2B82406",
    "tm_id_2023": "1D005E6FB8F034A5",
    "court": "Grounds",
    "date_2024": "2024-08-31",
    "time": "11:00 AM",
    "dow": "Saturday",
    "session": 11,
    "url_2024": "https://www.ticketmaster.com/3rd-round-mens-womens-flushing-new-york-08-31-2024/event/1D006054B2B82406"
  },
  {
    "tm_id_2024": "1D006054B2B82418",
    "tm_id_2023": "1D005E6FB94D34CF",
    "court": "Grounds",
    "date_2024": "2024-09-01",
    "time": "11:00 AM",
    "dow": "Sunday",
    "session": 13,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-01-2024/event/1D006054B2B82418"
  },
  {
    "tm_id_2024": "1D006054B2B92423",
    "tm_id_2023": "1D005E6FB8F034A7",
    "court": "Grounds",
    "date_2024": "2024-09-02",
    "time": "11:00 AM",
    "dow": "Monday",
    "session": 15,
    "url_2024": "https://www.ticketmaster.com/round-of-16-menswomens-flushing-new-york-09-02-2024/event/1D006054B2B92423"
  },
  {
    "tm_id_2024": "1D006054B2B9242D",
    "tm_id_2023": "1D005E6FB8F034AB",
    "court": "Grounds",
    "date_2024": "2024-09-03",
    "time": "11:00 AM",
    "dow": "Tuesday",
    "session": 17,
    "url_2024": "https://www.ticketmaster.com/doubles-quarterfinal-menswomens-flushing-new-york-09-03-2024/event/1D006054B2B9242D"
  }
];

const events = eventsAshe.concat(eventsArm).concat(eventsGround);

const TmpUsOpen = () => {
  return (
    <>
      <h1>TmpUsOpen</h1>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Court</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Time</TableCell>
              <TableCell align="right">Day</TableCell>
              <TableCell align="right">Session</TableCell>
              <TableCell align="right">2024 ID</TableCell>
              <TableCell align="right">2023 ID</TableCell>
              <TableCell align="right">Merge</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row) => (
              <TableRow
                key={row.tm_id_2024}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {row.court}
                </TableCell>
                <TableCell align="right">{row.date_2024}</TableCell>
                <TableCell align="right">{row.time}</TableCell>
                <TableCell align="right">{row.dow}</TableCell>
                <TableCell align="right">{row.session}</TableCell>
                <TableCell align="right">
                  {row.tm_id_2024}
                  {detailLink(row.tm_id_2024)}
                </TableCell>

                <TableCell align="right">
                  {row.tm_id_2023}
                  {detailLink(row.tm_id_2023)}
                </TableCell>
                <TableCell align="right">
                  <a
                    href={`/usOpen/graph/${row.tm_id_2024}/${row.tm_id_2023}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button style={{minWidth: "10px"}}
                            color={"success"}>
                      <GraphViewIcon/>
                    </Button>
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const detailLink = (id: string) => {
  return (
    <a
      href={`/event/${id}`}
      target="_blank"
      rel="noreferrer"
      style={{color: "inherit"}}
    >
      <Button style={{minWidth: "10px"}}>
        <GraphViewIcon/>
      </Button>
    </a>
  );
};

export default TmpUsOpen;
