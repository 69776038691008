// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { Box } from "@mui/material";

// ----- Components ----- //
import ErrorCard from "../../others/cards/ErrorCard";

/**
 * Error overlay for the data grid.
 * @param props<{ error: string | null }> - The error message to display.
 */
export default function Error(props: { error: string | null }) {
  const error = props.error;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
      }}
    >
      <ErrorCard
        show={error !== ""}
        details={{
          component: "dataGrids/Overlays/Error.tsx",
          error: {
            message: error
              ? error
              : "The data could not be loaded properly by the data grid.\n" +
                "Try refreshing the page.\n" +
                "If the problem persists, please contact an administrator.",
          },
        }}
      />
    </Box>
  );
}
