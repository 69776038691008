// ----- Packages ----- //
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import axios from "axios";

// ----- MUI ----- //
import { Box } from "@mui/material";

// ----- Components -----
import TmUpDataGrid from "./TmUpDataGrid";
import TmUpFilters from "./TmUpFilters";
import Template from "../../Template";

// ----- Types -----
import { TmEvent, TmUpFiltersData } from "../../../../Types/Tm";
import { useSearchParams } from "react-router-dom";

/**
 * The upcoming ticketmaster events page, which displays the filters and data grid
 * @returns The upcoming ticketmaster events page
 */
function TmUpPage() {
  // ----- Events -----
  const [activeEvents, setActiveEvents] = useState<TmEvent[]>([]);

  // ----- Events Filters Data -----
  const [filters, setFilters] = useState<TmUpFiltersData>(
    {} as TmUpFiltersData
  );

  // ----- States -----
  const [statsFor, setStatsFor] = useState<string>("tm");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const [autoSearch, setAutoSearch] = useState<boolean>(false);

  // ----- Styles -----
  const selectedHeaderStyle = {
    backgroundColor: "rgba(125, 125, 125, 0.07)",
    fontSize: "1.2rem",
    fontWeight: "bold",
  };


  // ----- URLs -----
  const upcomingEventsUrl = "/api/events";

  /**
   * Function that formats the events data
   * @param events<EventDetails[]> - The events to be formatted
   */
  const processEvents = useCallback(async (events: TmEvent[]) => {
    // Events that are in the drop-shipping
    const dsResp = await axios(
      `https://ds.plessinc.com/api/events?type=activetmids`
    );
    const dsEvents = await dsResp.data.events;

    // Format the events
    events.forEach((event: TmEvent) => {
      event.in_ds = !!dsEvents.includes(event.url_id);

      if (event.timezone) {
        event.week_day = moment(event.event_date)
          .tz(event.timezone)
          .format("ddd");
        event.event_date = moment(event.event_date)
          .tz(event.timezone)
          .format(`${dateFormat}`);
        event.tm_last_ping = moment(event.tm_last_ping)
          .tz(event.timezone)
          .format(dateFormat);
      } else {
        event.week_day = moment(event.event_date).format("ddd");
        event.event_date = moment(event.event_date).format("YYYY-MM-DD");
        event.tm_last_ping = moment(event.tm_last_ping).format(dateFormat);
      }

      event.updated_at = moment(event.updated_at)
        .tz("America/New_York")
        .format(dateFormat);
    });

    setActiveEvents(events);
    setFirstLoad(false);
    setIsLoading(false);
  }, []);

  /**
   * Function that fetches the events from the server
   *  Format the data and set the state
   */
  const getEvents = useCallback(() => {
    try {
      let data = filters;
      let url = upcomingEventsUrl;

      setStatsFor(filters.statsFor);
      if (Object.keys(data).length > 0) {
        url += "?";
        for (let key in data) {
          // @ts-ignore
          if (data[key] === "" || data[key] === null || data[key] === undefined)
            continue;
          // @ts-ignore
          url += `${key}=${data[key]}&`;
        }
        url = url.slice(0, -1);
      } else {
        setIsLoading(false);
        return;
      }

      console.log(url);

      setIsLoading(true);

      axios
        .get(url)
        .then((res) => {
          processEvents(res.data.events).then();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
    }
  }, [filters, processEvents]);

  /**
   * Function that starts the search process
   */
  const startSearch = useCallback(() => {
    setActiveEvents([]);

    setIsLoading(true);
    getEvents();
  }, [getEvents]);

  /**
   * Function that runs when the component mounts
   * if there are search params in the URL, set the filters state on load
   */
  useEffect(() => {
    if (Object.keys(filters).length > 0 && !autoSearch) {
      setAutoSearch(true);
      if (firstLoad && searchParams.toString() !== "") startSearch();
    }
  }, [filters, firstLoad, searchParams, startSearch, autoSearch]);

  // The date format to be used in the table
  const dateFormat = "YYYY-MM-DD";

  const renderFilters = () => {
    return <TmUpFilters setFilters={setFilters} search={startSearch}/>;
  };

  const renderDataGrid = () => {
    return (
      <Box
        style={{paddingTop: 0, height: "100%"}}
        /* Generate a CSS class to highlight the column header that the filters have been applied to */
        sx={
          statsFor === "tm"
            ? {"& .tm-header": selectedHeaderStyle}
            : statsFor === "rs"
              ? {"& .rs-header": selectedHeaderStyle}
              : {"& .sh-header": selectedHeaderStyle}
        }
      >
        <TmUpDataGrid
          events={activeEvents}
          loading={isLoading}
          firstLoad={firstLoad}
        />
      </Box>
    );
  };

  /**
   * Return the JSX of the page
   */
  return (
    <Template
      isLoading={isLoading}
      filtersComponent={renderFilters()}
      datagridComponent={renderDataGrid()}
      searchFunc={startSearch}
    />
  );
}

export default TmUpPage;
