// ----- Packages ----- //
import React, { memo, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

// ----- MUI ----- //
import { Box, Button, CircularProgress, Divider, Modal, TextField, Typography, } from "@mui/material";
import { DataGridPro, GridSelectionModel } from "@mui/x-data-grid-pro";

import AddAlertIcon from "@mui/icons-material/ZoomIn";
import OnAlertIcon from "@mui/icons-material/SearchOff";
import TitleIcon from "@mui/icons-material/Search";

// ----- Components ----- //
import ErrorCard from "../others/cards/ErrorCard";

// ----- Other ----- //
import { modalStyle } from "../../styles";

/**
 * Modal that allows the user to select which sections and types to watch for drops
 * @param props<{eventId: string, isWatched: boolean}> - The event ID and whether or not the event is being watched
 * @constructor
 */
export default memo(function DropCheck(props: {
  eventId: string;
  isWatched: boolean;
}) {
  const [types, setTypes] = useState([]);
  const [sections, setSections] = useState([]);

  const [checkedSections, setCheckedSections] = useState<GridSelectionModel>(
    []
  );
  const [checkedTypes, setCheckedTypes] = useState<GridSelectionModel>([]);

  const [open, setOpen] = useState(false);

  const [isWatched, setIsWatched] = useState(props.isWatched);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);
  const [typesIsLoading, setTypesIsLoading] = useState(true);
  const [typesError, setTypesError] = useState("");
  const [sectionsIsLoading, setSectionsIsLoading] = useState(true);
  const [sectionsError, setSectionsError] = useState("");
  const [note, setNote] = useState<string>("");


  const handleOpen = async () => {
    setOpen(true);

    setTypesIsLoading(true);
    setSectionsIsLoading(true);

    axios
      .get(`https://count.plessinc.com/event/map/${props.eventId}/US`)
      .then((resp) => {
        setSections(resp.data);
        setSectionsIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status !== 403)
          setSectionsError(String(err));
        else
          setSectionsIsLoading(false);
      });

    axios
      .get(`https://count.plessinc.com/event/sections/${props.eventId}/US`)
      .then((resp) => {
        setTypes(resp.data);
        setTypesIsLoading(false);
      })
      .catch((err) => {
        setTypesError(String(err));
      });
  };

  const handleClose = () => {
    setOpen(false);
    setCheckedTypes([]);
    setCheckedSections([]);
  };

  const handleDelete = () => {
    setIsSaving(true);
    fetch(`https://count.plessinc.com/dropchecker/${props.eventId}`, {
      method: "DELETE",
    })
      .then((data) => {
        if (data.ok) {
          setIsWatched(false);
          setOpen(false);
          setError(false);
        } else setError(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleSubmit = () => {
    setIsSaving(true);
    sections.forEach((section: any) => {
      section.isChecked = true;
    });

    types.forEach((type: any) => {
      type.isChecked = true;
    });

    const sectionsToWatch = sections.filter((section: any) =>
      checkedSections.includes(section.id)
    );

    const typesToWatch = types.filter((type: any) =>
      checkedTypes.includes(type.id)
    );

    const data = {
      country: "US",
      eventId: props.eventId,
      sections: sectionsToWatch,
      types: typesToWatch,
      note: note === "" ? null : note,
    };

    fetch("https://count.plessinc.com/dropchecker", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => {
        if (data.ok) {
          setOpen(false);
          setIsWatched(true);
          setCheckedTypes([]);
          setCheckedSections([]);
          setError(false);
        } else setError(true);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const typesDataGrid = useMemo(() => {
    return (
      <DataGridPro
        headerHeight={45}
        rowHeight={35}
        checkboxSelection={true}
        rows={types}
        loading={typesIsLoading}
        columns={[{field: "name", headerName: "Name", flex: 1}]}
        hideFooter={true}
        onSelectionModelChange={(ids) => {
          setCheckedTypes(ids);
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "row-even"
            : "row-odd"
        }
      />
    );
  }, [types, typesIsLoading]);


  const sectionsDataGrid = useMemo(() => {
    return (
      <DataGridPro
        headerHeight={45}
        rowHeight={35}
        checkboxSelection={true}
        rows={sections}
        loading={sectionsIsLoading}
        columns={[{field: "name", headerName: "Name", flex: 1}]}
        hideFooter={true}
        onSelectionModelChange={(ids) => {
          setCheckedSections(ids);
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? "row-even"
            : "row-odd"
        }
      />
    );
  }, [sections, sectionsIsLoading]);


  return (
    <div>
      {(isWatched && (
          <Button
            style={{minWidth: "10px"}}
            sx={{"&:hover": {color: "red"}}}
            color="success"
            onClick={handleOpen}
          >
            <OnAlertIcon/>
          </Button>
        )) ||
        // If event is a resale, show a different icon (Can't add to dropchecker)
        (props.eventId.startsWith("Z") ? (
          <Button style={{minWidth: "10px"}} color="error">
            <Typography
              fontSize={9}
              color="text.secondary"
              sx={{position: "absolute", bottom: 0}}
            >
              RESALE
            </Typography>
            <AddAlertIcon/>
          </Button>
        ) : (
          <Button style={{minWidth: "10px"}} onClick={handleOpen}>
            <AddAlertIcon/>
          </Button>
        ))}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isWatched ? (
          <Box sx={{...modalStyle, width: 1000}}>
            <Box sx={{display: "flex", alignItems: "center", marginY: 3}}>
              <TitleIcon sx={{fontSize: 40, mr: 1}}/>
              <h1 style={{margin: 0}}>Add To Dropchecker</h1>
            </Box>

            <Typography color={"text.secondary"}>
              Select the ticket types and sections you want to watch for drops.
              <br/>
              If a drop is detected, you will be notified via Slack in the
              channel{" "}
              <Link
                to={"https://ticketaria.slack.com/archives/CJWQT9T5J"}
                target={"_blank"}
                style={{color: "inherit", fontWeight: "bold"}}
              >
                #dropcheck
              </Link>
            </Typography>
            <Divider sx={{my: 1}}/>
            <Box sx={{display: "flex"}}>
              <Box sx={{p: 2, flexGrow: 1}}>
                <h2 style={{margin: 0}}>Types</h2>
                <div style={{height: 350}}>
                  {typesError && (
                    <ErrorCard
                      show={true}
                      details={{
                        component: "components/modals/DropCheck.tsx",
                        error: typesError,
                      }}
                    />
                  )}
                  {typesDataGrid}
                </div>
              </Box>

              <Box sx={{p: 2, flexGrow: 2}}>
                <h2 style={{margin: 0}}>Sections</h2>

                <div style={{height: 350}}>
                  {sectionsError && (
                    <ErrorCard
                      show={true}
                      details={{
                        component: "components/modals/DropCheck.tsx",
                        error: sectionsError,
                      }}
                    />
                  )}
                  {sectionsDataGrid}
                </div>
              </Box>
            </Box>

            <Box sx={{p: 2, pt: 2, flexGrow: 1}}>
              <TextField
                id="note"
                sx={{width: "100%"}}
                variant="outlined"
                label="Note"
                size={"small"}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
              <Typography color={"text.secondary"} fontSize={12}>
                This note will be included in the Slack message.
              </Typography>
            </Box>

            <Box sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}>
              <Box sx={{my: "auto", mr: 3}}>
                {checkedSections.length + checkedTypes.length} Item(s) selected
              </Box>
              {error && (
                <p style={{margin: "auto 25px"}}>An error occurred</p>
              )}
              <Button sx={{mr: 2}} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  isSaving ||
                  typesIsLoading ||
                  sectionsIsLoading ||
                  checkedSections.length + checkedTypes.length <= 0
                }
              >
                Submit
                {isSaving && <CircularProgress sx={{ml: 1}} size={20}/>}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={modalStyle}>
            <Box sx={{display: "flex", alignItems: "center", marginY: 3}}>
              <OnAlertIcon sx={{fontSize: 40, mr: 1}}/>
              <h1 style={{margin: 0}}>Remove from dropchecker</h1>
            </Box>
            <p>
              Are you sure you want to remove this event from the dropchecker?
              <br/>
              You will no longer receive notifications for this event.
            </p>
            <Divider sx={{my: 1}}/>

            <Box sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}>
              {error && (
                <p style={{margin: "auto 25px"}}>An error occurred</p>
              )}
              <Button sx={{mr: 2}} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={isSaving}
                variant="contained"
                style={{minWidth: "10px"}}
                color="error"
                onClick={handleDelete}
              >
                Remove
                {isSaving && <CircularProgress sx={{ml: 1}} size={20}/>}
              </Button>
            </Box>
          </Box>
        )}
      </Modal>
    </div>
  );
});
