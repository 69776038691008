// ----- Packages ----- //
import React, { memo } from "react";

// ----- MUI ----- //
import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";

// ----- Components ----- //
import SgViaSales from "./tabs/SgViaSales";
import MpvInventory from "./tabs/MpvInventory";
import SeatgeekSalesGraph from "./tabs/SeatgeekSales";
import MpvPromotions from "./tabs/MpvPromotions";
import DetailedSectionData from "./tabs/MpvDetailedSections";

/**
 * Component to show the tabs in the event details page
 * @param props{tm_id: string, seatgeek_id: string, viagogo_id: string, threshold_alert: number | undefined}
 *  tm_id, seatgeek_id, viagogo_id and threshold_alert of the event
 */
const MpvTabs = memo((props: {
  event_id: string;
  seatgeek_id: string;
  stubhub_id: string;
}) => {
  const {event_id, seatgeek_id, stubhub_id} = props;

  const [tab, setTab] = React.useState(
    window.location.hash
      ? window.location.hash.slice(1).replace(/%20/g, "")
      : "listings"
  );

  // ----- Render ----- //
  return (
    <Box>
      <Box sx={{pl: 4, pt: 1}}>
        <Tabs
          sx={{minHeight: 0}}
          value={tab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(event: React.SyntheticEvent, newValue: string) => {
            window.location.hash = newValue;
            setTab(newValue);
          }}
        >
          <Tab value="listings" label="MPV" sx={{minHeight: 0, py: 1}}/>
          <Tab value="detailed" label="Detailed Section Data" sx={{minHeight: 0, py: 1}}/>
          <Tab value="promotions" label="Promotions" sx={{minHeight: 0, py: 1}}/>
          <Tab value="seatgeek" label="Seatgeek"
               disabled={!seatgeek_id}
               sx={{minHeight: 0, py: 1}}/>

        </Tabs>
      </Box>

      <Divider sx={{my: 1}}/>

      {/* Show graph based on tab */}
      {tab === "listings" && (
        <Grid container>
          <Grid item sm={12} md={8.5}>
            <Box
              sx={{borderRight: "1px solid", borderColor: "divider", m: 1}}
            >
              <MpvInventory
                eventId={event_id || ""}
                sgEventId={seatgeek_id}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={3.5}>
            {(seatgeek_id) && (
              <SgViaSales
                seatgeekId={seatgeek_id || ""}
                viagogoId={stubhub_id || ""}
              />
            )}
          </Grid>
        </Grid>
      )}
      {tab === "seatgeek" && (
        <SeatgeekSalesGraph seatgeekId={seatgeek_id}/>
      )}
      {tab === "promotions" && (
        <MpvPromotions eventId={event_id}/>
      )}
      {tab === "detailed" && (
        <DetailedSectionData eventId={event_id}/>
      )}
    </Box>
  );
});

export default MpvTabs;
