// ----- Packages ----- //
import React, { useState } from "react";

// ----- MUI ----- //
import { Box, Button, Divider, Modal, TextField, Typography, } from "@mui/material";
import MatchIcon from '@mui/icons-material/JoinInner';
import ClearIcon from '@mui/icons-material/Clear';

// ----- Other ----- //
import { modalStyle } from "../../styles";
import ErrorCard from "../others/cards/ErrorCard";
import axios from "axios";

/**
 * Modal that let the user set id for other sellers manually
 * @param props<{eventId: string}> - The event ID
 * @constructor - Displays the modal
 */
export default function Matching(props: { eventId: string }) {
  const event_id = props.eventId;

  // ----- Data ----- //
  const [tmId, setTmId] = useState(""); // Ticketmaster
  const [shId, setShId] = useState(""); // Stubhub
  const [vividId, setVividId] = useState(""); // Vivid Seats
  const [tevoId, setTevoId] = useState(""); // Ticket Evolution
  const [gtId, setGtId] = useState(""); // Gametime
  const [tnId, setTnId] = useState(""); // Ticket Network
  const [sgId, setSgId] = useState(""); // Seat Geek
  const [viaId, setViaId] = useState(""); // Via GoGo


  // ----- State ----- //
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  // ----- Functions ----- //

  /**
   * Opens the modal and fetches the data
   */
  const handleOpen = async () => {
    if (error) {
      setError("");
      setIsLoading(true);
    }

    setOpened(true);

    const matches = await axios(`/api/events/matches/${event_id}`)
      .then(res => res.data)
      .catch(() => setError("Error fetching matches"));

    if (matches) {
      setTmId(matches.tm_id);
      setShId(matches.sh_id);
      setVividId(matches.vividseats_id);
      setTevoId(matches.tevo_id);
      setGtId(matches.gametime_id);
      setTnId(matches.ticketnetwork_id);
      setSgId(matches.seatgeek_id);
      setViaId(matches.viagogogo_id);
    }
  };

  /**
   * Closes the modal
   */
  const handleClose = () => {
    setOpened(false);
  };

  const handleSave = () => {
    axios.put(`/api/events/matches/`, {
      tm_id: tmId || null,
      sh_id: shId || null,
      vividseats_id: vividId || null,
      tevo_id: tevoId || null,
      gametime_id: gtId || null,
      ticketnetwork_id: tnId || null,
      seatgeek_id: sgId || null,
      viagogo_id: viaId || null,
    })
      .then(() => handleClose())
      .catch(() => setError("Error saving matches"));
  };

  const fields = [
    {label: "Ticketmaster ID", value: tmId, setter: setTmId},
    {label: "Stubhub ID", value: shId, setter: setShId},
    {label: "Vivid Seats ID", value: vividId, setter: setVividId},
    {label: "Ticket Evolution ID", value: tevoId, setter: setTevoId},
    {label: "Gametime ID", value: gtId, setter: setGtId},
    {label: "Ticket Network ID", value: tnId, setter: setTnId},
    {label: "Seat Geek ID", value: sgId, setter: setSgId},
    {label: "Via GoGo ID", value: viaId, setter: setViaId},
  ];

  // ----- Render ----- //
  return (
    <div>
      <Button style={{minWidth: "10px"}} onClick={handleOpen}>
        <MatchIcon/>
      </Button>

      <Modal
        open={opened}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={{display: "flex", alignItems: "center", marginY: 3}}>
            <MatchIcon sx={{fontSize: 40, mr: 1}}/>
            <h1 style={{margin: 0}}>Matching</h1>
          </Box>

          <Box sx={{display: "flex"}}>
            <Typography color={"text.secondary"} sx={{display: "flex"}}>
              Set the ID for other sellers manually
            </Typography>
          </Box>

          <Divider sx={{my: 1}}/>

          <Box>
            <Box sx={{overflow: "auto", py: 1}}>

              <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}>
                {fields.map((field, index) => (
                  <Box key={index} sx={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                    <TextField
                      disabled={!(field.label === 'Ticketmaster ID' || tmId)}
                      size={"small"}
                      label={field.label}
                      value={field.value}
                      onChange={(e) => field.setter(e.target.value)}
                      sx={{width: "100%", mr: 1}}
                    />
                    <Button
                      onClick={() => field.setter('')}
                      size={"small"}
                      sx={{minWidth: 0}}
                      color={"error"}
                      disabled={!field.value}
                    >
                      <ClearIcon/>
                    </Button>
                  </Box>
                ))}
              </Box>


              <ErrorCard
                details={{
                  component: "components/modals/Info.tsx",
                  error: error,
                }}
                show={!!error}
              />

            </Box>
          </Box>

          <Box sx={{mt: 3, display: "flex", justifyContent: "flex-end"}}>
            <Button sx={{mr: 2}} onClick={handleClose}>
              Close
            </Button>

            <Button variant={"contained"} onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

