// ----- Packages ----- //
import React from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// ----- MUI ----- //
import { Box, Button, Typography } from "@mui/material";
import MappedIcon from "@mui/icons-material/InsertLink";
import CarIcon from '@mui/icons-material/CarCrash';

// ----- Components ----- //
import DefaultInput from "../../../components/inputs/DefaultInput";

// ----- Types ----- //
import { SgFiltersData, SgFiltersProps } from "../../../Types/Sg";
import FiltersPreset from "../../../components/inputs/FiltersPreset";

/**
 * The filters component for the past ticketmaster events
 * @param props<TmPaFiltersProps> The props for the filters
 * @returns The filters component
 */
export default function SgFilters(props: SgFiltersProps) {
  // ----- Props ----- //
  const {setFilters, search} = props;

  // ----- States ----- //
  const [searchParams, setSearchParams] = useSearchParams();

  // ----- Filters ----- //
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, _setFilters] = React.useState({
    event_name: searchParams.get("event_name") || "",
    event_venue: searchParams.get("event_venue") || "",
    category: searchParams.get("category") || "",
    tickets_sold_lower: searchParams.get("tickets_sold_lower") || "",
    listings_sold_lower: searchParams.get("listings_sold_lower") || "",
    sold_yesterday: searchParams.get("sold_yesterday") || "",
    sold_last_seven: searchParams.get("sold_last_seven") || "",
    sold_last_thirty: searchParams.get("sold_last_thirty") || "",
    event_date_lower:
      searchParams.get("event_date_lower") || moment().format("YYYY-MM-DD"),
    event_date_upper: searchParams.get("event_date_upper") || "",
    mir_lower: searchParams.get("mir_lower") || "",
    mir_upper: searchParams.get("mir_upper") || "",
    mqr_lower: searchParams.get("mqr_lower") || "",
    mqr_upper: searchParams.get("mqr_upper") || "",
    avg_decrease_lower: searchParams.get("avg_decrease_lower") || "",
    avg_decrease_upper: searchParams.get("avg_decrease_upper") || "",
    tm_mapped: searchParams.get("tm_mapped") === "true",
    not_parking: searchParams.get("not_parking") === "true",

    tm_remaining: searchParams.get("tm_remaining") || "",
    tm_mir: searchParams.get("tm_mir") || "",
    tm_mqr: searchParams.get("tm_mqr") || "",
    tm_decrease_avg: searchParams.get("tm_decrease_avg") || "",
  } as SgFiltersData);

  React.useEffect(() => {
    setFilters(filters);
  }, [filters, setFilters]);

  /**
   * Update the value of a filter
   * @param key<string> The key of the filter
   * @param value<any> The value of the filter
   */
  const updateValue = (key: string, value: any) => {
    // @ts-ignore
    filters[key] = value;
    setFilters({...filters, [key]: value});

    value !== "" ? searchParams.set(key, value) : searchParams.delete(key);
    setSearchParams(searchParams);
  };

  /**
   * Render the component
   * @returns {JSX.Element} The component
   */
  return (
    <Box>
      <Box sx={{display: "flex"}}>
        <Box>
          {/* Event Name */}
          <DefaultInput
            id={"event-name"}
            label={"Event"}
            title={"Get the events with this name"}
            type={"text"}
            value={filters.event_name}
            error={false}
            startSearch={search}
            setValue={(value: string) => {
              updateValue("event_name", value);
            }}
          />
          {/* Event Venue */}
          <DefaultInput
            id={"event-venue"}
            label={"Venue"}
            title={"Get the events at this venue"}
            type={"text"}
            value={filters.event_venue}
            error={false}
            startSearch={search}
            setValue={(value: string) => updateValue("event_venue", value)}
          />
        </Box>
        <Box>
          {/* Event Date */}
          <DefaultInput
            id={"category"}
            label={"Category"}
            title={"Get the events with this category"}
            type={"text"}
            value={filters.category}
            error={false}
            startSearch={search}
            setValue={(value: string) => updateValue("category", value)}
          />
        </Box>

        {/*<Box>*/}
        {/*  /!* Tickets Sold Min *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"tickets-sold-lower"}*/}
        {/*    label={"Tix Sold"}*/}
        {/*    title={"Get the events with at least this many tickets sold"}*/}
        {/*    type={"number"}*/}
        {/*    value={filters.tickets_sold_lower}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) =>*/}
        {/*      updateValue("tickets_sold_lower", value)*/}
        {/*    }*/}
        {/*  />*/}
        {/*  /!* Listings Sold Min *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"listings-sold-lower"}*/}
        {/*    label={"List. Sold"}*/}
        {/*    title={"Get the events with at least this many listings sold"}*/}
        {/*    type={"number"}*/}
        {/*    value={filters.listings_sold_lower}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) =>*/}
        {/*      updateValue("listings_sold_lower", value)*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Box>*/}

        {/*<Box>*/}
        {/*  /!* Sold Yesterday *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"sold-yesterday"}*/}
        {/*    label={"Sold (1)"}*/}
        {/*    title={"Get the events with at least this many sold yesterday"}*/}
        {/*    type={"number"}*/}
        {/*    value={filters.sold_yesterday}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) => updateValue("sold_yesterday", value)}*/}
        {/*  />*/}
        {/*  /!* Sold Last Seven days *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"sold-last-seven"}*/}
        {/*    label={"Sold (7)"}*/}
        {/*    title={*/}
        {/*      "Get the events with at least this many sold in the last seven days"*/}
        {/*    }*/}
        {/*    type={"number"}*/}
        {/*    value={filters.sold_last_seven}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) => updateValue("sold_last_seven", value)}*/}
        {/*  />*/}
        {/*</Box>*/}
        {/*<Box>*/}
        {/*  /!* Sold Last Thirty days *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"sold-last-thirty"}*/}
        {/*    label={"Sold (30)"}*/}
        {/*    title={*/}
        {/*      "Get the events with at least this many sold in the last thirty days"*/}
        {/*    }*/}
        {/*    type={"number"}*/}
        {/*    value={filters.sold_last_thirty}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) => updateValue("sold_last_thirty", value)}*/}
        {/*  />*/}

        {/*  /!* Seatgeek Avg Decrease Min *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"avg-decrease-lower"}*/}
        {/*    label={"Avg Sale/Day"}*/}
        {/*    title={"Get the events with at least this avg of sales per day"}*/}
        {/*    type={"number"}*/}
        {/*    value={filters.avg_decrease_lower}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) =>*/}
        {/*      updateValue("avg_decrease_lower", value)*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Box>*/}
        <Box>
          {/* Event Date Min */}
          <DefaultInput
            id={"event-date-lower"}
            label={"From Date"}
            title={"Get the events that start after this date"}
            type={"date"}
            value={filters.event_date_lower}
            error={filters.event_date_lower > filters.event_date_upper}
            startSearch={search}
            setValue={(value: string) => updateValue("event_date_lower", value)}
          />
          {/* Event Date Max */}
          {/*<DefaultInput*/}
          {/*  id={"event-date-upper"}*/}
          {/*  label={"To Date"}*/}
          {/*  title={"Get the events that start before this date"}*/}
          {/*  type={"date"}*/}
          {/*  value={filters.event_date_upper}*/}
          {/*  error={filters.event_date_lower > filters.event_date_upper}*/}
          {/*  startSearch={search}*/}
          {/*  setValue={(value: string) => updateValue("event_date_upper", value)}*/}
          {/*/>*/}
        </Box>
        {/*<Box>*/}
        {/*  /!* Seatgeek MIR Min *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"mir-lower"}*/}
        {/*    label={"Min Mir"}*/}
        {/*    title={"Get the events with at least this many mir"}*/}
        {/*    type={"number"}*/}
        {/*    value={filters.mir_lower}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) => updateValue("mir_lower", value)}*/}
        {/*  />*/}
        {/*  /!* Seatgeek MIR Max *!/*/}
        {/*  <DefaultInput*/}
        {/*    id={"mir-upper"}*/}
        {/*    label={"Max Mir"}*/}
        {/*    title={"Get the events with at most this many mir"}*/}
        {/*    type={"number"}*/}
        {/*    value={filters.mir_upper}*/}
        {/*    error={false}*/}
        {/*    startSearch={search}*/}
        {/*    setValue={(value: string) => updateValue("mir_upper", value)}*/}
        {/*  />*/}
        {/*</Box>*/}
        <Box>
          {/* Seatgeek MQR Min */}
          <DefaultInput
            id={"mqr-lower"}
            label={"Min Mqr"}
            title={"Get the events with at least this many mqr"}
            type={"number"}
            value={filters.mqr_lower}
            error={false}
            startSearch={search}
            setValue={(value: string) => updateValue("mqr_lower", value)}
            hide={true} // TODO: Remove this when we have the data
          />
          {/* Seatgeek MQR Max */}
          <DefaultInput
            id={"mqr-upper"}
            label={"Max Mqr"}
            title={"Get the events with at most this many mqr"}
            type={"number"}
            value={filters.mqr_upper}
            error={false}
            startSearch={search}
            setValue={(value: string) => updateValue("mqr_upper", value)}
            hide={true} // TODO: Remove this when we have the data
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1,
          }}
        >
          {/* If event is mapped to Ticketmaster */}
          <Button
            sx={{m: 1, minWidth: 0, p: 1, border: 1}}
            id="tm-mapped"
            color="secondary"
            variant={filters.tm_mapped ? "contained" : "outlined"}
            title="Only show venues that have been mapped to TM"
            onClick={() => updateValue("tm_mapped", !filters.tm_mapped)}
          >
            <Typography
              fontSize={10}
              color="text.secondary"
              sx={{position: "absolute", top: 0, left: 3}}
            >
              TM
            </Typography>
            <MappedIcon/>
          </Button>

          <Button
            sx={{m: 1, minWidth: 0, p: 1, border: 1}}
            id="not-parking"
            color="secondary"
            variant={filters.not_parking ? "contained" : "outlined"}
            title="Only show event that are not marked as parking"
            onClick={() => updateValue("not_parking", !filters.not_parking)}
          >
            <CarIcon/>
          </Button>
        </Box>

        {/*<Card sx={{display: "flex"}}>*/}
        {/*  <Box>*/}
        {/*    /!* Ticketmaster Remaining Tickets Min *!/*/}
        {/*    <DefaultInput*/}
        {/*      id={"tm-remaining"}*/}
        {/*      label={"TM Rem."}*/}
        {/*      title={"Get the events with at least this many tm remaining"}*/}
        {/*      type={"number"}*/}
        {/*      value={filters.tm_remaining}*/}
        {/*      error={false}*/}
        {/*      startSearch={search}*/}
        {/*      setValue={(value: string) => updateValue("tm_remaining", value)}*/}
        {/*    />*/}
        {/*    /!* Ticketmaster Avg Decrease Min *!/*/}
        {/*    <DefaultInput*/}
        {/*      id={"tm-decrease-avg"}*/}
        {/*      label={"TM Dec. Avg"}*/}
        {/*      title={*/}
        {/*        "Get the events with at least this much decrease in avg tm sales"*/}
        {/*      }*/}
        {/*      type={"number"}*/}
        {/*      value={filters.tm_decrease_avg}*/}
        {/*      error={false}*/}
        {/*      startSearch={search}*/}
        {/*      setValue={(value: string) =>*/}
        {/*        updateValue("tm_decrease_avg", value)*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*  <Box>*/}
        {/*    /!* Ticketmaster MIR Min *!/*/}
        {/*    <DefaultInput*/}
        {/*      id={"tm-mir"}*/}
        {/*      label={"TM Mir"}*/}
        {/*      title={"Get the events with at least this many tm mir"}*/}
        {/*      type={"number"}*/}
        {/*      value={filters.tm_mir}*/}
        {/*      error={false}*/}
        {/*      startSearch={search}*/}
        {/*      setValue={(value: string) => updateValue("tm_mir", value)}*/}
        {/*    />*/}
        {/*    /!* Ticketmaster MQR Min *!/*/}
        {/*    <DefaultInput*/}
        {/*      id={"tm-mqr"}*/}
        {/*      label={"TM Mqr"}*/}
        {/*      title={"Get the events with at least this many tm mqr"}*/}
        {/*      type={"number"}*/}
        {/*      value={filters.tm_mqr}*/}
        {/*      error={false}*/}
        {/*      startSearch={search}*/}
        {/*      setValue={(value: string) => updateValue("tm_mqr", value)}*/}
        {/*    />*/}
        {/*  </Box>*/}
        {/*</Card>*/}
        <Box>
          <FiltersPreset
            id={"Sg"}
            filters={filters}
            updateValue={updateValue}
          />
        </Box>
      </Box>
    </Box>
  );
}
