// ----- Module -----
import React from "react";
import axios from "axios";

/**
 * The logout page
 * - Logs the user out, then redirects to the login page
 */
const Logout = () => {
  document.title = "Logging out...";

  /**
   * Log the user out by sending a request to the server.
   * The server will clear the session from the database and the cookie.
   * The user will then be redirected to the login page.
   */
  axios({
    method: "post",
    url: "/api/sessions/logout",
  }).then(() => {
    window.location.href = "/login";
  });

  return <div></div>;
};

export default Logout;
