// ----- Packages ----- //
import React, { useState } from "react";
import "moment-timezone";

// ----- MUI ----- //
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

// ----- Components ----- //
import NavMenu from "../components/others/NavMenu";
import TmUpPage from "./Searchs/Ticketmaster/Upcoming/TmUpPage";
import TmPaPage from "./Searchs/Ticketmaster/Past/TmPaPage";
import SgPage from "./Searchs/Seatgeek/SgPage";
import MpvUpPage from "./Searchs/Mpv/MpvUpPage";

// ----- Types ----- //
import { SEARCH_TYPES } from "../Types/Other";
import { useNavigate, useSearchParams } from "react-router-dom";

/**
 * Function to render the search page
 */
function Search(props: { seller: string }) {
  // ----- Others -----
  const [eventType, setEventType] = useState(props.seller);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  document.title = `Search | ${eventType}`;

  // ----- Functions ----- //
  function handleSearchTypeChange(searchType: SEARCH_TYPES, path: string) {
    setEventType(searchType);
    if (searchParams.toString()) setSearchParams("");
    navigate(path);
    document.title = `Search | ${searchType}`;
  }

  /**
   * Return the JSX of the page
   */
  return (
    <div
      className="App"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgba(0, 0, 0, 0.015)",
      }}
    >
      {/* ----- Header ----- */}
      <Box sx={{ display: "flex", borderRight: 1, borderColor: "divider" }}>
        <NavMenu current={"search"} />
        {/* ----- Time frame selector & search button ----- */}
        <FormControl size="small" title="Select the time frame">
          <Select
            sx={{ m: 3, width: 130, position: "absolute" }}
            size="small"
            id="event-type"
            value={eventType}
            onChange={(event: SelectChangeEvent) => {
              switch (event.target.value) {
                case SEARCH_TYPES.TM_ACTIVE:
                  handleSearchTypeChange(SEARCH_TYPES.TM_ACTIVE, "/tm/active");
                  break;
                case SEARCH_TYPES.TM_PAST:
                  handleSearchTypeChange(SEARCH_TYPES.TM_PAST, "/tm/past");
                  break;
                case SEARCH_TYPES.VIA_ACTIVE:
                  handleSearchTypeChange(SEARCH_TYPES.VIA_ACTIVE, "/via");
                  break;
                case SEARCH_TYPES.SG_ACTIVE:
                  handleSearchTypeChange(SEARCH_TYPES.SG_ACTIVE, "/sg");
                  break;
                case SEARCH_TYPES.SG_VIA_ACTIVE:
                  handleSearchTypeChange(SEARCH_TYPES.SG_VIA_ACTIVE, "/sg-via");
                  break;
                case SEARCH_TYPES.MPV:
                  handleSearchTypeChange(SEARCH_TYPES.MPV, "/mpv");
                  break;
              }
            }}
          >
            <MenuItem value={SEARCH_TYPES.TM_ACTIVE}>TM | Active</MenuItem>
            <MenuItem value={SEARCH_TYPES.TM_PAST}>TM | Past</MenuItem>
            <MenuItem value={SEARCH_TYPES.SG_ACTIVE}>Seatgeek</MenuItem>
            <MenuItem value={SEARCH_TYPES.MPV}>MPV</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {eventType === SEARCH_TYPES.TM_ACTIVE && <TmUpPage />}
      {eventType === SEARCH_TYPES.TM_PAST && <TmPaPage />}
      {eventType === SEARCH_TYPES.SG_ACTIVE && <SgPage />}
      {eventType === SEARCH_TYPES.MPV && <MpvUpPage />}
    </div>
  );
}

export default Search;
