// ----- Packages -----
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

// ----- MUI -----
import { Box, Card, Typography } from "@mui/material";

// ----- Components -----
import SeatgeekSalesGraph from "../../components/event/details/tabs/SeatgeekSales";
import NavMenu from "../../components/others/NavMenu";

/**
 * This component is used to display all the details of a Seatgeek event
 * - Show the event details and different graphs
 * @constructor Sg - The Seatgeek event details page
 */
const Sg = () => {
  let { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = React.useState<any>([]);

  const url = "https://api.plessinc.com/api/event/view/seatgeek/";

  useEffect(() => {
    document.title = "Loading...";
    fetch(url + eventId)
      .then((response) => response.json())
      .then((data) => {
        document.title = data.event.name;
        setEvent(data?.event);
      });
  }, [eventId]);

  return (
    <Box>
      <Card
        sx={{
          m: 3,
          px: 2,
          py: 1,
          width: "50%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">
          <a
            href={event.url}
            target="_blank"
            rel="noreferrer"
            style={{ color: "inherit" }}
          >
            {event.name}
          </a>
        </Typography>
        <Typography variant="h5" color={"text.secondary"}>
          {event.venue?.name}
        </Typography>
        <Typography variant="h5">
          {moment.tz(event.event_date, event.timezone).format("DD/MM/YYYY")}
        </Typography>
      </Card>
      <NavMenu />
      <SeatgeekSalesGraph seatgeekId={eventId || ""} />
    </Box>
  );
};

export default Sg;
