// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { Box } from "@mui/material";

// ----- Components ----- //
import DropCheckModal from "../modals/DropCheck";
import ThresholdAlertModal from "../modals/ThresholdAlert";
import InfoModal from "../modals/Info";

// ----- Types ----- //
import { TmEvent } from "../../Types/Tm";
import Matching from "../modals/Matching";

/**
 * The available actions for an event.
 * @param props<{ event: TmEvent }> - The event to display the actions for.
 */
export default function EventActions(props: { event: TmEvent }) {
  const event: TmEvent = props.event;

  // ----- Render ----- //
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        py: 1,
      }}
    >
      <DropCheckModal eventId={event.url_id} isWatched={event.isWatched}/>
      <ThresholdAlertModal
        eventId={event.url_id}
        remaining={event.tm_remaining}
        remaining_threshold={event.tm_remaining_threshold}
        mir={event.tm_mir}
        mir_threshold={event.tm_mir_threshold}
      />
      <InfoModal eventId={event.url_id}/>
      <Matching eventId={event.url_id}/>
    </Box>
  );
}
