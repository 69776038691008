import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import { Box, Card, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";

const Colors = {
  TM_2024: "rgba(0,122,235)",
  TM_2023: "rgb(138,237,88)",
  TM_RESALE_2024: "rgba(208,0,111)",
  TM_RESALE_2023: "rgb(168,50,207)",
};

const UsOpenGraph = () => {
  let {id2024, id2023} = useParams<{ id2024: string, id2023: string }>();

  // ----- State -----
  const [isLoading, setIsLoading] = useState(true);
  const [mergedData, setMergedData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const data2024 = await fetchData(id2024 || "");
      const data2023 = await fetchData(id2023 || "");

      const merged = mergeData(data2024, data2023);
      // @ts-ignore
      setMergedData(merged);

      setIsLoading(false);
    };
    fetch();
  }, [id2024, id2023]);

  const fetchData = async (id: string) => {
    const response = await axios(`/api/counters/${id}`);
    response.data.forEach((d: any) => {
      d.creationDate = moment(d.creationDate).format("2024-MM-DD");
      d.totalTicketsSg = 0;
    });

    const data = response.data;
    return data.filter((d: any) => moment(d.creationDate).isValid());
  };

  const mergeData = (data2024: any[], data2023: any[]) => {
    const merged = {};

    data2024.forEach((d: any) => {
      const date = d.creationDate;
      // @ts-ignore
      if (!merged[date]) {
        // @ts-ignore
        merged[date] = {date, totalTickets2024: d.totalTickets, totalTicketsResale2024: d.totalTicketsResale};
      } else {
        // @ts-ignore
        merged[date].totalTickets2024 = d.totalTickets;
        // @ts-ignore
        merged[date].totalTicketsResale2024 = d.totalTicketsResale;
      }
    });

    data2023.forEach((d: any) => {
      const date = d.creationDate;
      // @ts-ignore
      if (!merged[date]) {
        // @ts-ignore
        merged[date] = {date, totalTickets2023: d.totalTickets, totalTicketsResale2023: d.totalTicketsResale};
      } else {
        // @ts-ignore
        merged[date].totalTickets2023 = d.totalTickets;
        // @ts-ignore
        merged[date].totalTicketsResale2023 = d.totalTicketsResale;
      }
    });

    return Object.values(merged);
  };

  // ----- Data to show in the graph ----- //
  const data = {
    labels: mergedData.map((d: any) => moment(d.date)),
    datasets: [
      {
        fill: false,
        backgroundColor: "rgba(0,122,235,0.4)",
        borderColor: Colors.TM_2024,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM_2024,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "TM - 2024",
        // @ts-ignore
        data: mergedData.map((d) => d.totalTickets2024),
      },
      {
        fill: false,
        backgroundColor: "rgba(208,0,111,0.4)",
        borderColor: Colors.TM_RESALE_2024,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM_RESALE_2024,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "RS - 2024",
        // @ts-ignore
        data: mergedData.map((d) => d.totalTicketsResale2024),
      },
      {
        fill: false,
        backgroundColor: "rgba(0,122,235,0.4)",
        borderColor: Colors.TM_2023,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM_2023,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "TM - 2023",
        // @ts-ignore
        data: mergedData.map((d) => d.totalTickets2023),
      },
      {
        fill: false,
        backgroundColor: "rgba(208,0,111,0.4)",
        borderColor: Colors.TM_RESALE_2023,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: Colors.TM_RESALE_2023,
        pointRadius: 5,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        label: "RS - 2023",
        // @ts-ignore
        data: mergedData.map((d) => d.totalTicketsResale2023),
      },

    ],
  };

  // ----- Options for the graph ----- //
  let options = {
    // don't show tooltips if name is hidden
    tooltips: {
      mode: "index",
      intersect: false
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          distribution: "linear",
          time: {
            tooltipFormat: "YYYY MMMM DD",
            unit: "day",
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: "Day",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          id: "y-axis-1",
          scaleLabel: {
            display: true,
            labelString: "Primary",
            fontColor: "#000000",
            fontSize: 14,
          },
        },
      ],
    },
  };

  // ----- Render ----- //
  return (
    <Box sx={{width: "100%"}}>
      <Card
        sx={{
          width: "fit-content",
          ml: 4,
          py: 1,
          pl: 2,
          pr: 5,
        }}
      >
        <Typography variant="h5">Listings</Typography>
      </Card>
      <Box sx={{position: "relative", bottom: 10}}>
        <Line type={"line"} data={isLoading ? {} : data} options={options}/>
      </Box>
    </Box>
  );
};

export default UsOpenGraph;

