// ----- Packages ----- //
import React, { memo } from "react";
import axios from "axios";

// ----- MUI ----- //
import { GridColumnGroupingModel, GridColumns, GridRowModel, } from "@mui/x-data-grid-pro";
import GraphViewIcon from "@mui/icons-material/QueryStats";
import { Box, Button } from "@mui/material";

// ----- Types ----- //
import { MpvUpDataGridProps } from "../../../Types/Mpv";
import SearchDataGrid from "../../../components/dataGrids/SearchDataGrid";

/**
 * The data grid component for the upcoming ticketmaster events
 * @param props<TmUpDataGridProps> The props for the data grid
 * @returns The data grid component
 */
export default memo(function MpvUpDataGrid(props: MpvUpDataGridProps) {
  /**
   * Function to update the value of a cell to the database
   * @param params<GridValueSetterParams> The parameters of the cell
   * @param field<string> The name of the field to update
   * @returns boolean True if the update was successful
   *
   * INFO: This function only works for the notes field at the moment
   */
  async function updateValue(
    params: GridRowModel,
    field: string
  ): Promise<boolean> {
    return await axios
      .put(`/api/events/${params.url_id}/notes`, {
        [field]: params[field],
      })
      .then(() => true)
      .catch(() => false);
  }

  /**
   * On row process, update the value in the database
   */
  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve) => {
        updateValue(newRow, "notes").then((res) => {
          if (res) resolve(newRow);
          else resolve(oldRow);
        });
      }),
    []
  );

  // The columns groupings for the data grid. Only used for the active events
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "SeatGeek",
      headerClassName: "sg-header",
      children: [
        {field: "sg_tickets_sold"},
        {field: "sg_sold_1"},
        {field: "sg_sold_7"},
        {field: "sg_sold_30"}
      ],
    },
    {
      groupId: " ",
      children: [{field: "actions"}],
    },
  ];

  // The default column for the data grid
  const columns: GridColumns = [
    {
      field: "links",
      headerName: "Pages link",
      width: 100,
      pinnable: true,
      sortable: false,
      // Render the links to the ticketmaster and stubhub pages
      renderCell: (params) => {
        return (
          <Box sx={{color: "primary.main", display: "flex"}}>
            <a
              href={params.row.url}
              target="_blank"
              rel="noreferrer"
              style={{color: "inherit"}}
            >
              <Button sx={{minWidth: 0, minHeight: 0}}>MPV</Button>
            </a>

            {params.row.seatgeek_id && (
              <a
                href={"https://seatgeek.com/e/events/" + params.row.viagogo_id}
                target="_blank"
                rel="noreferrer"
                style={{color: "inherit"}}
              >
                <Button sx={{minWidth: 0, ml: 1}}>SG</Button>
              </a>
            )}
          </Box>
        );
      },
    },
    {field: "id", headerName: "ID", width: 100},
    {field: "event_name", headerName: "Event Name"},
    {
      field: "event_date", headerName: "Date",
    },
    {field: "event_time", headerName: "Time"},
    {field: "days_left", headerName: "Days Left"},
    {field: "venue_name", headerName: "Venue"},
    {field: "venue_city", headerName: "City"},
    {field: "venue_state", headerName: "State"},
    //{field: "days_left", headerName: "Days Left"},
   // {field: "decrease", headerName: "Decrease"},
    {field: "capacity", headerName: "Capacity"},
    {field: "mpv_remaining", headerName: "Remaining"},
    {field: "availability_change_yesterday", headerName: "Qty Change (1)"},
    {field: "promotions_count", headerName: "Promos"},
    {field: "promtoions_change_yesterday", headerName: "Promos Change (1)"},
    {
      field: "separator-2",
      headerName: "---",
      sortable: false,
      filterable: false,
      width: 0,
      align: "center",
      // Render a vertical line between the ticketmaster and resale columns
      renderCell: () => (
        <Box
          sx={{
            borderLeft: "1px solid",
            borderColor: "grey.300",
            height: "100%",
          }}
        />
      ),
      headerAlign: "center",
    },
    {field: "sg_tickets_sold", headerName: "Sold (Total)"},
    {field: "sg_sold_1", headerName: "Sold (1)"},
    {field: "sg_sold_7", headerName: "Sold (7)"},
    {field: "sg_sold_30", headerName: "Sold (30)"},
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinnable: true,
      sortable: false,
      // Render the buttons to view the graph, set the threshold, watch the event for drops, and view the event info
      renderCell: (params) => {
        return (
          <Box sx={{display: "flex"}}>
            <div>
              <a
                href={`/mpv/${params.row.id}`}
                target="_blank"
                rel="noreferrer"
                style={{color: "inherit"}}
              >
                <Button style={{minWidth: "10px"}}>
                  <GraphViewIcon/>
                </Button>
              </a>
            </div>
          </Box>
        );
      },
    },
  ];

  // The data grid component fully configured and filled with the data
  return (
    <SearchDataGrid
      id={"MPV_UP"}
      data={props.events}
      loading={props.loading}
      firstLoad={props.firstLoad}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      processRowUpdate={processRowUpdate as any}
    />
  );
});
