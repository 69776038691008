// ----- Packages ----- //
import { Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// ----- MUI ----- //
import {
  Box,
  Card,
  Divider,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import NoDataIcon from "@mui/icons-material/PriorityHigh";
import CalendarIcon from "@mui/icons-material/CalendarToday";

// ----- Components ----- //
import ErrorCard from "../../../others/cards/ErrorCard";

// ----- Types ----- //
import { PreOnSaleData } from "../../../../Types/Other";

/**
 * Displays the presale and on-sale graph for an event
 * @param props{eventId} - The event id
 */
const PreOnSaleGraph = (props: { eventId: string }) => {
  // ----- State ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const [preOnSaleEvents, setPreOnSaleEvents] = useState<PreOnSaleData[]>([]);
  const [index, setIndex] = useState(0);

  const PRESALE_COLOR = "warning";
  const ONSALE_COLOR = "success";

  // Get the data for the presale and on-sale
  useEffect(() => {
    const fetch = async () => {
      const response = await axios(`/api/preOnSale/${props.eventId}`);

      setPreOnSaleEvents(response.data.preOnsale);
    };
    fetch()
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, [props.eventId]);

  const lines = [];

  // Format the data for the graph
  for (let i = 0; i < preOnSaleEvents.length; i++) {
    const dataPoints = preOnSaleEvents[i].graph_data;

    const label = moment(preOnSaleEvents[i].sale_date).format(
      "MM/DD/YYYY | hh:mm a"
    );

    const data = {
      labels: dataPoints.map((d) => d.date),
      datasets: [
        {
          fill: false,
          backgroundColor: "rgba(0,183,141,0.4)",
          borderColor: "rgb(0,183,141)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: "rgb(0,183,141)",
          pointRadius: 5,
          pointBorderWidth: 1,
          pointHoverRadius: 7,
          data: dataPoints.map((d) => d.quantity),
          yAxisID: "y-axis-1",
        },
      ],
    };

    let options = {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            id: "x-axis-1",
            type: "time",
            distribution: "linear",
            time: {
              tooltipFormat: "hh:mm a",
              unit: "minute",
            },
            display: true,
          },
        ],
        yAxes: [
          {
            position: "left",
            id: "y-axis-1",
            ticks: {
              beginAtZero: true,
              userCallback: function (label: any) {
                if (Math.floor(label) === label) return label;
              },
            },
          },
        ],
      },
    };

    lines.push(
      <Box sx={{ width: "65%" }}>
        <Card sx={{ px: 2, py: 1, mb: 3, width: "fit-content" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarIcon
              sx={{ fontSize: 30, p: 0.5, pb: 0.75 }}
              color={
                preOnSaleEvents[i].sale_type === 0
                  ? PRESALE_COLOR
                  : ONSALE_COLOR
              }
            />
            <Typography variant="h6" component="div" fontWeight={600}>
              {preOnSaleEvents[i].sale_type === 0 ? "PreSale" : "OnSale"}
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ ml: 1 }}
              color={"text.secondary"}
            >
              {label}
            </Typography>
          </Box>
        </Card>
        <Line type={"line"} data={data} options={options} />
      </Box>
    );
  }

  /**
   * Handles the change of the pagination
   * @param event<React.ChangeEvent<unknown>> - The event
   * @param value<number> - The value of the pagination
   */
  function handleChange(event: React.ChangeEvent<unknown>, value: number) {
    setIndex(value - 1);
  }

  // ----- Render ----- //
  return error ? (
    <ErrorCard
      details={{
        component: "components/event/graphs/PreOnSale.tsx",
        error: error,
      }}
      show={true}
    />
  ) : (
    <Box
      sx={{
        pt: 1,
        px: 5,
      }}
    >
      {!isLoading && lines.length === 0 ? (
        <Card
          sx={{
            p: 2,
            width: "fit-content",
            display: "flex",
            alignItems: "center",
          }}
        >
          <NoDataIcon sx={{ fontSize: 30 }} color={"warning"} />
          <Typography variant="h6" component="div">
            No PreSale or OnSale Data Available
          </Typography>
        </Card>
      ) : (
        <div>
          <Card sx={{ px: 2, py: 1, width: "fit-content" }}>
            <Stack spacing={2} fontWeight={600}>
              <Pagination
                count={isLoading ? 1 : lines.length}
                disabled={isLoading}
                color="primary"
                onChange={handleChange}
                size={"large"}
              />
            </Stack>
          </Card>
          <Divider sx={{ my: 1 }} />
          {lines[index]}
        </div>
      )}
    </Box>
  );
};

export default PreOnSaleGraph;
