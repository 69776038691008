// ----- Packages ----- //
import React, {memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";

// ----- MUI ----- //
import {Box} from "@mui/material";
import {DataGridPro, GridColumns} from "@mui/x-data-grid-pro";
import OpenIcon from "@mui/icons-material/OpenInNew";

// ----- Components ----- //
import ArtistDropCheckBtn from "../event/artist/DropCheckBtn";

// ----- Types ----- //
import {TmPerformer} from "../../Types/Tm";
import moment from "moment";

/**
 * DataGrid that contains all the artists that are in the drop checker
 * @param props {data: any, loading: boolean} - The rows of the data grid and if it is loading or not
 */
export default memo(function ArtistsDropCheckDataGrid(props: {
  data: any;
  loading: boolean;
}) {
  // ----- State ----- //
  const [data, setData] = useState([] as TmPerformer[]);

  // ----- Effects ----- //
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  // ----- Columns ----- //
  const columns: GridColumns = [
    {
      field: "actions",
      headerName: "",
      width: 50,
      pinnable: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <ArtistDropCheckBtn
            row={params.row}
            callBack={() => setData([...data])}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      pinnable: true,
      type: "string",
    },
    {
      field: "counter",
      headerName: "Event Drops",
      width: 120,
      pinnable: true,
      type: "number",
    },
    {
      field: "lastEventAdded",
      headerName: "Newest Event",
      align: "center",
      headerAlign: "center",
      width: 300,
      pinnable: true,
      type: "string",
      renderCell: (params) => {
        return !params.row.lastEventAdded ? (
          ""
        ) : (
          <Box sx={{display: "flex", gap: 3}}>
            <Link
              to={`/event/${params.row.lastEventAdded}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{color: "inherit", textDecoration: "none"}}
            >
              <Box sx={{display: "flex", alignItems: "center"}}>
                Bowman
                <OpenIcon sx={{fontSize: 15, marginLeft: 0.5}}/>
              </Box>
            </Link>

            <Link
              to={`https://www.ticketmaster.com/event/${params.row.lastEventAdded}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{color: "inherit", textDecoration: "none"}}
            >
              <Box sx={{display: "flex", alignItems: "center"}}>
                Ticketmaster
                <OpenIcon sx={{fontSize: 15, marginLeft: 0.5}}/>
              </Box>
            </Link>
          </Box>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => {
        return params.row.watched
          ? moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm A")
          : null;
      },
    },
  ];

  // The data grid component fully configured and filled with the data
  return (
    <Box sx={{height: "calc(100vh - 140px)", width: "100%"}}>
      <DataGridPro
        getRowId={(row) => row.id}
        rows={data}
        columns={columns}
        density={"compact"}
        loading={props.loading}
        initialState={{
          pinnedColumns: {left: ["actions"]},
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd"
        }
      />
    </Box>
  );
});
