// ----- Packages ----- //
import React, { memo } from "react";

// ----- MUI ----- //
import { GridColumns } from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import GraphViewIcon from "@mui/icons-material/QueryStats";
import RocketIcon from '@mui/icons-material/Rocket';

// ----- Types ----- //
import { TmPaDataGridProps } from "../../../../Types/Tm";
import SearchDataGrid from "../../../../components/dataGrids/SearchDataGrid";

/**
 * The data grid component for the past ticketmaster events
 * @param props<TmPaDataGridProps> The props for the data grid
 * @returns The data grid component
 */
export default memo(function TmPaDataGrid(props: TmPaDataGridProps) {
  // The columns for the data grid
  const columns: GridColumns = [
    {
      field: "links",
      headerName: "Link",
      width: 55,
      pinnable: true,
      sortable: false,
      // Render the link to the ticketmaster event page
      renderCell: (params) => {
        return (
          <Box sx={{color: "primary.main", display: "flex"}}>
            <a
              href={`https://www.ticketmaster.com/event/${params.row.pastEvent.url_id}`}
              target="_blank"
              rel="noreferrer"
              style={{color: "inherit"}}
            >
              <Button sx={{minWidth: 0}}>TM</Button>
            </a>
          </Box>
        );
      },
      valueGetter: (params) => 'https://www.ticketmaster.com/event/' + params.row.pastEvent.url_id
    },
    {
      field: "event_name",
      headerName: "Event name",
      valueGetter: (params) => params.row.pastEvent.event_name,
    },
    {
      field: "event_date",
      headerName: "Date",
      valueGetter: (params) => params.row.pastEvent.event_date,
    },
    {
      field: "venue_name",
      headerName: "Venue",
      valueGetter: (params) => params.row.pastEvent.venue_name,
    },
    {
      field: "last_count",
      headerName: "Last Count",
      valueGetter: (params) => params.row.pastEvent.last_count,
    },
    {
      field: '|',
      headerName: '',
      width: 30,
    },
    {
      field: "current_remaining",
      headerName: "Current Remaining",
      valueGetter: (params) => params.row.currentEvent?.tm_remaining,
    },
    {
      field: "current_avg_decrease",
      headerName: "Current Avg Decrease",
      valueGetter: (params) => params.row.currentEvent?.tm_decrease_avg,
    },
    {
      field: "current_mir",
      headerName: "Current MIR",
      valueGetter: (params) => params.row.currentEvent?.tm_mir,
    },
    {
      field: "current_mqr",
      headerName: "Current MQR",
      valueGetter: (params) => params.row.currentEvent?.tm_mqr,
    },
    {
      field: "current_event_date",
      headerName: "Current Date",
      valueGetter: (params) => params.row.currentEvent?.event_date,
    },
    {
      field: "delta_remaining",
      headerName: "Delta Remaining",
      valueGetter: (params) => {
        const current = params.row.currentEvent?.tm_remaining;
        const past = params.row.pastEvent.last_count_1y;

        if (current && past) return past - current;
        else return null;
      }
    },

    {
      field: "actions",
      headerName: "",
      width: 100,
      pinnable: true,
      sortable: false,
      // Render the link to the graph page
      renderCell: (params) => {
        const onClickViewGraph = () => {
          const id = params.row.pastEvent.url_id;
          window.open(`/event/${id}`, "_blank");
        };

        const onClickViewRockettes = () => {
          const past_id = params.row.pastEvent.url_id;
          const current_id = params.row.currentEvent?.url_id;

          window.open(`/rockettest/${past_id}/${current_id}`, "_blank");
        };

        return (
          <Box sx={{display: "flex"}}>
            <div>
              <Button style={{minWidth: "10px"}} onClick={onClickViewRockettes} disabled={!params.row.currentEvent}>
                <RocketIcon/>
              </Button>
            </div>
            <div>
              <Button style={{minWidth: "10px"}} onClick={onClickViewGraph}>
                <GraphViewIcon/>
              </Button>
            </div>
          </Box>
        );
      },
    },
  ];

  // The data grid component fully configured and filled with the data
  return (
    <SearchDataGrid
      id={"TM_PA"}
      data={props.events}
      loading={props.loading}
      firstLoad={props.firstLoad}
      columns={columns}
      columnGroupingModel={[]}
      processRowUpdate={() => {
      }}
      getRowId={(row) => row.pastEvent.url_id}
    />
  );
});
