// ----- Packages ----- //
import React, { useState } from "react";
import moment from "moment/moment";
import { useSearchParams } from "react-router-dom";

// ----- MUI ----- //
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import ShowIcon from "@mui/icons-material/Visibility";

// ----- Components ----- //
import DefaultInput from "../../../components/inputs/DefaultInput";
import MinMaxRemainingInputs from "../../../components/inputs/MinMaxRemaining";

// ----- Types ----- //
import FiltersPreset from "../../../components/inputs/FiltersPreset";

// ----- Animations ----- //
import { MpvUpFiltersData, MpvUpFiltersProps } from "../../../Types/Mpv";

// ----- US States ----- //
const states = [
  {name: "", code: ""},
  {name: "Alabama", code: "AL"},
  {name: "Alaska", code: "AK"},
  {name: "Arizona", code: "AZ"},
  {name: "Arkansas", code: "AR"},
  {name: "California", code: "CA"},
  {name: "Colorado", code: "CO"},
  {name: "Connecticut", code: "CT"},
  {name: "Delaware", code: "DE"},
  {name: "Florida", code: "FL"},
  {name: "Georgia", code: "GA"},
  {name: "Hawaii", code: "HI"},
  {name: "Idaho", code: "ID"},
  {name: "Illinois", code: "IL"},
  {name: "Indiana", code: "IN"},
  {name: "Iowa", code: "IA"},
  {name: "Kansas", code: "KS"},
  {name: "Kentucky", code: "KY"},
  {name: "Louisiana", code: "LA"},
  {name: "Maine", code: "ME"},
  {name: "Maryland", code: "MD"},
  {name: "Massachusetts", code: "MA"},
  {name: "Michigan", code: "MI"},
  {name: "Minnesota", code: "MN"},
  {name: "Mississippi", code: "MS"},
  {name: "Missouri", code: "MO"},
  {name: "Montana", code: "MT"},
  {name: "Nebraska", code: "NE"},
  {name: "Nevada", code: "NV"},
  {name: "New Hampshire", code: "NH"},
  {name: "New Jersey", code: "NJ"},
  {name: "New Mexico", code: "NM"},
  {name: "New York", code: "NY"},
  {name: "North Carolina", code: "NC"},
  {name: "North Dakota", code: "ND"},
  {name: "Ohio", code: "OH"},
  {name: "Oklahoma", code: "OK"},
  {name: "Oregon", code: "OR"},
  {name: "Pennsylvania", code: "PA"},
  {name: "Rhode Island", code: "RI"},
  {name: "South Carolina", code: "SC"},
  {name: "South Dakota", code: "SD"},
  {name: "Tennessee", code: "TN"},
  {name: "Texas", code: "TX"},
  {name: "Utah", code: "UT"},
  {name: "Vermont", code: "VT"},
  {name: "Virginia", code: "VA"},
  {name: "Washington", code: "WA"},
  {name: "West Virginia", code: "WV"},
  {name: "Wisconsin", code: "WI"},
  {name: "Wyoming", code: "WY"},

  // Canada
  {name: "Alberta", code: "AB"},
  {name: "British Columbia", code: "BC"},
  {name: "Manitoba", code: "MB"},
  {name: "New Brunswick", code: "NB"},
  {name: "Newfoundland and Labrador", code: "NL"},
  {name: "Northwest Territories", code: "NT"},
  {name: "Nova Scotia", code: "NS"},
  {name: "Nunavut", code: "NU"},
  {name: "Ontario", code: "ON"},
  {name: "Prince Edward Island", code: "PE"},
  {name: "Quebec", code: "QC"},
  {name: "Saskatchewan", code: "SK"},
  {name: "Yukon", code: "YT"},
];

/**
 * Function to render all the filters for the upcoming events
 * @param props<TmUpFiltersProps> The props for the filters
 * @returns {JSX.Element} The filters component
 */
export default function MpvUpFilters(props: MpvUpFiltersProps) {
  // ----- Props ----- //
  const {setFilters, search} = props;

  // ----- States ----- //
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeInputs, setActiveInputs] = useState<string[]>([]);
  const [showMaxInputs, setShowMaxInputs] = useState<boolean>(false);

  // ----- Filters ----- //
  const [filters] = useState<MpvUpFiltersData>({
    fromDate:
      searchParams.get("fromDate") ||
      moment().subtract(1, "days").format("YYYY-MM-DD"),
    toDate: searchParams.get("toDate") || moment().format("YYYY-MM-DD"),
    eventDateMin:
      searchParams.get("eventDateMin") || moment().format("YYYY-MM-DD"),
    minDataPoints: searchParams.get("minDataPoints")
      ? parseInt(searchParams.get("minDataPoints") || "7")
      : 7,
    team: searchParams.get("team") || "",
    venue: searchParams.get("venue") || "",
    state: searchParams.get("state") || "",
    minDecrease: searchParams.get("minDecrease")
      ? searchParams.get("minDecrease")
      : "",
    maxDecrease: searchParams.get("maxDecrease")
      ? searchParams.get("maxDecrease")
      : "",
    minAvgDecrease: searchParams.get("minAvgDecrease")
      ? searchParams.get("minAvgDecrease")
      : "",
    maxAvgDecrease: searchParams.get("maxAvgDecrease")
      ? searchParams.get("maxAvgDecrease")
      : "",
    minRemaining: searchParams.get("minRemaining")
      ? searchParams.get("minRemaining")
      : "",
    maxRemaining: searchParams.get("maxRemaining")
      ? searchParams.get("maxRemaining")
      : "",
    minMaxRemainIsNb: searchParams.get("minMaxRemainIsNb")
      ? searchParams.get("minMaxRemainIsNb") === "true"
      : true,
    minSgSells_total: searchParams.get("minSgSells_total") || "",
    minSgSells_1: searchParams.get("minSgSells_1") || "",
    minSgSells_7: searchParams.get("minSgSells_7") || "",
    minSgSells_30: searchParams.get("minSgSells_30") || "",
  } as MpvUpFiltersData);

  /**
   * Function that check it the input is active
   * @param id<string> - The id of the input
   */
  function checkIfDisabled(id: string) {
    if (activeInputs.length > 0) return !activeInputs.includes(id);
    else return false;
  }

  /**
   * Function that set the filters on load
   */
  React.useEffect(() => {
    setFilters(filters);

    // Set the active inputs on load
    if (filters.team !== "" || filters.venue !== "")
      setActiveInputs(["team", "venue"]);
  }, [filters, setFilters]);

  /**
   * Function that update the value of a filter
   * @param key<string> - The key of the filter
   * @param value<any> - The value of the filter
   */
  const updateValue = (key: string, value: any) => {
    // @ts-ignore
    filters[key] = value;
    setFilters({...filters, [key]: value});

    value !== "" ? searchParams.set(key, value) : searchParams.delete(key);
    setSearchParams(searchParams);
  };

  /**
   * Return the component
   * @returns {JSX.Element} - The component
   */
  return (
    <Box>
      <Box sx={{display: "flex"}}>
        <Box>
          {/* From Date */}
          <DefaultInput
            id={"from-date"}
            label={"From Date"}
            title={"Filter all data after this date"}
            type={"date"}
            value={moment(filters.fromDate).format("YYYY-MM-DD")}
            error={filters.fromDate >= filters.toDate}
            startSearch={search}
            setValue={(value: string) =>
              value !== "" && updateValue("fromDate", value)
            }
            disabled={checkIfDisabled("from-date")}
          />
          {/* To Date */}
          <DefaultInput
            id={"to-date"}
            label={"To Date"}
            title={"Filter all data before this date"}
            type={"date"}
            value={moment(filters.toDate).format("YYYY-MM-DD")}
            error={
              moment(filters.fromDate) >= moment(filters.toDate) ||
              moment(filters.toDate) >= moment()
            }
            startSearch={search}
            setValue={(value: string) =>
              value !== "" && updateValue("toDate", value)
            }
            disabled={checkIfDisabled("to-date")}
          />
        </Box>

        <Box>
          {/* Event Date Min */}
          <DefaultInput
            id={"event-date-min"}
            label={"Event Date Min"}
            title={"Minimum event date"}
            type={"date"}
            value={moment(filters.eventDateMin).format("YYYY-MM-DD")}
            error={moment(filters.eventDateMin) < moment().subtract(1, "day")}
            startSearch={search}
            setValue={(value: string) =>
              value !== "" && updateValue("eventDateMin", value)
            }
            disabled={checkIfDisabled("event-date-min")}
          />
        </Box>

        <Box>
          {/* Team */}
          <DefaultInput
            id={"team"}
            label={"Team"}
            title={"The Team name"}
            type={"text"}
            value={filters.team}
            error={filters.team.length > 0 && filters.team.length < 3}
            startSearch={search}
            setValue={(value) => {
              updateValue("team", value);
              if (value !== "" || filters.venue !== "")
                setActiveInputs(["team", "venue"]);
              else if (filters.team === "" && filters.venue === "")
                setActiveInputs([]);
            }}
            disabled={checkIfDisabled("team")}
          />
          {/* Venue */}
          <DefaultInput
            id={"venue"}
            label={"Venue"}
            title={"The venue name"}
            type={"text"}
            value={filters.venue}
            error={filters.venue.length > 0 && filters.venue.length < 3}
            startSearch={search}
            setValue={(value) => {
              updateValue("venue", value);
              if (value !== "" || filters.team !== "")
                setActiveInputs(["team", "venue", "only-with-parking", "min-ds-invoices", "max-ds-invoices"]);
              else if (filters.team === "" && filters.venue === "")
                setActiveInputs([]);
            }}
            disabled={checkIfDisabled("venue")}
          />
        </Box>

        <Box>
          {/* State */}
          <Autocomplete
            id="state"
            disabled={checkIfDisabled("state")}
            autoSelect
            autoHighlight
            size={"small"}
            options={states.map((state) => state.code)}
            sx={{mx: 1, my: 2, width: "100px"}}
            renderInput={(params) => <TextField {...params} label="State"/>}
            filterOptions={(options, {inputValue}) => {
              return options.filter((option) => {
                const stateName = states
                  .find((state) => state.code === option)
                  ?.name.toLowerCase();
                const stateCode = option.toLowerCase();
                const input = inputValue.toLowerCase();
                return stateName?.includes(input) || stateCode?.includes(input);
              });
            }}
            renderOption={(props, option) => [
              <li {...props}>
                {option}-
                <small>
                  {states.find((state) => state.code === option)?.name}
                </small>
              </li>,
            ]}
            value={filters.state}
            onChange={(event, newValue) => {
              if (newValue !== null) updateValue("state", newValue);
              else updateValue("state", "");
            }}
          />
          {/* Min Data Points */}
          <DefaultInput
            id={"min-data-points"}
            label={"Data Points"}
            title={"Minimum number of data points"}
            type={"number"}
            value={filters.minDataPoints}
            error={filters.minDataPoints < 0}
            startSearch={search}
            setValue={(value: string) => updateValue("minDataPoints", value)}
            disabled={checkIfDisabled("min-data-points")}
          />
        </Box>

        {/* Inputs that are applied on seller stats [tm, rs] */}
        <Card sx={{display: "flex"}}>
          <Box>
            <Box sx={{display: "flex", flexDirection: "row"}}>
              {/* Seller */}
              <FormControl
                sx={{
                  mx: 1,
                  mt: 2,
                  width: "100px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {borderColor: "secondary.main"},
                    "&:hover fieldset": {borderColor: "secondary.main"},
                    "&.Mui-focused fieldset": {borderColor: "secondary.main"},
                  },
                }}
                size="small"
                title="Apply filters on stats of..."
                disabled={checkIfDisabled("search-type")}
              >
                <InputLabel id="search-type-label">Filter on</InputLabel>
                <Select
                  id="search-type"
                  fullWidth
                  labelId="search-type-label"
                  value={filters.statsFor}
                  label="Filter on"
                  onChange={(event: SelectChangeEvent) => {
                    updateValue("statsFor", event.target.value);
                  }}
                >
                  <MenuItem value={"mpv"}>Primary</MenuItem>
                </Select>
              </FormControl>

              {/* Show Max input */}
              <Button
                sx={{minWidth: 0, p: 1, border: 1, marginTop: 2, bottom: 1}}
                disabled={checkIfDisabled("show-max-input")}
                id="show-max-input"
                color="primary"
                variant={showMaxInputs ? "contained" : "outlined"}
                title="Show max inputs"
                onClick={() => {
                  setShowMaxInputs(!showMaxInputs);
                }}
              >
                <Typography
                  fontSize={10}
                  color="text.secondary"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                  }}
                >
                  MAX
                </Typography>
                <ShowIcon/>
              </Button>
            </Box>
          </Box>

          <Box sx={{display: showMaxInputs ? "flex" : "block"}}>
            <Box>
              {/* Min decrease */}
              <DefaultInput
                id={"min-decrease"}
                label={showMaxInputs ? "Min Dec. *" : "Decrease *"}
                title={
                  "Minimum tickets quantity decrease between the FROM and TO dates"
                }
                type={"number"}
                value={filters.minDecrease}
                error={
                  filters.minDecrease !== "" &&
                  filters.maxDecrease !== "" &&
                  filters.minDecrease > filters.maxDecrease
                }
                startSearch={search}
                setValue={(value: string) => updateValue("minDecrease", value)}
                disabled={checkIfDisabled("min-decrease")}
              />
              {/* Max decrease */}
              <DefaultInput
                id={"max-decrease"}
                label={"Max Dec. *"}
                title={
                  "Maximum tickets quantity decrease between the FROM and TO dates"
                }
                type={"number"}
                value={filters.maxDecrease}
                error={
                  filters.minDecrease !== "" &&
                  filters.maxDecrease !== "" &&
                  filters.minDecrease > filters.maxDecrease
                }
                startSearch={search}
                setValue={(value: string) => updateValue("maxDecrease", value)}
                disabled={checkIfDisabled("max-decrease")}
                hide={!showMaxInputs}
              />
            </Box>

            <Box>
              {/* Min Avg Decrease */}
              <DefaultInput
                id={"min-avg-decrease"}
                label={`${showMaxInputs ? "Min " : ""}Dec. Avg`}
                title={
                  "Minimum tickets quantity decrease average from the last 7 days"
                }
                type={"number"}
                value={filters.minAvgDecrease}
                error={
                  filters.minAvgDecrease !== "" &&
                  filters.maxAvgDecrease !== "" &&
                  filters.minAvgDecrease > filters.maxAvgDecrease
                }
                startSearch={search}
                setValue={(value: string) =>
                  updateValue("minAvgDecrease", value)
                }
                disabled={checkIfDisabled("min-avg-decrease")}
              />
              {/* Max Avg Decrease */}
              <DefaultInput
                id={"max-avg-decrease"}
                label={"Max Decrease Avg"}
                title={
                  "Maximum tickets quantity decrease average from the last 7 days"
                }
                type={"number"}
                value={filters.maxAvgDecrease}
                error={
                  filters.minAvgDecrease !== "" &&
                  filters.maxAvgDecrease !== "" &&
                  filters.minAvgDecrease > filters.maxAvgDecrease
                }
                startSearch={search}
                setValue={(value: string) =>
                  updateValue("maxAvgDecrease", value)
                }
                disabled={checkIfDisabled("max-avg-decrease")}
                hide={!showMaxInputs}
              />
            </Box>
          </Box>

          <Box sx={{display: showMaxInputs ? "flex" : "block"}}>
            <Box>
              {/* Min/Max Remaining */}
              <MinMaxRemainingInputs
                minRemaining={filters.minRemaining}
                maxRemaining={filters.maxRemaining}
                setMinRemaining={(value: number | string) =>
                  updateValue("minRemaining", value)
                }
                setMaxRemaining={(value: number | string) =>
                  updateValue("maxRemaining", value)
                }
                minMaxRemainIsNb={filters.minMaxRemainIsNb}
                setMinMaxIsNb={() =>
                  updateValue("minMaxRemainIsNb", !filters.minMaxRemainIsNb)
                }
                checkIfDisabled={checkIfDisabled}
                startSearch={search}
                hideMax={!showMaxInputs}
              />
            </Box>
          </Box>
        </Card>

        <Card sx={{display: "flex", ml: 5, position: "relative", overflow: "visible"}}>
          <Typography variant="h6" sx={{position: "absolute", left: -50, top: 30, transform: "rotate(-90deg)"}}>
            SG Sells
          </Typography>

          <Box sx={{display: "flex"}}>
            <Box>
              {/* SG Sells Total */}
              <DefaultInput
                id={"min-sg-sells-total"}
                label={"Total"}
                title={"Minimum tickets quantity sold from the last 7 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_total}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_total", value)}
                disabled={checkIfDisabled("min-sg-sells-total")}
              />


              {/* Sg Sells 1 day */}
              <DefaultInput
                id={"min-sg-sells-1"}
                label={"1 day"}
                title={"Minimum tickets quantity sold from the last 1 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_1}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_1", value)}
                disabled={checkIfDisabled("min-sg-sells-1")}
              />
            </Box>

            <Box>
              {/* Sg Sells 7 days */}
              <DefaultInput
                id={"min-sg-sells-7"}
                label={"7 days"}
                title={"Minimum tickets quantity sold from the last 7 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_7}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_7", value)}
                disabled={checkIfDisabled("min-sg-sells-7")}
              />

              {/* Sg Sells 30 days */}
              <DefaultInput
                id={"min-sg-sells-30"}
                label={"30 days"}
                title={"Minimum tickets quantity sold from the last 30 days on SeatGeek"}
                type={"number"}
                value={filters.minSgSells_30}
                startSearch={search}
                setValue={(value: string) => updateValue("minSgSells_30", value)}
                disabled={checkIfDisabled("min-sg-sells-30")}
              />
            </Box>
          </Box>

        </Card>


        <Box>
          <FiltersPreset
            id={"MpvUp"}
            filters={filters}
            updateValue={updateValue}
          />
        </Box>
      </Box>
    </Box>
  );
}
