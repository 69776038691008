// ----- Packages -----
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// ----- MUI -----
import { Box, Typography } from "@mui/material";

// ----- AG-GRID -----
import { DataGridPro } from "@mui/x-data-grid-pro";
import { maxWidth } from "@mui/system";


interface DetailedSectionData {
  id: string;
  full_name: string;
  available: number;
  capacity: number;
  max_contiguous: number;
  ref_price: number;
  buyer_type_id: string;
  code: string;
  description: string;
  price: number;
}

/**
 * Displays the seatgeek sales graph for an event
 * @param props{eventId} - The event id
 */
const MpvDetailedSections = (props: { eventId: string }) => {
  // ----- State -----
  const [isLoading, setIsLoading] = useState(true);

  // ----- Data -----
  const [sectionData, setSectionData] = useState<DetailedSectionData[]>([]);

  /**
   * Fetches the detailed section availability data from the API
   */
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await axios
      .get(`/api/mpv/sectiondata/${props.eventId}`)
      .then((res) => {
        console.log(res.data);
        setSectionData(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [props.eventId]);

  // ----- Effects -----
  useEffect(() => {
    const fetch = async () => {
      await fetchData().then(() => setIsLoading(false));
    };

    if (!props.eventId) return;

    fetch().then();
  }, [props.eventId, fetchData]);

  function formatMoney(
    amount: number,
    currencySymbol: string = "$",
    locale: string = "en-US"
  ): string {
    return amount.toLocaleString(locale, {
      style: "currency",
      currency: currencySymbol,
    });
  }

  // ----- DataGrid Columns -----
  const sectionColumns = [
    {
      field: "full_name",
      headerName: "Section",
      width: 300,
    },
    {
      field: "available",
      headerName: "Available",
    },
    {
      field: "capacity",
      headerName: "Capacity",
    },
    {
      field: "max_contiguous",
      headerName: "Max Contiguous",
      maxWidth: 100,
    },
    {
      field: "ref_price",
      headerName: "Ref Price",
      maxWidth: 100,
    },
    {
      field: "code",
      headerName: "Code",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "price",
      headerName: "Price",
      maxWidth: 100,
    },
  ] as any;



  // ----- Render -----
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <Box sx={{ py: 1, px: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box sx={{ width: 1250, p: 1, height: 800 }}>
            <Typography variant="h5">Section Availability & Pricing</Typography>
            <DataGridPro
              getRowId={(row) => `${row.id}-${row.buyer_type_id}-${row.ref_price}`}
              sx={{ height: 900 }}
            rows={sectionData}
            columns={sectionColumns}
            />
        </Box>
      </Box>
    </Box>
  );
};

export default MpvDetailedSections;
