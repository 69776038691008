import React from "react";

// ----- MUI ----- //
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import HideInfoIcon from "@mui/icons-material/ArrowDropDown";
import ShowInfoIcon from "@mui/icons-material/ArrowRight";
import DismissIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import CopyIcon from "@mui/icons-material/ContentCopy";

// ----- Types ----- //
import { ErrorDetails } from "../../../Types/Other";

// ----- Other ----- //
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Component that renders a card with an error message
 * @param props<{msg: string, show: boolean}> - props for the component
 * - msg<string> - The error message to display
 * - show<boolean> - If the error card should be shown or not
 * - dismissible<boolean> - If the error card should be dismissible or not
 * @returns {JSX.Element} - The component
 */
export default function ErrorCard(props: {
  details: ErrorDetails;
  show: boolean;
  dismissible?: boolean;
}) {
  const [showMore, setShowMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDismiss = () => {
    setShowMore(false);
    // Find a way to dismiss the error card
    // props.show = false; - This doesn't work because props are immutable
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  /**
   * Return the component
   */
  return (
    <Box sx={{ display: props.show ? "block" : "none" }}>
      <Box sx={{ display: "flex" }}>
        <Card sx={{ p: 2, m: 1, width: "400px" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ErrorIcon color="error" sx={{ mr: 0.5, fontSize: "2rem" }} />
            <Typography variant="h5" color="error">
              An error has occurred
            </Typography>
            {props.dismissible && (
              <Button sx={{ minWidth: 0, p: 0 }} onClick={handleDismiss}>
                <DismissIcon
                  fontSize={"small"}
                  sx={{ color: "text.secondary" }}
                />
              </Button>
            )}
          </Box>

          <Divider sx={{ my: 1 }} />
          <Typography variant="body1">
            Something went wrong while loading the page.
            <br />
            Please try again later.
          </Typography>
          <Button sx={{ textTransform: "none", p: 0, minWidth: 0 }}>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                cursor: "pointer",
                display: props.details ? "flex" : "none",
                alignItems: "center",
              }}
              onClick={() => setShowMore(!showMore)}
            >
              Click here to view the error details{" "}
              {showMore ? <HideInfoIcon /> : <ShowInfoIcon />}
            </Typography>
          </Button>

          <Collapse in={showMore}>
            <Box
              sx={{
                position: "relative", // add a transition on open/close
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              <Box
                bgcolor="background.default"
                sx={{
                  p: 1,
                  mt: 1,
                  borderRadius: 1,
                  border: 1,
                  borderColor: "divider",
                  overflowX: "auto",
                  maxHeight: "500px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  component="pre"
                >
                  {JSON.stringify(props.details, null, 2)}
                </Typography>
              </Box>

              <CopyIcon
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 20,
                  zIndex: 1,
                  cursor: "pointer",
                  backgroundColor: "rgba(150, 150, 150, 0.2)",
                  borderRadius: 1,
                  p: 0.5,
                }}
                onClick={() => {
                  navigator.clipboard
                    .writeText(JSON.stringify(props.details, null, 2))
                    .then(() => setOpen(true));
                }}
              />
            </Box>
          </Collapse>
        </Card>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Error details copied to clipboard
        </Alert>
      </Snackbar>
    </Box>
  );
}
