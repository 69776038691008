// ----- Packages ----- //
import axios from "axios";
import { DateTime } from "luxon";
import { Bar } from "react-chartjs-2";
import React, { memo, useEffect, useState } from "react";

// ----- MUI ----- //
import { Box, Card, Divider, Skeleton, Typography } from "@mui/material";
import SaleIcon from "@mui/icons-material/Receipt";
import InventoryIcon from "@mui/icons-material/Inventory";
import WorkIcon from "@mui/icons-material/Work";

/**
 * Displays the ticketaria graph for an event (sale and inventory)
 * @param props{eventId} - The event id
 */
const TicketariaGraph = memo((props: { eventId: string }) => {
  // ----- State ----- //
  const [isLoading, setIsLoading] = useState(true);
  const [saleDataPoints, setSaleDataPoints] = useState<any[]>([]);
  const [inventoryDataPoints, setInventoryDataPoints] = useState<any[]>([]);
  const [stats, setStats] = useState<any>({});

  // ----- Effects ----- //
  useEffect(() => {
    setIsLoading(true);
    if (!props.eventId) {
      setIsLoading(false);
      return;
    }

    // Get the data for the sale and inventory graphs
    const fetch = async () => {
      const responseSale = await axios(`/api/skybox/${props.eventId}/sale`);
      setSaleDataPoints(responseSale.data);

      const responseInventory = await axios(
        `/api/skybox/${props.eventId}/inventory`
      );

      responseInventory.data.forEach((d: any) => {
        d.totalTickets = parseInt(d.totalTickets);
      });

      setInventoryDataPoints(responseInventory.data);

      const responseStats = await axios(`/api/skybox/${props.eventId}/stats`);
      setStats(responseStats.data);
    };
    fetch().then(() => {
      setIsLoading(false);
    });
  }, [props.eventId]);

  // Data to show in the graph (sale)
  const dataSale = {
    // @ts-ignore
    labels: saleDataPoints.map((d) => DateTime.fromISO(d.date).toJSDate()),
    datasets: [
      {
        label: "Sale",
        data: saleDataPoints.map((d) => d.qty),
        backgroundColor: "rgba(255,132,51,0.8)",
      },
    ],
  };

  // Options for the graph (sale)
  let optionsSale = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    legend: {display: false},
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          time: {
            tooltipFormat: "MMMM DD",
            unit: "day",
          },
        },
      ],
    },
  };

  // Data to show in the graph (inventory)
  const dataInventory = {
    labels: inventoryDataPoints.map((d) => DateTime.fromISO(d.date).toJSDate()),
    datasets: [
      {
        label: "Active Inventory",
        data: inventoryDataPoints.map((d) => d.qty),
        backgroundColor: "rgba(55,110,255,0.8)",
        datalabels: {display: false},
      },
    ],
  };

  // Options for the graph (inventory)
  let optionsInventory = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    legend: {display: false},
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            userCallback: function (label: any) {
              if (Math.floor(label) === label) return label;
            },
          },
        },
      ],
      xAxes: [
        {
          id: "x-axis-1",
          type: "time",
          time: {
            tooltipFormat: "MMMM DD",
            unit: "day",
          },
        },
      ],
    },
  };

  // ----- Render ----- //
  return isLoading ? (
    <Skeleton width={800} height={165} variant="rounded"/>
  ) : saleDataPoints.length <= 0 && inventoryDataPoints.length <= 0 ? (
    <Card sx={{p: 1}}>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InventoryIcon sx={{position: "relative", top: -1, fontSize: 20}}/>
        {!props.eventId
          ? "No SkyBox ID could be found for this event"
          : "No sales/inventory data available"}
      </Typography>
    </Card>
  ) : (
    <Card sx={{p: 1, width: 800, height: 165}}>
      <Box sx={{display: "flex"}}>
        <Box sx={{pr: 1}}>
          <Typography
            sx={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WorkIcon sx={{position: "relative", top: -2, fontSize: 20}}/>
            SkyBox
          </Typography>

          <Divider orientation={"horizontal"} flexItem={true}/>
          <Box
            sx={{pt: 1, display: "flex", flexDirection: "column", gap: 1.5}}
          >
            <span>
              <strong style={{color: "gray"}}>Tix Sold:</strong>{" "}
              {stats.totalTickets}
            </span>
            <span>
              <strong style={{color: "gray"}}>Profit:</strong> $
              {stats.totalProfit}
            </span>
            <span>
              <strong style={{color: "gray"}}>ROI:</strong> {stats.totalRoi}%
            </span>
          </Box>
        </Box>

        <Divider orientation={"vertical"} flexItem={true}/>

        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              pr: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              color="text.secondary"
              sx={{
                ml: 4,
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SaleIcon sx={{fontSize: 20}}/>
              Sales
            </Typography>
            <Bar data={dataSale} options={optionsSale} height={135}/>
          </Box>
          <Divider orientation={"vertical"} flexItem={true}/>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography
              color="text.secondary"
              sx={{
                ml: 4,
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InventoryIcon sx={{fontSize: 20}}/>
              Inventory
            </Typography>
            <Bar data={dataInventory} options={optionsInventory} height={135}/>
          </Box>
        </Box>
      </Box>
    </Card>
  );
});

export default TicketariaGraph;
