// ----- Packages ----- //
import React, { memo } from "react";
import axios from "axios";

// ----- MUI ----- //
import { GridColumnGroupingModel, GridColumns, GridRowModel, } from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import GraphViewIcon from "@mui/icons-material/QueryStats";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

// ----- Components ----- //
import DropCheckModal from "../../../../components/modals/DropCheck";
import ThresholdAlertModal from "../../../../components/modals/ThresholdAlert";
import InfoModal from "../../../../components/modals/Info";

// ----- Types ----- //
import { TmUpDataGridProps } from "../../../../Types/Tm";
import SearchDataGrid from "../../../../components/dataGrids/SearchDataGrid";

/**
 * The data grid component for the upcoming ticketmaster events
 * @param props<TmUpDataGridProps> The props for the data grid
 * @returns The data grid component
 */
export default memo(function TmUpDataGrid(props: TmUpDataGridProps) {
  /**
   * Function to update the value of a cell to the database
   * @param params<GridValueSetterParams> The parameters of the cell
   * @param field<string> The name of the field to update
   * @returns boolean True if the update was successful
   *
   * INFO: This function only works for the notes field at the moment
   */
  async function updateValue(
    params: GridRowModel,
    field: string
  ): Promise<boolean> {
    return await axios
      .put(`/api/events/${params.url_id}/notes`, {
        [field]: params[field],
      })
      .then(() => true)
      .catch(() => false);
  }

  /**
   * On row process, update the value in the database
   */
  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>((resolve) => {
        updateValue(newRow, "notes").then((res) => {
          if (res) resolve(newRow);
          else resolve(oldRow);
        });
      }),
    []
  );

  // The columns groupings for the data grid. Only used for the active events
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "TicketMaster",
      headerClassName: "tm-header",
      children: [
        {field: "tm_decrease_avg"},
        {field: "tm_remaining"},
        {field: "tm_mir"},
        {field: "tm_mqr"},
        {field: "tm_last_ping"},
      ],
    },
    {
      groupId: "Resale",
      headerClassName: "rs-header",
      children: [
        {field: "rs_decrease_avg"},
        {field: "rs_remaining"},
        {field: "rs_mir"},
      ],
    },
    {
      groupId: "SeatGeek",
      headerClassName: "sg-header",
      children: [
        {field: "sg_tickets_sold"},
        {field: "sg_sold_1"},
        {field: "sg_sold_7"},
        {field: "sg_sold_30"}
      ],
    },
    {
      groupId: " ",
      children: [{field: "actions"}],
    },
  ];

  // The default column for the data grid
  const columns: GridColumns = [
    {
      field: "links",
      headerName: "Pages link",
      width: 100,
      pinnable: true,
      sortable: false,
      // Render the links to the ticketmaster and stubhub pages
      renderCell: (params) => {
        return (
          <Box sx={{color: "primary.main", display: "flex"}}>
            <a
              href={`https://www.ticketmaster.com/event/${params.row.url_id}`}
              target="_blank"
              rel="noreferrer"
              style={{color: "inherit"}}
            >
              <Button sx={{minWidth: 0, minHeight: 0}}>TM</Button>
            </a>

            {params.row.viagogo_id && (
              <a
                href={"https://www.stubhub.com/event/" + params.row.viagogo_id}
                target="_blank"
                rel="noreferrer"
                style={{color: "inherit"}}
              >
                <Button sx={{minWidth: 0, ml: 1}}>SH</Button>
              </a>
            )}
          </Box>
        );
      },
    },
    {field: "event_name", headerName: "Event Name"},
    {field: "category", headerName: "Category"},
    {
      field: "week_day",
      headerName: "WD",
    },
    {
      field: "event_date", headerName: "Date",
    },
    {field: "state", headerName: "State"},
    {field: "venue_name", headerName: "Venue"},
    {field: "city", headerName: "City"},
    {field: "days_left", headerName: "Days Left"},
    {field: "decrease", headerName: "Decrease"},
    {field: "capacity", headerName: "Capacity"},
    {field: "tm_remaining", headerName: "Remaining"},
    {field: "tm_decrease_avg", headerName: "DEC. AVG"},
    {field: "tm_mir", headerName: "MIR"},
    {field: "tm_mqr", headerName: "MQR"},
    {field: "tm_last_ping", headerName: "Last Update"},
    {
      field: "separator",
      headerName: "---",
      sortable: false,
      filterable: false,
      width: 0,
      align: "center",
      // Render a vertical line between the ticketmaster and resale columns
      renderCell: () => (
        <Box
          sx={{
            borderLeft: "1px solid",
            borderColor: "grey.300",
            height: "100%",
          }}
        />
      ),
      headerAlign: "center",
    },
    {field: "rs_remaining", headerName: "Remaining"},
    {field: "rs_decrease_avg", headerName: "DEC. AVG"},
    {field: "rs_mir", headerName: "MIR"},
    {
      field: "separator-2",
      headerName: "---",
      sortable: false,
      filterable: false,
      width: 0,
      align: "center",
      // Render a vertical line between the ticketmaster and resale columns
      renderCell: () => (
        <Box
          sx={{
            borderLeft: "1px solid",
            borderColor: "grey.300",
            height: "100%",
          }}
        />
      ),
      headerAlign: "center",
    },
    {field: "sg_tickets_sold", headerName: "Sold (Total)"},
    {field: "sg_sold_1", headerName: "Sold (1)"},
    {field: "sg_sold_7", headerName: "Sold (7)"},
    {field: "sg_sold_30", headerName: "Sold (30)"},


    {
      field: "in_ds",
      headerName: "In DS",
      // Render a check or cross depending on the value of the field (red=not in DS, green=in DS)
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            {params.value ? (
              <CheckIcon style={{color: "green"}}/>
            ) : (
              <ClearIcon style={{color: "red"}}/>
            )}
          </Box>
        );
      },
    },
    {field: "ds_invoices", headerName: "Invoices"},
    {
      field: "notes",
      headerName: "Notes (click to edit)",
      editable: true,
      type: "string",
    },
    {
      field: "url_id",
      headerName: "TM ID",
      renderCell: (params) => {
        return (
          <>
            <Button sx={{minWidth: 0, mr: 1}}
                    onClick={() => navigator.clipboard.writeText(params.value)}>
              <ContentPasteIcon fontSize="small"/>
            </Button>
            {params.value}
          </>
        );
      }
    },
    {
      field: "actions",
      headerName: "",
      width: 175,
      pinnable: true,
      sortable: false,
      // Render the buttons to view the graph, set the threshold, watch the event for drops, and view the event info
      renderCell: (params) => {
        return (
          <Box sx={{display: "flex"}}>
            <div>
              <a
                href={`/event/${params.row.url_id}`}
                target="_blank"
                rel="noreferrer"
                style={{color: "inherit"}}
              >
                <Button style={{minWidth: "10px"}}>
                  <GraphViewIcon/>
                </Button>
              </a>
            </div>
            <Box sx={{display: "flex"}}>
              <DropCheckModal
                eventId={params.row.url_id}
                isWatched={params.row.isWatched}
              />
              <ThresholdAlertModal
                eventId={params.row.url_id}
                remaining={params.row.tm_remaining}
                remaining_threshold={params.row.tm_remaining_threshold}
                mir={params.row.tm_mir}
                mir_threshold={params.row.tm_mir_threshold}
              />
              <InfoModal eventId={params.row.url_id}/>
            </Box>
          </Box>
        );
      },
    },
  ];

  // The data grid component fully configured and filled with the data
  return (
    <SearchDataGrid
      id={"TM_UP"}
      data={props.events}
      loading={props.loading}
      firstLoad={props.firstLoad}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      processRowUpdate={processRowUpdate as any}
    />
  );
});
