// ----- Packages ----- //
import React from "react";

// ----- MUI ----- //
import { Box, Button, TextField } from "@mui/material";

// ----- Types ----- //
import { MinMaxRemainingInputProps } from "../../Types/Other";

/**
 * The component for the min/max remaining input
 * - This is a custom input because it has a button to switch between % and #
 * @param props<MinMaxRemainingInputProps> - The props for the component
 */
export default function MinMaxRemaining(props: MinMaxRemainingInputProps) {
  const {
    minRemaining,
    maxRemaining,
    setMinRemaining,
    setMaxRemaining,
    minMaxRemainIsNb,
    setMinMaxIsNb,
    checkIfDisabled,
    startSearch,
    hideMax,
  } = props;

  // ----- Render ----- //
  return (
    <Box>
      <Box sx={{ mx: 1, mt: 2, mb: 1, width: "100px" }}>
        <TextField
          disabled={checkIfDisabled("min-remaining")}
          size="small"
          id="min-remaining"
          label={hideMax ? "Remaining" : "Min Rem."}
          title="Minimum number of tickets remaining"
          type="tel"
          InputProps={{
            startAdornment: (
              <Button
                disabled={checkIfDisabled("min-remaining")}
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  minWidth: "0",
                  width: "25px",
                  height: "25px",
                  position: "relative",
                  left: "-7px",
                }}
                size="small"
                color="secondary"
                variant="contained"
                onClick={() => {
                  setMinMaxIsNb(!minMaxRemainIsNb);
                }}
              >
                {!minMaxRemainIsNb ? "%" : "#"}
              </Button>
            ),
          }}
          value={minRemaining}
          onChange={(e) => {
            setMinRemaining(parseInt(e.target.value) || "");
          }}
          error={
            (minRemaining && maxRemaining && maxRemaining < minRemaining) ||
            minRemaining < 0
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") startSearch();
          }}
        />
      </Box>

      <Box
        sx={{
          mx: 1,
          mt: 2,
          mb: 1,
          width: "100px",
          display: hideMax ? "none" : "",
        }}
      >
        <TextField
          disabled={checkIfDisabled("max-remaining")}
          size="small"
          id="max-remaining"
          label="Max Rem."
          title="Maximum number of tickets remaining"
          type="number"
          InputProps={{
            startAdornment: (
              <Button
                disabled={checkIfDisabled("max-remaining")}
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  minWidth: "0",
                  width: "25px",
                  height: "25px",
                  position: "relative",
                  left: "-7px",
                }}
                size="small"
                color="secondary"
                variant="contained"
                onClick={() => {
                  setMinMaxIsNb(!minMaxRemainIsNb);
                }}
              >
                {!minMaxRemainIsNb ? "%" : "#"}
              </Button>
            ),
          }}
          value={maxRemaining}
          onChange={(e) => {
            setMaxRemaining(parseInt(e.target.value) || "");
          }}
          error={
            (minRemaining && maxRemaining && maxRemaining < minRemaining) ||
            (!minMaxRemainIsNb && maxRemaining > 100)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") startSearch();
          }}
        />
      </Box>
    </Box>
  );
}
