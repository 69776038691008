// ---- Packages ----- //
import React from "react";
import moment from "moment";

// ----- MUI ----- //
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

// ----- Types ----- //
import {FiltersPresetProps} from "../../Types/Other";

/**
 * The component for the filters presets
 * @param props<FiltersPresetProps> - The props for the component
 */
export default function FiltersPreset(props: FiltersPresetProps) {
  const {id, filters, updateValue} = props;

  // ----- State ----- //
  const [presets, setPresets] = React.useState<any[]>(() => {
    const presets = localStorage.getItem(`${id}-filters-presets`)
      ? JSON.parse(localStorage.getItem(`${id}-filters-presets`) as string)
      : [];
    presets.forEach((preset: any) => {
      delete preset.filters.fromDate;
      delete preset.filters.toDate;
      delete preset.filters.eventDateMin;
    });
    return presets;
  });

  const [preset, setPreset] = React.useState<string>("");
  const [newPresetName, setNewPresetName] = React.useState<string>("");

  // ----- Functions ----- //

  /**
   * Handles the change of the preset select
   * @param select<SelectChangeEvent> - The select change event
   */
  const handleChange = (select: SelectChangeEvent) => {
    localStorage.setItem(`${id}-filters-default`, select.target.value);
    setPreset(select.target.value);
  };

  // ----- Effects ----- //
  React.useEffect(() => {
    const defaultFilters = localStorage.getItem(
      `${id}-filters-default`
    ) as string;
    if (defaultFilters) setPreset(defaultFilters);
  }, [id]);

  React.useEffect(() => {
    if (preset === "") return;
    const presetFilters = presets.find((p) => p.name === preset)?.filters;
    if (!presetFilters) return;
    Object.keys(presetFilters).forEach((key) => {
      if (key === "fromDateDiff")
        updateValue(
          "fromDate",
          moment().subtract(presetFilters[key], "days").format("YYYY-MM-DD")
        );
      else if (key === "toDateDiff")
        updateValue(
          "toDate",
          moment().subtract(presetFilters[key], "days").format("YYYY-MM-DD")
        );
      else updateValue(key, presetFilters[key]);
    });
  }, [preset, presets]);

  /**
   * Saves the current filters as a preset
   */
  const savePreset = () => {
    let newFilters = {...filters};

    newFilters.fromDateDiff =
      moment().diff(moment(filters.fromDate), "days") || 0;
    newFilters.toDateDiff = moment().diff(moment(filters.toDate), "days") || 0;

    delete newFilters.fromDate;
    delete newFilters.toDate;
    delete newFilters.eventDateMin;
    delete newFilters.artist;
    delete newFilters.venue;
    delete newFilters.onlyWithThreshold;
    delete newFilters.onlyInDropCheck;

    const presetToUpdate = presets.find((p) => p.name === newPresetName);
    let newPresets;
    if (presetToUpdate) {
      const index = presets.indexOf(presetToUpdate);
      newPresets = [...presets];
      newPresets[index] = {name: newPresetName, filters: newFilters};
    } else if (newPresetName === "") {
      if (preset === "") return;
      const presetToUpdate = presets.find((p) => p.name === preset);
      const index = presets.indexOf(presetToUpdate);
      newPresets = [...presets];
      newPresets[index] = {name: preset, filters: newFilters};
    } else {
      newPresets = [...presets, {name: newPresetName, filters: newFilters}];
    }

    localStorage.setItem(`${id}-filters-presets`, JSON.stringify(newPresets));
    setPresets(
      JSON.parse(localStorage.getItem(`${id}-filters-presets`) as string)
    );
    setPreset("");
    setNewPresetName("");
  };

  /**
   * Deletes a preset
   * @param name<string> - The name of the preset to delete
   */
  const deletePreset = (name: string) => {
    const newPresets = presets.filter((p) => p.name !== name);
    localStorage.setItem(`${id}-filters-presets`, JSON.stringify(newPresets));
    setPresets(newPresets);
  };

  // ----- Render ----- //
  return (
    <Card sx={{ml: 5, p: 1, pb: 1.25}}>
      <Box sx={{mt: 1, width: "150px", display: "flex"}}>
        <FormControl fullWidth size={"small"}>
          <InputLabel id={id}>Preset</InputLabel>
          <Select
            labelId={id + "-label"}
            id={id + "-select"}
            value={preset}
            label={"Preset"}
            onChange={handleChange}
          >
            <MenuItem value={""}>
              <em>None</em>
            </MenuItem>
            {presets.map((preset: any) => (
              <MenuItem
                key={preset.name}
                value={preset.name}
                sx={{width: "100%"}}
              >
                {preset.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          id={id + "-save"}
          onClick={savePreset}
          sx={{minWidth: "0px"}}
          disabled={!preset || !!newPresetName}
          color={"success"}
        >
          <EditIcon/>
        </Button>
        <Button
          id={id + "-delete"}
          onClick={() => deletePreset(preset)}
          sx={{minWidth: "0px"}}
          color={"error"}
          disabled={!preset}
        >
          <ClearIcon/>
        </Button>
      </Box>

      <Box sx={{mt: 2, width: "150px", display: "flex"}}>
        <TextField
          fullWidth
          id={id + "-name"}
          label={"New Preset"}
          type={"text"}
          InputLabelProps={{shrink: true}}
          value={newPresetName}
          size={"small"}
          onChange={(e) => {
            setNewPresetName(e.target.value);
          }}
          error={false}
          onKeyDown={(e) => {
            if (e.key === "Enter") savePreset();
          }}
        />
        <Button id={id + "-save"} onClick={savePreset} sx={{minWidth: "0px"}}>
          <SaveIcon/>
        </Button>
      </Box>
    </Card>
  );
}
