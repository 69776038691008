import React from "react";
import { createTheme } from "@mui/material/styles";
import darkScrollbar from "@mui/material/darkScrollbar";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function DefaultTheme() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: (themeParam) => ({
              body: themeParam.palette.mode === "dark" ? darkScrollbar() : null,
              ".threshold-alert":
                themeParam.palette.mode === "dark"
                  ? { backgroundColor: "rgba(164,64,0,0.25) !important" }
                  : { backgroundColor: "#fff8f4 !important" },
              ".threshold-alert.Mui-hovered":
                themeParam.palette.mode === "dark"
                  ? { backgroundColor: "rgba(164,64,0,0.3) !important" }
                  : { backgroundColor: "#fff0e6 !important" },
              ".threshold-alert.Mui-selected":
                themeParam.palette.mode === "dark"
                  ? { backgroundColor: "rgba(164,64,0,0.4) !important" }
                  : { backgroundColor: "#ffe9d9 !important" },

              ".row-even": { backgroundColor: "rgba(0,0,0,0)" },
              ".row-odd": { backgroundColor: "rgba(125,125,125,0.05)" },
              ".MuiDataGrid-footerContainer": {
                height: "30px !important",
                minHeight: "30px !important",
              },

              ".via-sale-row": {
                backgroundColor: "rgba(116,195,0,0.25) !important",
              },
              ".sg-sale-row": {
                backgroundColor: "rgba(255, 84, 78, 0.25) !important",
              },
            }),
          },
        },
      }),
    [prefersDarkMode]
  );
}
